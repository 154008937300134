import React, { useMemo } from 'react';
import { Typography } from '@material-tailwind/react';
import { Source, Layer, Marker } from 'react-map-gl';
import { useAgamiMarkerStyle } from '../hooks/useAgamiMarkerStyle';

const XpClusterLayer = ({ feature, superCluster, onClick }) => {
  const { point_count: count, cluster_id: clusterId } = feature.properties;
  const { clusterStyle: circleStyle } = useAgamiMarkerStyle();

  const patterns = useMemo(() => {
    const leaves = superCluster?.getLeaves(clusterId, Infinity);
    return leaves?.filter((leaf) => leaf.properties.pattern).map((leaf) => leaf.properties.pattern) ?? [];
  }, []);

  const circleSize = Math.min(Math.max(count * 2, 30), 100);

  const circleLayerStyle = {
    id: `${feature.id}_circle`,
    type: 'circle',
    paint: {
      'circle-radius': circleSize,
      'circle-stroke-color': circleStyle.color,
      'circle-stroke-opacity': circleStyle.opacity,
      'circle-stroke-width': circleStyle.weight,
      'circle-color': circleStyle.fillColor,
      'circle-opacity': circleStyle.fillOpacity,
      'circle-pitch-alignment': 'map',
    },
  };

  return (
    <>
      <Source id={`${feature.id}_source`} key={`${feature.id}_source`} type="geojson" data={feature}>
        <Layer {...circleLayerStyle} />
      </Source>

      <Marker
        longitude={feature.geometry.coordinates[0]}
        latitude={feature.geometry.coordinates[1]}
        anchor="center"
        onClick={onClick}
      >
        <div className="bg-transparent relative text-center" style={{ width: circleSize }}>
          <Typography className="font-bold">{count - patterns.length}</Typography>
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full flex flex-wrap gap-2 justify-center w-full">
            {patterns.map((pattern) => (
              <div
                key={pattern.id}
                className="h-2 w-2 rounded-full"
                style={{ backgroundColor: pattern.color }}
              />
            ))}
          </div>
        </div>
      </Marker>
    </>
  );
};

export default XpClusterLayer;
