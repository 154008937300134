import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { getUserPatternDetails } from '../utils/helpers/supabase.helpers';

export const useExperiencePatternDetails = (options = {}) => {
  const { user } = useSessionContext();
  const { patternId, enabled } = options;

  const [isLoading, setIsLoading] = useState(true);
  const [patternDetails, setPatternDetails] = useState(null);

  const fetchPatternDetails = useCallback(async () => {
    if (enabled === false) {
      return;
    }

    const { data, error } = await getUserPatternDetails({
      patternId,
      userId: user.id,
    });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setPatternDetails(data);
    setIsLoading(false);
  }, [user, enabled, getUserPatternDetails]);

  useEffect(() => {
    if (!user || !patternId || enabled === false) {
      return;
    }
    fetchPatternDetails();
  }, [user, patternId, enabled]);

  return { patternDetails, isLoading, refetch: fetchPatternDetails };
};
