import React from 'react';
import Player from '../components/Player';

const SinglePlayer = ({ activeSpot }) => (
  <div className="absolute bottom-0 w-full md:w-[27rem] md:bottom-6 md:left-1/2 md:-translate-x-1/2 px-2 md:px-6 py-4 z-[999] bg-white text-primary shadow rounded">
    <Player spot={activeSpot} />
  </div>
);

export default SinglePlayer;
