import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Spinner, Typography } from '@material-tailwind/react';
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { PiCirclesThree } from 'react-icons/pi';
import GoBack from '../components/GoBack';
import { usePageTitle, useWindowSize } from '../hooks/toolkit';
import { useExperiencePatternDetails } from '../hooks/useExperiencePatternDetails';
import ExperienceSpotsList from '../ViewDashboard/ExperienceSpotsList';
import RemoveAgamiFromPatternDialog from '../ViewDashboard/RemoveAgamiFromPatternDialog';
import ExperienceSpotsCards from '../ViewDashboard/ExperienceSpotsCards';
import AddAgamiToPattern from '../ViewDashboard/AddAgamiToPattern';
import ResponsiveDialog from '../components/ResponsiveDialog';
import EditPatternForm from '../components/EditPatternForm';
import { useUserExperiences } from '../hooks/useUserExperiences';

const DashboardPatternDetail = () => {
  usePageTitle('Pattern Details | Agami Dashboard');
  const { patternId } = useParams();
  const { patternDetails, isLoading: detailsLoading, refetch } = useExperiencePatternDetails({ patternId });
  const { experienceList, isLoading: expLoading } = useUserExperiences({
    excludeDefaultExperience: true,
  });

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [agamiToRemove, setAgamiToRemove] = useState(null);

  const { width } = useWindowSize();
  const smallScreen = width <= 640;

  const startRemove = (agami) => {
    setAgamiToRemove(agami);
    setRemoveDialogOpen(true);
  };

  const isLoading = detailsLoading || expLoading;

  return (
    <div className="py-4">
      <div className="flex flex-col justify-start items-start w-full">
        {isLoading && <Spinner className="h-6 w-6" />}

        {!isLoading && !patternDetails && <Typography>Looks like there&apos;s nothing here :(</Typography>}

        {!isLoading && patternDetails && (
          <>
            <ResponsiveDialog
              isOpen={editDialogOpen}
              setIsOpen={setEditDialogOpen}
              title="Edit pattern details"
            >
              <EditPatternForm
                pattern={patternDetails}
                experienceList={experienceList}
                refetch={refetch}
                closeDialog={() => setEditDialogOpen(false)}
              />
            </ResponsiveDialog>

            <RemoveAgamiFromPatternDialog
              isOpen={removeDialogOpen}
              setIsOpen={setRemoveDialogOpen}
              agamiToRemove={agamiToRemove}
              patternDetails={patternDetails}
              refetchAgamiList={refetch}
            />

            <AddAgamiToPattern
              isOpen={addDialogOpen}
              setIsOpen={setAddDialogOpen}
              refetchAgamiList={refetch}
              currentPatternId={patternDetails.pattern_id}
              currentExperienceId={patternDetails.experience_id}
            />

            <div className="flex flex-col md:flex-row md:items-start justify-center gap-2 mb-2">
              <GoBack />

              <div className="flex gap-x-4 items-center">
                <span className="inline-block mr-1">
                  <PiCirclesThree className="h-5 w-5" />
                </span>

                <Typography variant="lead">{patternDetails.name}</Typography>

                {patternDetails.color && (
                  <div className="w-10 h-10 rounded-full" style={{ backgroundColor: patternDetails.color }} />
                )}

                {patternDetails.agamiList?.length > 0 && (
                  <Typography className="text-base text-gray-600">
                    ( {patternDetails.agamiList?.length} agami )
                  </Typography>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-y-2 my-4">
              <Typography>
                <strong className="font-bold">Experience:</strong>{' '}
                <Link
                  className="underline hover:opacity-70"
                  to={`/experiences/${patternDetails.experience_id}`}
                >
                  {patternDetails.experience_name}
                </Link>
              </Typography>

              {patternDetails.description && (
                <Typography>
                  <strong className="font-bold">Description</strong>
                  <br />
                  {patternDetails.description}
                </Typography>
              )}
            </div>

            <div className="flex items-center space-x-4">
              <Button
                color="primary"
                className="flex justify-center items-center gap-2"
                size="sm"
                onClick={() => setEditDialogOpen(true)}
              >
                <AiOutlineEdit />
                EDIT PATTERN DETAILS
              </Button>
              <Button
                color="primary"
                className="flex justify-center items-center gap-2"
                size="sm"
                onClick={() => setAddDialogOpen(true)}
              >
                <AiOutlinePlus />
                ADD AN AGAMI TO THIS PATTERN
              </Button>
            </div>
          </>
        )}

        <div className="w-full mt-4">
          {smallScreen ? (
            <ExperienceSpotsCards
              agamiListLoading={isLoading}
              agamiList={patternDetails?.agamiList}
              removeFromExperience={startRemove}
            />
          ) : (
            <ExperienceSpotsList
              agamiListLoading={isLoading}
              agamiList={patternDetails?.agamiList}
              removeFromExperience={startRemove}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPatternDetail;
