import React from 'react';
import { Chip, Spinner } from '@material-tailwind/react';
import { useLabelsContext } from '../contexts/LabelsContext';

const AgamiTypeChip = ({ agamiType }) => {
  const { loading, getSpotTypeLabel } = useLabelsContext();
  return (
    <Chip
      className="inline-block"
      title={agamiType}
      size="sm"
      variant="ghost"
      color={agamiType === 1 ? 'brown' : 'blue-gray'}
      value={
        loading ? (
          <span className="inline-block">
            <Spinner className=" w-2 h-2" />
          </span>
        ) : (
          getSpotTypeLabel(agamiType)
        )
      }
    />
  );
};

export default AgamiTypeChip;
