import React from 'react';
import { useUserPreferences } from '../contexts/UserPreferencesContext';
import { MAPBOX_SATELLITE_MAP_STYLE } from '../utils/constants/mapbox.constants';
import { theme } from '../tailwind.config';

const AgamiMarkerIcon = ({ color }) => {
  const { mapStyle } = useUserPreferences();
  const { colors } = theme.extend;
  const PRIMARY = colors.primary;
  const ACCENT = colors.accent;
  return mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? (
    <svg
      version="1.1"
      width="36.955326"
      height="36.955326"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill={color ?? ACCENT[500]}
        stroke={PRIMARY[500]}
        strokeWidth={0.4}
        cx="18.477663"
        cy="18.477663"
        r="18.277662"
      />
      <g
        id="g1"
        transform="translate(-1.5223377,6.1026623)"
        fill={color ?? ACCENT[500]}
        stroke={PRIMARY[500]}
      >
        <path
          d="m 17.501629,9.69013 c -1.28584,-0.3348 -2.49839,-1.5537 -2.49839,-3.18417 0,-3.07336 2.34489,-4.50596 4.996,-4.50596 2.65112,0 4.996,2.29533 4.996,5.12649 0,0 0.4289,4.32871 -4.996,10.87351 l -2.90488,-3.6599"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          stroke={PRIMARY[500]}
        />
        <ellipse cx="20" cy="22" rx="5" ry="2" fill={PRIMARY[500]} />
      </g>
    </svg>
  ) : (
    <svg
      version="1.1"
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49839 9.69013C3.21255 9.35533 2 8.13643 2 6.50596C2 3.4326 4.34489 2 6.996 2C9.64712 2 11.992 4.29533 11.992 7.12649C11.992 7.12649 12.4209 11.4552 6.996 18L4.09112 14.3401"
        stroke={color ?? PRIMARY[500]}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="7" cy="22" rx="5" ry="2" fill={color ?? PRIMARY[500]} />
    </svg>
  );
};

export default AgamiMarkerIcon;
