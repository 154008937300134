import React from 'react';
import { Button, Typography } from '@material-tailwind/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const RedeemCodeMessage = ({
  redeemCodeError,
  setRedeemCodeError,
  setRedeemCode,
  urlParamCode,
  textColor,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClearCode = () => {
    setRedeemCode('');
    setRedeemCodeError(null);
    if (urlParamCode) {
      // remove from URL
      navigate(pathname, { replace: true });
    }
  };

  return (
    <>
      {redeemCodeError && (
        <>
          <Typography className="font-italic font-bold mt-1" variant="small" color="red">
            {redeemCodeError}
          </Typography>
          <Button size="sm" onClick={handleClearCode}>
            CLEAR CODE
          </Button>
        </>
      )}
      {!redeemCodeError && (
        <Typography className="font-italic mt-1" variant="small" color={textColor}>
          🙏 Thank you for supporting us! With this code your account will have some premium features. See you
          inside :){' '}
          <Link to="/faq#code-faq" className="underline" target="_blank" rel="noopener noreferrer">
            Need help?
          </Link>
        </Typography>
      )}
    </>
  );
};

export default RedeemCodeMessage;
