import React from 'react';
import Sheet from 'react-modal-sheet';
import { Dialog, DialogHeader, DialogBody, Typography } from '@material-tailwind/react';
import { useScreenBreakpoint } from '../hooks/toolkit';

const ResponsiveDialog = ({ isOpen, setIsOpen, title, children }) => {
  const screenBreakpoint = useScreenBreakpoint();
  if (screenBreakpoint === 'sm') {
    return (
      <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Sheet.Container>
          <Sheet.Header className="p-4 flex justify-between items-center">
            <Typography variant="h4">{title}</Typography>
          </Sheet.Header>
          <Sheet.Content>{children}</Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  }

  return (
    <Dialog open={isOpen} handler={setIsOpen}>
      <DialogHeader className="justify-between">
        <Typography variant="h4">{title}</Typography>
      </DialogHeader>
      <DialogBody>{children}</DialogBody>
    </Dialog>
  );
};

export default ResponsiveDialog;
