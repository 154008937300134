import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import Supercluster from 'supercluster';
import { center } from '@turf/center';
import { points } from '@turf/helpers';
import {
  FIT_BOUNDS_ANIMATION_DURATION,
  FIT_BOUNDS_PADDING,
  SUPERCLUSTER_OPTIONS,
} from '../utils/constants/map.constants';
import { getBoundsFromCoords } from '../utils/helpers/map.helpers';
import XpSpotLayer from './XpSpotLayer';
import XpClusterLayer from './XpClusterLayer';
import { useXp } from '../contexts/XpContext';
import XpPatternLayer from './XpPatternLayer';

const DEBOUNCE_UPDATE_TIME = 100;

const XpSpotsCluster = ({ zoomOnClick }) => {
  const { positions, active } = useXp();
  const { current: map } = useMap();

  const debounceUpdate = useRef();

  const [selectedPattern, setSelectedPattern] = useState();
  const [currentClusters, setCurrentClusters] = useState();
  const [superCluster] = useState(
    new Supercluster({
      ...SUPERCLUSTER_OPTIONS,
      extent: 512,
    })
  );

  const activePositionsIds = useMemo(() => active?.map((audio) => audio?.spot?.position), [active]);

  // load supercluster features
  const loadSuperCluster = useCallback(() => {
    const pointFeatures = positions.reduce((result, pattern) => {
      if (pattern.id === 'default') {
        return result.concat(
          pattern.positions?.map((position) => ({
            id: position.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [position.lon, position.lat],
            },
            properties: {
              position,
            },
          })) ?? []
        );
      }

      if (pattern.id !== selectedPattern?.id) {
        return result.concat(
          pattern.positions?.length
            ? [
                {
                  id: pattern.id,
                  ...center(points(pattern.positions.map((position) => [position.lon, position.lat]))),
                  properties: {
                    pattern,
                  },
                },
              ]
            : []
        );
      }

      return result;
    }, []);

    superCluster.load(pointFeatures);
  }, [positions, selectedPattern]);

  // define update method
  const updateClusters = useCallback(() => {
    if (!map || !superCluster) return;
    const zoom = map.getZoom();
    const bounds = map.getBounds();

    const updatedClusters = superCluster.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()], // [westLng, southLat, eastLng, northLat]
      zoom
    );

    setCurrentClusters(updatedClusters);
  }, [superCluster, currentClusters, positions]);

  // on map moved
  const onMapChange = React.useCallback(() => {
    if (debounceUpdate.current) return;
    debounceUpdate.current = setTimeout(() => {
      clearTimeout(debounceUpdate.current);
      debounceUpdate.current = undefined;

      updateClusters();
    }, DEBOUNCE_UPDATE_TIME);
  }, [updateClusters]);

  // fit map bounds to current cluster
  const fitClusterBounds = React.useCallback(
    (clusterId) => {
      if (!superCluster || !map || !clusterId) return;
      const coords = superCluster?.getLeaves(clusterId, Infinity).map((leaf) => leaf.geometry.coordinates);

      const mapBounds = getBoundsFromCoords(coords);
      map.fitBounds(mapBounds, {
        padding: FIT_BOUNDS_PADDING,
        duration: FIT_BOUNDS_ANIMATION_DURATION,
      });
    },
    [superCluster, map]
  );

  // fit map bounds to current pattern
  const fitPatternBounds = React.useCallback(
    (pattern) => {
      if (!pattern || !map) return;

      const coords = pattern.positions.map((position) => [position.lon, position.lat]);
      const mapBounds = getBoundsFromCoords(coords);
      map.fitBounds(mapBounds, {
        padding: FIT_BOUNDS_PADDING,
        duration: FIT_BOUNDS_ANIMATION_DURATION,
      });
    },
    [map]
  );

  const handlePatternClick = useCallback((pattern) => {
    setSelectedPattern(pattern);
    fitPatternBounds(pattern);
  }, []);

  // update supercluster when map moves
  useEffect(() => {
    if (!map) return;
    map.on('move', onMapChange);
    map.on('zoom', onMapChange);
    return () => {
      map?.off('move', onMapChange);
      map?.off('zoom', onMapChange);
    };
  }, [map, onMapChange]);

  // load supercluster
  useEffect(() => {
    if (!superCluster) return;

    loadSuperCluster();
    updateClusters();
  }, [positions, superCluster, selectedPattern]);

  // auto select pattern if spot is active
  useEffect(() => {
    const activePattern = positions.find((pattern) =>
      activePositionsIds.some((activeId) => pattern.positions.map((pos) => pos.id).includes(activeId))
    );
    if (activePattern) setSelectedPattern(activePattern);
  }, [activePositionsIds]);

  return (
    <>
      {currentClusters?.map((feature) => {
        const { position, pattern, cluster } = feature.properties;
        if (cluster) {
          return (
            <XpClusterLayer
              key={feature.id}
              feature={feature}
              superCluster={superCluster}
              onClick={
                zoomOnClick
                  ? (e) => {
                      fitClusterBounds(feature.properties.cluster_id);
                      e.originalEvent.stopPropagation();
                    }
                  : undefined
              }
            />
          );
        }
        if (pattern && pattern.id !== selectedPattern?.id && !position) {
          return (
            <XpPatternLayer
              key={feature.id}
              feature={feature}
              onClick={(e) => {
                handlePatternClick(pattern);
                e.originalEvent.stopPropagation();
              }}
            />
          );
        }
        if (position) {
          return (
            <XpSpotLayer
              key={feature.id}
              feature={feature}
              isActive={activePositionsIds.includes(position.id)}
            />
          );
        }

        return null;
      })}

      {selectedPattern?.positions.map((position) => (
        <XpSpotLayer
          key={position.id}
          feature={{
            id: position.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [position.lon, position.lat],
            },
            properties: {
              position,
              pattern: selectedPattern,
            },
          }}
          isActive={activePositionsIds.includes(position.id)}
        />
      ))}
    </>
  );
};

export default XpSpotsCluster;
