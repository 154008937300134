import React, { useCallback, useMemo, useState } from 'react';
import { Card, CardBody, IconButton, Typography } from '@material-tailwind/react';
import { PiTrash } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import DeletePatternDialog from './DeletePatternDialog';
import { formatDateString } from '../utils/helpers/dates.helpers';
import { AGAMI_GLOBAL_EXPERIENCE_ID } from '../utils/constants/db.constants';

const PatternPreviewCard = ({ pattern, experienceList, refetch }) => {
  const experience = experienceList.find((exp) => exp.id === pattern.experience_id);

  const isAgamiGlobal = pattern.experience_id === AGAMI_GLOBAL_EXPERIENCE_ID;

  const patternExpName = useMemo(() => {
    if (experience?.name) return experience.name;
    if (isAgamiGlobal) return 'Agami Global';
    return '-';
  }, [isAgamiGlobal, experience]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const manageDelete = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <DeletePatternDialog
        isOpen={deleteDialogOpen}
        setIsOpen={setDeleteDialogOpen}
        pattern={pattern}
        refetch={refetch}
      />

      <Card
        className="w-full h-full cursor-pointer hover:bg-base-gray-400"
        onClick={() => navigate(`/patterns/${pattern.pattern_id}`)}
      >
        <CardBody>
          <div className="mb-4 flex items-center justify-end">
            <div className="flex items-center gap-x-2">
              {pattern.color && (
                <div className="w-6 h-6 rounded-full" style={{ backgroundColor: pattern.color }} />
              )}
              <Typography variant="h3" className="font-bold">
                {pattern.name}
              </Typography>
            </div>
            <IconButton variant="text" size="sm" color="yellow" onClick={manageDelete} className="ml-auto">
              <PiTrash className="text-primary text-[20px]" />
            </IconButton>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <Typography className="font-bold text-primary-500">Experience</Typography>
            <em>{patternExpName}</em>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <Typography className="font-bold text-primary-500">Created at</Typography>
            <em>{pattern.created_at ? formatDateString(pattern.created_at) : '-'}</em>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default PatternPreviewCard;
