import React, { useState } from 'react';
import { PiInfoLight } from 'react-icons/pi';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Input,
  Switch,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { validateInputNumber } from '../utils/helpers/validate.helpers';
import { AccordionCaret } from '../components/AccordionCaret';

const UploadExpAdvancedSettings = ({ formData, handleDataChange, checkInputIsValid }) => {
  const [open, setOpen] = useState(false);

  return (
    <Accordion open={open} icon={<AccordionCaret rotate={open} />}>
      <AccordionHeader onClick={() => setOpen((prev) => !prev)} className="justify-center gap-4">
        Advanced settings
      </AccordionHeader>
      <AccordionBody className="flex flex-col mt-4 gap-4">
        <div className="w-full flex flex-col md:flex-row justify-center md:items-center gap-4 mb-4 md:mb-0">
          <Input
            type="number"
            label="Agami radius (m) - default 20 m"
            min={1}
            value={formData.default_radius ?? ''}
            onChange={(e) =>
              handleDataChange('default_radius', validateInputNumber(e.currentTarget.value ?? ''))
            }
            onBlur={() => checkInputIsValid('default_radius', formData.default_radius > 0)}
          />
          <Input
            type="number"
            label="Agami deadband (m) - default 15 m"
            min={1}
            value={formData.default_deadband ?? ''}
            onChange={(e) =>
              handleDataChange('default_deadband', validateInputNumber(e.currentTarget.value ?? ''))
            }
            onBlur={() => checkInputIsValid('default_deadband', formData.default_deadband > 0)}
          />
          <Tooltip
            content={
              <div className="w-[300px]">
                <Typography variant="small" className="font-bold">
                  This values will be used in this experience for agami created without radius (if any).
                  Radius set for single agami have the priority over this value. Leave blank to use global
                  deafult values.
                </Typography>
                <Typography variant="small">
                  <strong>
                    <em>default: radius 20 m, deadband 15 m</em>
                  </strong>
                </Typography>
              </div>
            }
          >
            <span>
              <PiInfoLight className="text-primary" />
            </span>
          </Tooltip>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-center md:items-center gap-4 mb-4 md:mb-0">
          <Input
            type="number"
            label="Fade in time (ms) - default 0 ms"
            min={0}
            value={formData.fade_in_time ?? ''}
            onChange={(e) =>
              handleDataChange('fade_in_time', validateInputNumber(e.currentTarget.value ?? ''))
            }
            onBlur={() => checkInputIsValid('fade_in_time', formData.fade_in_time >= 0)}
          />
          <Input
            type="number"
            label="Fade out time (ms) - default 1000 ms"
            min={0}
            value={formData.fade_out_time ?? ''}
            onChange={(e) =>
              handleDataChange('fade_out_time', validateInputNumber(e.currentTarget.value ?? ''))
            }
            onBlur={() => checkInputIsValid('fade_out_time', formData.fade_out_time >= 0)}
          />
          <Tooltip
            content={
              <div className="w-[300px]">
                <Typography variant="small" className="font-bold">
                  Fades are gradual increase (fade-in) or decrease (fade-out) in the volume of an audio
                  content. Define the duration for this gradual change for your <em>agamis</em> in this
                  experience, when they activate or deactivate. Leave blank to use global deafult values. Use
                  0 to to avoid gradual volume changes.
                </Typography>
                <Typography variant="small">
                  <strong>
                    <em>default: fadeIn 0 ms, fade-out 1000 ms</em>
                  </strong>
                </Typography>
              </div>
            }
          >
            <span>
              <PiInfoLight className="text-primary" />
            </span>
          </Tooltip>
        </div>

        <div className="flex flex-col md:flex-row justify-start md:items-center gap-4 mb-4 md:mb-0">
          <Input
            type="number"
            label="Min required position accuracy (m) - default 25 m"
            min={1}
            value={formData.accuracy ?? ''}
            onChange={(e) => handleDataChange('accuracy', validateInputNumber(e.currentTarget.value ?? ''))}
            onBlur={() => checkInputIsValid('accuracy', formData.accuracy > 0)}
          />
          <Tooltip
            content={
              <div className="w-[300px]">
                <Typography variant="small" className="font-bold">
                  Retrieved user position with an accuracy higher than this value will be ignored. If you set
                  this value to a very low value, only very precise position will be used. If you set this
                  value to a very high value, also unaccurate position will be used. Leave blank to use the
                  default value.
                </Typography>
                <Typography variant="small">
                  <strong>
                    <em>default: 25 meters</em>
                  </strong>
                </Typography>
              </div>
            }
            placement="right"
            interactive
          >
            <span>
              <PiInfoLight className="text-primary" />
            </span>
          </Tooltip>
        </div>
        <div className="flex justify-start items-center gap-1">
          <Switch
            id="high_accuracy_switch"
            label="High accuracy"
            checked={Boolean(formData.enable_high_accuracy)}
            onChange={(e) => handleDataChange('enable_high_accuracy', e.currentTarget.checked)}
          />
          <Tooltip
            content={
              <div className="w-[300px]">
                <Typography variant="small" className="font-bold">
                  The application will try to provide a more accurate user position, even if it takes longer
                  time
                </Typography>
                <Typography variant="small">
                  <strong>
                    <em>default: true</em>
                  </strong>
                </Typography>
              </div>
            }
            placement="right"
            interactive
          >
            <span>
              <PiInfoLight className="text-primary" />
            </span>
          </Tooltip>
        </div>
      </AccordionBody>
    </Accordion>
  );
};

export default UploadExpAdvancedSettings;
