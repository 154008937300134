import {
  Alert,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from '@material-tailwind/react';
import React, { useCallback, useState } from 'react';
import { PiWarningCircle } from 'react-icons/pi';
import { useGeneralToast } from '../hooks/toast';
import { useWindowSize } from '../hooks/toolkit';
import { deleteUserPattern } from '../utils/helpers/supabase.helpers';

const DeletePatternDialog = ({ isOpen, setIsOpen, pattern, refetch }) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [deleting, setDeleting] = useState(false);
  const { width } = useWindowSize();
  const dialogSize = width <= 640 ? 'lg' : 'xs';

  const deletePattern = useCallback(async () => {
    setDeleting(true);

    const { error } = await deleteUserPattern({ patternId: pattern.pattern_id });

    if (error) {
      console.error(error);
      toastError('Something went wrong :(');
      setDeleting(false);
      return;
    }

    await refetch();
    toastSuccess('Pattern sucessfully deleted');
    setDeleting(false);
    setIsOpen(false);
  }, [pattern]);

  return (
    <Dialog size={dialogSize} open={isOpen} handler={setIsOpen}>
      <DialogHeader>Delete pattern</DialogHeader>

      <DialogBody>
        <Alert variant="ghost" color="amber" icon={<PiWarningCircle className="h-6 w-6" />}>
          This operation cannot be undone!
        </Alert>
        <span className="block my-2">
          Are you sure you want to delete the pattern <strong>{pattern.name}</strong>?
        </span>
      </DialogBody>

      <DialogFooter className="justify-between gap-2">
        <Button size="sm" color="primary" variant="text" onClick={() => setIsOpen(false)} disabled={deleting}>
          CANCEL
        </Button>
        <Button size="sm" color="red" className="bg-accent-red" onClick={deletePattern} disabled={deleting}>
          {deleting ? <Spinner className="h-4 w-4" /> : 'CONFIRM DELETE'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DeletePatternDialog;
