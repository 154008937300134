import { useLocation, Navigate, Link } from 'react-router-dom';
import { Button, Typography } from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';
import decoration1 from '../assets/svg/homepage-decor-1.svg';
import decoration2 from '../assets/svg/homepage-decor-2.svg';

const Home = () => {
  const { user } = useSessionContext();

  const location = useLocation();

  if (user) {
    // send a logged user to its dashboard
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return (
    <div className="text-white-yellow w-full overflow-x-clip">
      <div className="w-full relative z-10">
        <video autoPlay muted loop className="opacity-40 block md:hidden min-h-[700px] w-full">
          <source
            src="https://content.mezzoforte.design/agami/AGAMI_CUT_nosub_noaudio_vertical.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <video autoPlay muted loop className="opacity-40 hidden md:block min-h-[800px] w-full">
          <source
            src="https://content.mezzoforte.design/agami/AGAMI_CUT_nosub_noaudio.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center max-w-[900px] w-[90%] mx-auto">
          <Typography className="uppercase font-bold text-accent pb-4" variant="h1">
            Everywhere immersive sound
          </Typography>
          <Typography className="pb-5" variant="h5">
            <strong>agami</strong> is the first pro audio platform for open ear immersive experiences
          </Typography>
          <Link to="/how-it-works">
            <Button>SEE HOW IT WORKS</Button>
          </Link>
        </div>
      </div>

      <div className="relative">
        <img className="absolute left-0 top-0 -z-10 hidden xl:block" src={decoration1} alt="decoration 1" />
        <img className="absolute right-0 bottom-0 -z-10" src={decoration2} alt="decoration 2" />

        <div className="max-w-[900px] w-[90%] mx-auto flex flex-col md:flex-row pt-20 pb-48 md:pt-40 items-center">
          <div className="flex-1 md:max-w-[50%]">
            <Typography className="uppercase font-bold text-accent pb-4" variant="lead">
              GET MEZZO FORTE OPEN EAR HEADPHONES
            </Typography>
            <Typography className="pb-6">
              Our headset will lead you into an immersive sound experience
            </Typography>
            <Link to="https://www.indiegogo.com/projects/agami-everywhere-immersive-sound#/" target="_blank">
              <Button size="sm">BUY NOW</Button>
            </Link>
          </div>
          <div className="flex-1 md:max-w-[50%]">
            <img
              className="max-w-[300px] md:max-w-[400px] h-auto"
              src="/img/headset.png"
              alt="app and headset"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
