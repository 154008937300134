import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import {
  PiUserPlusLight,
  PiQrCodeLight,
  PiUsersFourLight,
  PiMapTrifold,
  PiMapPinLine,
  PiListNumbersLight,
} from 'react-icons/pi';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';

const AdminPanel = () => {
  usePageTitle('Admin panel | Agami');

  const navigate = useNavigate();

  return (
    <Container direction="col" bgColorClass="bg-white">
      <Typography variant="h1" className="text-primary">
        ADMIN CONTROL PANEL
      </Typography>

      <div className="w-full mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Card className="cursor-pointer hover:bg-base-gray-400" onClick={() => navigate('/admin/new-user')}>
          <CardBody>
            <PiUserPlusLight className="text-5xl mb-3" />
            <Typography variant="h5">Invite new user</Typography>
            <Typography>Send an invitation email to a new user</Typography>
          </CardBody>
        </Card>

        <Card className="cursor-pointer hover:bg-base-gray-400" onClick={() => navigate('/admin/new-code')}>
          <CardBody>
            <PiQrCodeLight className="text-5xl mb-3" />
            <Typography variant="h5">Generate code</Typography>
            <Typography>Generate an upgrade code for an existing user</Typography>
          </CardBody>
        </Card>

        <Card className="cursor-pointer hover:bg-base-gray-400" onClick={() => navigate('/admin/list-codes')}>
          <CardBody>
            <PiListNumbersLight className="text-5xl mb-3" />
            <Typography variant="h5">List codes</Typography>
            <Typography>See generated upgrade codes and their status</Typography>
          </CardBody>
        </Card>

        <Card className="cursor-pointer hover:bg-base-gray-400" onClick={() => navigate('/admin/list-users')}>
          <CardBody>
            <PiUsersFourLight className="text-5xl mb-3" />
            <Typography variant="h5">List users</Typography>
            <Typography>Get a list of users and their role</Typography>
          </CardBody>
        </Card>

        <Card
          className="cursor-pointer hover:bg-base-gray-400"
          onClick={() => navigate('/admin/list-experiences')}
        >
          <CardBody>
            <PiMapTrifold className="text-5xl mb-3" />
            <Typography variant="h5">List experiences</Typography>
            <Typography>Get a list of creted experiences</Typography>
          </CardBody>
        </Card>

        <Card className="cursor-pointer hover:bg-base-gray-400" onClick={() => navigate('/admin/list-spots')}>
          <CardBody>
            <PiMapPinLine className="text-5xl mb-3" />
            <Typography variant="h5">List agami</Typography>
            <Typography>Get a list of uploaded agami and their status</Typography>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default AdminPanel;
