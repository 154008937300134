import { useState } from 'react';
import { Input, Checkbox, Button, Typography, Spinner } from '@material-tailwind/react';
import { useSearchParams } from 'react-router-dom';
import { useSessionContext } from '../contexts/SessionContext';
import { updateUser, updateUserPassword, redeemUpgradeCode } from '../utils/helpers/supabase.helpers';
import { useGeneralToast } from '../hooks/toast';
import RedeemCodeMessage from './RedeemCodeMessage';

const FinalizeUserForm = ({ userType }) => {
  const { error: toastError } = useGeneralToast();

  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('redeemCode');
  const [redeemCode, setRedeemCode] = useState(codeParam);
  const [redeemCodeError, setRedeemCodeError] = useState();

  const { refresh, user } = useSessionContext();

  const [formData, setFormData] = useState({});
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();

  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setFormData((current) => ({
      ...current,
      [key]: value,
    }));
  };

  const handleRedeemCode = async (code) => {
    if (code) {
      const { data: redeemData, error: redeemError } = await redeemUpgradeCode({
        code,
        email: user.email,
      });

      if (redeemError) {
        setRedeemCodeError(redeemError);
        return { redeemError };
      }

      const redeemedRole = redeemData.target_role;

      return { redeemedRole };
    }
    return {};
  };

  const updateUserData = async (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    const { error: updateUserPasswordError } = await updateUserPassword({
      password,
      passwordConfirm,
    });

    if (updateUserPasswordError) {
      console.error(updateUserPasswordError);
      toastError(updateUserPasswordError.message ?? updateUserPasswordError);
      setLoading(false);
      return;
    }

    const { redeemError, redeemedRole } = await handleRedeemCode(redeemCode);

    if (redeemError) {
      setLoading(false);
      return;
    }

    const bch_model = userType === 'indiegogo' ? 'MezzoForte OpenEar' : formData.bch_model;

    const { error: updateUserError } = await updateUser({
      attributes: {
        ...formData,
        bch_model,
        role_id: redeemedRole,
        finalized: true,
      },
      id: user.id,
    });

    if (updateUserError) {
      console.error(updateUserError);
      toastError(updateUserError.message ?? updateUserError);
      setLoading(false);
      return;
    }

    setLoading(false);
    refresh();
  };

  return (
    <form className="w-full md:w-1/2 flex flex-col gap-4 mb-6" onSubmit={updateUserData}>
      {/* redeemCode is in the URL */}
      {codeParam && (
        <div>
          <Input
            color="white"
            className="font-bold text-white"
            value={redeemCode}
            readOnly
            type="text"
            label="Your Early Bird code!"
            required
            success={!redeemCodeError}
            error={!!redeemCodeError}
          />
          <RedeemCodeMessage
            redeemCodeError={redeemCodeError}
            setRedeemCode={setRedeemCode}
            setRedeemCodeError={setRedeemCodeError}
            urlParamCode={codeParam}
            textColor="white"
          />
        </div>
      )}

      {/* redeemCode is NOT in the URL but user wants to insert it */}
      {!codeParam && userType === 'indiegogo' && (
        <div>
          <Input
            color="white"
            className="font-bold text-white"
            value={redeemCode || ''}
            onChange={(e) => setRedeemCode(e.currentTarget.value)}
            type="text"
            label="Your Early Bird code!"
            required
            success={!redeemCodeError}
            error={!!redeemCodeError}
          />
          <RedeemCodeMessage
            redeemCodeError={redeemCodeError}
            setRedeemCode={setRedeemCode}
            setRedeemCodeError={setRedeemCodeError}
            textColor="white"
          />
        </div>
      )}

      {userType === 'bch-owner' && (
        <>
          <Input
            color="white"
            value={formData.bch_model || ''}
            onChange={(e) => handleInputChange('bch_model', e.currentTarget.value)}
            type="text"
            label="Your Bone Conduction Headphones model"
            required
          />
          <Typography className="font-italic mt-[-0.5rem]" variant="small" color="white">
            If you have more than one BCH, insert the one you prefer or you use more :)
          </Typography>
        </>
      )}

      <Input color="white" value={user.email} readOnly type="email" label="Email" />
      <Input
        color="white"
        value={formData.name || ''}
        onChange={(e) => handleInputChange('name', e.currentTarget.value)}
        type="text"
        label="Name"
        required
      />
      <Input
        color="white"
        value={formData.surname || ''}
        onChange={(e) => handleInputChange('surname', e.currentTarget.value)}
        type="text"
        label="Surname"
        required
      />
      <Input
        color="white"
        value={formData.company || ''}
        onChange={(e) => handleInputChange('company', e.currentTarget.value)}
        type="text"
        label="Company/organization/institute"
      />
      <div>
        <Input
          color="white"
          value={password || ''}
          onChange={(e) => setPassword(e.currentTarget.value)}
          type="password"
          label="Password"
          required
        />
        <Typography variant="small" className="mt-1 text-white-yellow">
          At least 10 charachters. Must contain a lowercase and an uppercase letter, a number and a symbol.
        </Typography>
      </div>
      <Input
        color="white"
        value={passwordConfirm || ''}
        onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
        type="password"
        label="Confirm password"
        required
      />
      <div className="flex justify-start items-center w-full ml-[-0.75rem]">
        <Checkbox
          id="terms_conditions"
          checked={Boolean(formData.terms_accepted)}
          onChange={(e) => handleInputChange('terms_accepted', e.currentTarget.checked)}
          type="checkbox"
          required
        />
        <label htmlFor="terms_conditions" className="text-white">
          <small>Accept terms and conditions</small>
        </label>
      </div>

      <Button type="submit" disabled={loading}>
        {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'CREATE ACCOUNT'}
      </Button>
    </form>
  );
};

export default FinalizeUserForm;
