import React from 'react';
import { Layer } from 'react-map-gl';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import { useUserPreferences } from '../contexts/UserPreferencesContext';

const Buildings3DLayer = () => {
  const { apply3DMap } = useUserPreferences();
  const advMapsAuth = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);
  const buildingLayer = {
    id: 'add-3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa',
      'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
      'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
      'fill-extrusion-opacity': 0.6,
    },
  };

  return apply3DMap && advMapsAuth ? <Layer {...buildingLayer} /> : null;
};

export default Buildings3DLayer;
