import React, { useState } from 'react';
import { Typography, Input, Button, Spinner, IconButton } from '@material-tailwind/react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';
import { signInWithEmailAndPassword } from '../utils/helpers/supabase.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { useGeneralToast } from '../hooks/toast';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';

const UserSignin = () => {
  usePageTitle('Signin | Agami');

  const { error: toastError } = useGeneralToast();
  const { user, refresh } = useSessionContext();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  if (user) {
    // send a logged user to its dashboard
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  const login = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!email || !password) {
      return;
    }

    setLoading(true);

    const { error } = await signInWithEmailAndPassword({ email, password });

    if (error) {
      toastError('Wrong credentials');
      console.error(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    refresh();
  };

  return (
    <Container direction="col">
      <Typography className="mt-4 mb-12" variant="h1" color="white">
        SIGN IN
      </Typography>

      <form className="w-full md:w-1/2 flex flex-col gap-8" onSubmit={login}>
        <Input
          color="white"
          value={email || ''}
          onChange={(e) => setEmail(e.currentTarget.value)}
          type="email"
          label="Email address"
          required
        />
        <div className="relative flex w-full">
          <Input
            className="pr-20"
            color="white"
            value={password || ''}
            onChange={(e) => setPassword(e.currentTarget.value)}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            required
            containerProps={{
              className: 'min-w-0',
            }}
          />
          <IconButton
            color="white"
            size="sm"
            variant="text"
            className="!absolute right-1 top-1 rounded"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <PiEyeSlashBold /> : <PiEyeBold />}
          </IconButton>
        </div>
        <Button type="submit" disabled={loading}>
          {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'SIGN IN'}
        </Button>
      </form>

      <div className="my-6">
        <Link to="/reset-password">
          <Button color="white" variant="text">
            Forgot your password?
          </Button>
        </Link>
      </div>

      <hr className="w-full md:w-1/2 my-6 opacity-50" />

      <div className="flex w-full justify-center items-center gap-2 my-2">
        <Typography color="white">Don&apos;t have Bone Conduction Headphones yet?</Typography>
        <Link
          to="https://www.indiegogo.com/projects/agami-everywhere-immersive-sound"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography color="white" className="underline font-bold">
            Buy now
          </Typography>
        </Link>
      </div>

      <div className="flex w-full justify-center items-center gap-2 my-2">
        <Typography color="white">Don&apos;t have an account yet?</Typography>
        <Link to="/register">
          <Typography color="white" className="underline font-bold">
            Create one
          </Typography>
        </Link>
      </div>
    </Container>
  );
};

export default UserSignin;
