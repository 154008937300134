import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Spinner, Input } from '@material-tailwind/react';
import { GoSearch } from 'react-icons/go';
import { getGeocoding } from '../utils/helpers/mapbox.helpers';

const PositionSuggestion = ({ suggestionInput, setSuggestionInput, onSuggestionSelect }) => {
  const [loading, setLoading] = useState(false);

  const [suggestions, setSuggestions] = useState([]);

  const geocode = async (value) => {
    if (value === '' || value.length < 3) {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const { data, error } = await getGeocoding(value);

    if (error) {
      console.error(error);
      setLoading(false);
      return;
    }

    setLoading(false);

    setSuggestions(data?.features);
  };

  const selectSuggestion = (suggestion) => {
    setSuggestions([]);
    setSuggestionInput(suggestion.place_name);
    onSuggestionSelect(suggestion);
  };

  const clearSuggestion = () => {
    setSuggestions([]);
    setLoading(false);
  };

  return (
    <div className="w-full relative">
      <div className="relative h-[41px]">
        <Input
          className="w-full"
          label="Search for a place"
          value={suggestionInput}
          onChange={(e) => {
            // set value
            setSuggestionInput(e.currentTarget.value);
            // perform geocoding to load suggestions
            geocode(e.currentTarget.value);
          }}
          icon={!loading && <GoSearch className="h-5 w-5" />}
        />

        {loading && <Spinner className="absolute h-5 w-5 top-3 right-2" />}
      </div>

      {suggestions?.length > 0 && (
        <ClickAwayListener onClickAway={clearSuggestion}>
          <div className="absolute top-[calc(41px_+_1rem)] w-full bg-white border flex flex-col shadow z-[99]">
            {suggestions?.map((suggestion, idx) => {
              const bgColor =
                idx % 2 === 0 ? 'bg-white hover:bg-gray-100' : 'bg-base-gray hover:bg-base-gray-600';
              return (
                <span
                  key={suggestion.id}
                  className={`inline-block w-full px-2 py-3 cursor-pointer ${bgColor}`}
                  onClick={() => selectSuggestion(suggestion)}
                >
                  {suggestion.place_name}
                </span>
              );
            })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default PositionSuggestion;
