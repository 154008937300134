import React, { useCallback, useMemo, useRef, useState } from 'react';
import Joyride from 'react-joyride';
import { Typography, Checkbox } from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';
import { TUTORIAL_STYLE } from '../utils/constants/onboarding.constants';
import { setSkipOnboarding } from '../utils/helpers/supabase.helpers';
import { useGeneralToast } from '../hooks/toast';

export const createOnboardingSteps = (DontShowCheckbox) => [
  {
    content: (
      <>
        <Typography variant="h3">Take a quick tour of Agami :)</Typography>
        <Typography className="mt-4">
          Just a few steps to introduce you to the awesome world of Agami.
        </Typography>
        {DontShowCheckbox}
      </>
    ),
    locale: { skip: 'Skip' },
    placement: 'center',
    target: 'body',
  },
  {
    content: <Typography>In your dashboard you can check and manage the content you uploaded!</Typography>,
    target: 'a[data-testid="dashboard-nav-link"]',
  },
  {
    content: (
      <Typography>
        The Experience tab gives you access to the interactive sound maps that are available for you.
      </Typography>
    ),
    target: 'a[data-testid="experience-nav-link"]',
  },
  {
    content: (
      <Typography>In the Upload section you can create awesome audio for Agami experiences!</Typography>
    ),
    placement: 'left',
    target: 'a[data-testid="upload-nav-link"]',
  },
];

const OnboardingTutorial = () => {
  const { loading, user } = useSessionContext();
  const savedSkip = useRef(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { success } = useGeneralToast();

  const runTutorial = useMemo(
    () => Boolean(!loading && user?.id && user?.finalized && !user?.skip_onboarding),
    [loading, user]
  );

  const steps = useMemo(
    () =>
      createOnboardingSteps(
        <Checkbox
          label="Don't show this again"
          checked={dontShowAgain}
          onChange={(e) => setDontShowAgain(e.currentTarget.checked)}
          type="checkbox"
        />
      ),
    [dontShowAgain]
  );

  const handleTutorialCallback = useCallback(
    async (e) => {
      if (e.action === 'skip' && dontShowAgain && !savedSkip.current) {
        // avoid double event
        savedSkip.current = true;
        // save to DB
        await setSkipOnboarding({
          userId: user.id,
        });
        // toast user
        success("We won't show you this tutorial again");
      }
    },
    [user?.id, dontShowAgain, savedSkip.current]
  );

  return (
    <div>
      <Joyride
        steps={steps}
        styles={TUTORIAL_STYLE}
        run={runTutorial}
        continuous
        showProgress
        showSkipButton
        callback={handleTutorialCallback}
      />
    </div>
  );
};

export default OnboardingTutorial;
