import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';
import { AgamiSpotDetailPropType } from '../PropTypes';
import AddAgamiToPatternRow from './AddAgamiToPatternRow';

const TABLE_HEAD = ['Agami Name', 'Agami Description', 'Created', 'Add to pattern'];

const AddAgamiToPatternTable = ({ currentExpId, currentPatternId, refetchAfterChange, agamiList }) => (
  <table className="w-full">
    <thead>
      <tr>
        {TABLE_HEAD.map((head) => (
          <th key={head} className="border-blue-gray-100 bg-blue-gray-500/30 border-b p-4">
            <Typography variant="small" color="primary" className="font-bold leading-none">
              {head}
            </Typography>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {agamiList.map(({ id, name, description, inserted_at, experience_content_spots }) => (
        <AddAgamiToPatternRow
          key={id}
          id={id}
          name={name}
          description={description}
          inserted_at={inserted_at}
          experience_content_spots={experience_content_spots}
          currentPatternId={currentPatternId}
          currentExpId={currentExpId}
          refetch={refetchAfterChange}
        />
      ))}
    </tbody>
  </table>
);

AddAgamiToPatternTable.propTypes = {
  currentExpId: PropTypes.string,
  currentPatternId: PropTypes.string,
  refetchAfterChange: PropTypes.func,
  agamiList: PropTypes.arrayOf(AgamiSpotDetailPropType),
};

export default AddAgamiToPatternTable;
