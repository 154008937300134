import { EXPERIENCE_CONTENT_TABLE, GEOXP_AUDIO_BUCKET, GEOXP_SPOTS_TABLE } from '../constants/db.constants';

import { getAudioFileUrl, supabase } from './supabase.helpers';

export const getAgami = async ({ agamiId }) => {
  try {
    const { data, error } = await supabase
      .from(GEOXP_SPOTS_TABLE)
      .select('*, spot_types (label)')
      .select('*, experience_content_spots (experience_id, manual_code, is_canceled)')
      .eq('id', agamiId)
      .eq('experience_content_spots.is_canceled', false)
      .eq('is_canceled', false);

    if (error) {
      console.error(error);
      return { error };
    }

    if (data.length === 0) {
      return { error: 'Cannot find spot' };
    }

    const fileUrl = await getAudioFileUrl({
      bucketId: GEOXP_AUDIO_BUCKET,
      filePath: data[0].audio_content_path,
    });

    const agamiData = {
      ...data[0],
      audioUrl: fileUrl?.data?.signedUrl,
    };

    return { agamiData, error };
  } catch (err) {
    console.error(err);
    return { err };
  }
};

export const getUserAgamiListWithExpRelations = async ({ userId, getAudioUrls }) => {
  try {
    const { data, error } = await supabase
      .from(GEOXP_SPOTS_TABLE)
      .select('*, spot_types (label)')
      .select('*, experience_content_spots (experience_id)')
      .eq('user_id', userId)
      .eq('experience_content_spots.is_canceled', false)
      .eq('is_canceled', false)
      .order('inserted_at', { ascending: false });

    if (error) {
      console.error(error);
      return { error };
    }

    if (!getAudioUrls) {
      return { spots: data };
    }

    const fileUrlPromises = data.map((spot) =>
      getAudioFileUrl({
        bucketId: GEOXP_AUDIO_BUCKET,
        filePath: spot.audio_content_path,
      })
    );

    const fileUrls = await Promise.all(fileUrlPromises);

    const spots = data.map((spot, idx) => ({
      ...spot,
      audioUrl: fileUrls[idx]?.data?.signedUrl,
    }));

    return { spots, error };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const getUserAgamiList = async ({ userId, getAudioUrls }) => {
  try {
    const { data, error } = await supabase
      .from(GEOXP_SPOTS_TABLE)
      .select('*, spot_types (label)')
      .eq('user_id', userId)
      .eq('is_canceled', false)
      .order('inserted_at', { ascending: false });

    if (error) {
      console.error(error);
      return { error };
    }

    if (!getAudioUrls) {
      return { spots: data };
    }

    if (data.length === 0) {
      return { data };
    }

    const fileUrlPromises = data.map((spot) =>
      getAudioFileUrl({
        bucketId: GEOXP_AUDIO_BUCKET,
        filePath: spot.audio_content_path,
      })
    );

    const fileUrls = await Promise.all(fileUrlPromises);

    const spots = data.map((spot, idx) => ({
      ...spot,
      audioUrl: fileUrls[idx]?.data?.signedUrl,
    }));

    return { spots, error };
  } catch (err) {
    console.error(err);
    return { err };
  }
};

export const getUserGeoXpSpotCount = async () => {
  const { data, error } = await supabase.rpc('get_user_spots_count');

  if (error) {
    console.error(error);
    return { error };
  }

  return { count: data, error };
};

export const deleteGeoXpSpot = async ({ userId, spotId, audioPath }) => {
  // step 1 - delete file from storage
  const { error: removeError } = await supabase.storage.from(GEOXP_AUDIO_BUCKET).remove(audioPath);

  if (removeError) {
    console.error(removeError);
    return { error: removeError };
  }

  // step 2 -delete from SPOTS table
  const spotsTableUpdate = await supabase
    .from(GEOXP_SPOTS_TABLE)
    .update({ is_canceled: true })
    .eq('id', spotId)
    .eq('user_id', userId)
    .select();

  if (spotsTableUpdate.error) {
    console.error(spotsTableUpdate.error);
    return {
      error: spotsTableUpdate.error,
    };
  }

  // step 3 -delete all of its relationships with experiences
  const expContentTableUpdate = await supabase
    .from(EXPERIENCE_CONTENT_TABLE)
    .update({ is_canceled: true })
    .eq('spot_id', spotId)
    .select();

  if (expContentTableUpdate.error) {
    console.error(expContentTableUpdate.error);
    return {
      error: expContentTableUpdate.error,
    };
  }

  return {
    data: {
      expTable: spotsTableUpdate.data,
      expContentTable: expContentTableUpdate.data,
    },
  };
};

export const getSpotsByExperienceId = async ({ userId, experienceId }) => {
  try {
    const { data, error } = await supabase
      .from(GEOXP_SPOTS_TABLE)
      .select('*, spot_types (label)')
      .select('*, experience_content_spots!inner(manual_code, pattern_id)')
      .eq('user_id', userId)
      .eq('is_canceled', false)
      .eq('experience_content_spots.is_canceled', false)
      .eq('experience_content_spots.experience_id', experienceId)
      .order('inserted_at', { ascending: false });

    if (error) {
      console.error(error);
      return { error };
    }

    if (data.length === 0) {
      return { data };
    }

    const fileUrlPromises = data.map((spot) =>
      getAudioFileUrl({
        bucketId: GEOXP_AUDIO_BUCKET,
        filePath: spot.audio_content_path,
      })
    );

    const fileUrls = await Promise.all(fileUrlPromises);

    const spots = data.map((spot, idx) => ({
      ...spot,
      audioUrl: fileUrls[idx]?.data?.signedUrl,
    }));

    return { spots, error };
  } catch (error) {
    return { error };
  }
};
