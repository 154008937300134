import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { PiWarningCircle } from 'react-icons/pi';
import {
  Alert,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from '@material-tailwind/react';
import { useWindowSize } from '../hooks/toolkit';
import { useGeneralToast } from '../hooks/toast';
import { deleteExperience } from '../utils/helpers/supabase.helpers';

const RemoveExperienceDialog = ({ isOpen, setIsOpen, experienceName, experienceId, refetchExpList }) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [deleting, setDeleting] = useState(false);
  const { width } = useWindowSize();
  const dialogSize = width <= 640 ? 'lg' : 'xs';

  const deleteExperienceFromLibrary = useCallback(async () => {
    setDeleting(true);
    const { error } = await deleteExperience({ experienceId });

    if (error) {
      console.error(error);
      toastError('Something went wrong :(');
      setDeleting(false);
      return;
    }

    await refetchExpList();
    toastSuccess('Experence sucessfully deleted');
    setDeleting(false);
    setIsOpen(false);
  }, [experienceId]);

  return (
    <Dialog size={dialogSize} open={isOpen} handler={setIsOpen}>
      <DialogHeader>Delete experience</DialogHeader>
      <DialogBody>
        <Alert variant="ghost" color="amber" icon={<PiWarningCircle className="h-6 w-6" />}>
          This operation cannot be undone!
        </Alert>

        <span className="block my-2">
          Are you sure you want to delete your experience <strong>{experienceName}</strong> from your library?
        </span>
      </DialogBody>
      <DialogFooter className="justify-between gap-2">
        <Button size="sm" color="primary" variant="text" onClick={() => setIsOpen(false)} disabled={deleting}>
          CANCEL
        </Button>
        <Button
          size="sm"
          color="red"
          className="bg-accent-red"
          onClick={deleteExperienceFromLibrary}
          disabled={deleting}
        >
          {deleting ? <Spinner className="h-4 w-4" /> : 'CONFIRM DELETE'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

RemoveExperienceDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  experienceName: PropTypes.string,
  experienceId: PropTypes.string,
  refetchExpList: PropTypes.func,
};

export default RemoveExperienceDialog;
