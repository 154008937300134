import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-tailwind/react';
import Mapbox, { NavigationControl, ScaleControl } from 'react-map-gl';
import XpSpotDraggableMarker from '../Map/XpSpotDraggableMarker';
import { DEFAULT_MAP_ZOOM, MAPBOX_TOKEN } from '../utils/constants/mapbox.constants';
import { useUserPreferences } from '../contexts/UserPreferencesContext';
import MapStyleSelector from './MapStyleSelector';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import TerrainSourceLayer from '../Map/TerrainSourceLayer';
import Buildings3DLayer from '../Map/Buildings3DLayer';

const SpotPositionPreview = ({
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  radius,
  deadband,
  interactive,
}) => {
  const mapRef = useRef(null);

  const mapboxInitialView = useMemo(
    () => (latitude && longitude ? { longitude, latitude, zoom: DEFAULT_MAP_ZOOM } : null),
    [latitude, longitude]
  );

  useEffect(() => {
    if (!latitude || !longitude) return;
    mapRef?.current?.easeTo({
      center: [longitude, latitude],
      duration: 500,
    });
  }, [latitude, longitude]);

  const updateLatLng = useCallback((evt) => {
    if (!evt?.lngLat) return;
    const { lat, lng } = evt.lngLat;
    setLatitude(lat);
    setLongitude(lng);
  }, []);

  const { mapStyle, apply3DMap } = useUserPreferences();
  const advMapsAuth = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);

  return (
    mapboxInitialView && (
      <div className="w-full flex flex-col justify-center">
        {interactive && (
          <div className="inline-flex mx-auto justify-center">
            <Alert variant="ghost" className="my-2 py-1">
              <small className="mx-0">
                Drag and drop marker or double click on map to change <em>agami</em> position.
              </small>
            </Alert>
          </div>
        )}
        <div className="w-full h-[650px]">
          <Mapbox
            ref={mapRef}
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle={mapStyle}
            initialViewState={mapboxInitialView}
            attributionControl={false}
            onDblClick={updateLatLng}
            doubleClickZoom={false}
            style={{
              height: '100%',
              width: '100%',
            }}
            terrain={{
              source: 'mapbox-dem',
              exaggeration: apply3DMap ? 1 : 0,
            }}
            maxPitch={75}
          >
            <NavigationControl />
            <ScaleControl position="bottom-right" />
            {latitude && longitude && (
              <XpSpotDraggableMarker
                position={{
                  id: 'agami-preview-position',
                  latitude,
                  longitude,
                  radius,
                  deadband,
                }}
                onDragEnd={updateLatLng}
              />
            )}
            {advMapsAuth && (
              <>
                <div className="absolute p-3 bottom-6 left-1">
                  <MapStyleSelector />
                </div>
                <TerrainSourceLayer />
                <Buildings3DLayer />
              </>
            )}
          </Mapbox>
        </div>
      </div>
    )
  );
};

SpotPositionPreview.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setLatitude: PropTypes.func,
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setLongitude: PropTypes.func,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deadband: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  interactive: PropTypes.bool,
};

export default SpotPositionPreview;
