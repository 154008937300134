import { Button, Input, Typography } from '@material-tailwind/react';
import React from 'react';

const ManualSpot = ({ onPlay }) => {
  const [manualSpot, setManualSpot] = React.useState();

  const handlePlay = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!manualSpot) return;
      onPlay(manualSpot);
    },
    [onPlay, manualSpot]
  );

  return (
    <div className="absolute bottom-0 w-full md:w-96 md:right-3 md:bottom-10 px-6 py-3 z-[999] text-primary bg-white">
      <form onSubmit={handlePlay}>
        <Typography className="pb-1">Play manual spot</Typography>
        <div className="flex items-center gap-x-2">
          <Input
            className="flex-1"
            value={manualSpot || ''}
            onChange={(e) => setManualSpot(e.currentTarget.value)}
            type="text"
            placeholder="Code"
          />
          <Button type="submit">PLAY</Button>
        </div>
      </form>
    </div>
  );
};

export default ManualSpot;
