import React, { useState } from 'react';
import { Button, Input } from '@material-tailwind/react';
import { BiSearch } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';

const SearchBar = ({ search, setSearch }) => {
  const [value, setValue] = useState(search);

  return (
    <div className="w-full flex gap-2">
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Search spots"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setSearch(value);
          }
        }}
      />

      {search && (
        <Button
          size="sm"
          variant="outlined"
          color="primary"
          onClick={() => {
            setValue('');
            setSearch('');
          }}
        >
          <MdClose className="w-3 h-3" />
        </Button>
      )}

      <Button size="sm" onClick={() => setSearch(value)}>
        <BiSearch className="w-3 h-3" />
      </Button>
    </div>
  );
};

export default SearchBar;
