import React from 'react';
import PropTypes from 'prop-types';

const Center = ({ children }) => (
  <div className="w-full h-full flex justify-center items-center">{children}</div>
);

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Center;
