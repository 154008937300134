import React from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { Layer, Marker, Source } from 'react-map-gl';
import { useAgamiMarkerStyle } from '../hooks/useAgamiMarkerStyle';

const XpPatternLayer = ({ feature, onClick }) => {
  const { pattern } = feature.properties;
  const { patternStyle: circleStyle } = useAgamiMarkerStyle();

  const count = pattern.positions?.length ?? 0;
  const circleSize = Math.min(Math.max(count * 2, 20), 100);

  const circleLayerStyle = {
    id: `${feature.id}_circle`,
    type: 'circle',
    paint: {
      'circle-radius': circleSize,
      'circle-stroke-color': pattern.color ?? circleStyle.color,
      'circle-stroke-opacity': circleStyle.opacity,
      'circle-stroke-width': circleStyle.weight,
      'circle-color': pattern.color ?? circleStyle.fillColor,
      'circle-opacity': circleStyle.fillOpacity,
      'circle-pitch-alignment': 'map',
    },
  };

  return (
    <>
      <Source id={`${feature.id}_source`} key={`${feature.id}_source`} type="geojson" data={feature}>
        <Layer {...circleLayerStyle} />
      </Source>

      <Marker
        longitude={feature.geometry.coordinates[0]}
        latitude={feature.geometry.coordinates[1]}
        anchor="center"
        onClick={onClick}
      >
        <Tooltip
          className="bg-transparent"
          content={
            <div
              className="py-1.5 px-3 rounded-lg"
              style={{ backgroundColor: pattern?.color ?? circleStyle.color }}
            >
              <Typography className="font-bold">{pattern.label ?? 'Click to explore pattern'}</Typography>
              {pattern.description && <Typography variant="small">{pattern.description}</Typography>}
            </div>
          }
        >
          <div className="p-2 bg-transparent">
            <Typography className="font-bold">{count}</Typography>
          </div>
        </Tooltip>
      </Marker>
    </>
  );
};

export default XpPatternLayer;
