import React, { useState } from 'react';
import { Alert, Button, Input } from '@material-tailwind/react';
import { BiSearch } from 'react-icons/bi';
import { checkRedeemCode } from '../utils/helpers/supabase.helpers';
import { useGeneralToast } from '../hooks/toast';
import { formatDateString } from '../utils/helpers/dates.helpers';
import { REDEEM_CODE_VALIDITY } from '../utils/constants/supabase.constants';

const CheckCode = () => {
  const [code, setCode] = useState('');
  const [result, setResult] = useState();
  const [codeValid, setCodeValid] = useState();
  const { error: toastError } = useGeneralToast();

  const desribeCode = (foundCode) => {
    if (!foundCode) return;
    const { user_email, redeemed, issued_at } = foundCode;
    const issueDate = new Date(issued_at).getTime();
    const now = Date.now();
    const diff = (now - issueDate) / (1000 * 3600 * 24); // days
    const expired = diff > REDEEM_CODE_VALIDITY;
    const isValid = !redeemed && !expired;
    setCodeValid(isValid);
    return `
      Code issued for ${user_email} at ${formatDateString(issued_at)}.
      ${isValid ? 'VALID' : 'NOT VALID'}
    `;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { data, error } = await checkRedeemCode({ code });
    if (error) {
      toastError(error);
      return;
    }

    if (!data || data?.length === 0) {
      setResult('Code not found');
    }

    const codeData = data[0];
    const desc = desribeCode(codeData);
    setResult(desc);
  };

  return (
    <div className="w-full grid grid-cols-3 gap-4">
      <form className="w-full flex gap-2" onSubmit={onSubmit}>
        <Input
          value={code}
          onChange={(e) => {
            setResult('');
            setCode(e.target.value);
          }}
          label="Check code"
        />

        <Button size="sm" type="submit">
          <BiSearch className="w-3 h-3" />
        </Button>
      </form>

      <div className="col-span-2">
        {result && (
          <Alert color={codeValid ? 'green' : 'amber'} variant="ghost">
            {result}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default CheckCode;
