import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { PiInfoLight } from 'react-icons/pi';
import { Radio, Typography, Tooltip } from '@material-tailwind/react';
import { AgamiSpotPropType, SpotTypes } from '../PropTypes';

const AgamiTypeSelector = ({ isPublic, formData, handleDataChange, availableSpotTypes }) => (
  <div className="flex gap-4 justify-start items-center">
    <div className="flex gap-1 justify-start items-center">
      <Typography className="font-bold text-primary">Agami Type</Typography>
      <Tooltip
        content={
          <div className="w-[300px]">
            <Typography variant="small" className="font-bold">
              Choosing Geo the audio content will be reproduced automatically detecting the user&apos;s geo
              location. Chosing Manual the user will need to manually insert a code in the app.
            </Typography>
          </div>
        }
        placement="right"
        interactive
      >
        <span>
          <PiInfoLight className="text-primary" />
        </span>
      </Tooltip>
    </div>
    <div className="flex gap-1 justify-center items-center">
      {Object.keys(availableSpotTypes).map((k) => {
        const type = Number(k);
        return (
          <Radio
            key={k}
            type="radio"
            value={type}
            checked={formData.type === type}
            onChange={() => handleDataChange('type', type)}
            label={capitalize(availableSpotTypes[type])}
            disabled={isPublic}
          />
        );
      })}
    </div>
  </div>
);

AgamiTypeSelector.propTypes = {
  isPublic: PropTypes.bool,
  formData: AgamiSpotPropType,
  handleDataChange: PropTypes.func,
  availableSpotTypes: SpotTypes,
};

export default AgamiTypeSelector;
