import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  IconButton,
  Button,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react';
import truncate from 'lodash/truncate';
import { PiCheckCircleDuotone, PiPlusBold } from 'react-icons/pi';
import { addAgamiToPattern } from '../utils/helpers/supabase.helpers';
import { useGeneralToast } from '../hooks/toast';
import ResponsiveDialog from '../components/ResponsiveDialog';

const AddAgamiToPatternRow = ({
  id,
  name,
  description,
  inserted_at,
  experience_content_spots,
  refetch,
  currentPatternId,
  currentExpId,
}) => {
  const { error: toastError } = useGeneralToast();
  const [adding, setAdding] = useState();
  const agamiExpRelationIds = experience_content_spots?.map((expRel) => expRel.pattern_id) ?? [];

  const cancelAddAgamiToPattern = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setAdding();
  }, []);

  const confirmAddAgamiToPattern = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setAdding('loading');

      const { error } = await addAgamiToPattern({
        agamiId: id,
        patternId: currentPatternId,
        experienceId: currentExpId,
      });

      if (error) {
        toastError(error);
        setAdding();
        return;
      }

      await refetch();
      setAdding();
    },
    [id, agamiExpRelationIds, currentPatternId, currentExpId]
  );

  const handleAddAgamiToPattern = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      experience_content_spots[0] &&
      experience_content_spots[0].pattern_id &&
      experience_content_spots[0].pattern_id !== currentPatternId
    ) {
      setAdding('awaitingConfirmation');
    } else {
      confirmAddAgamiToPattern(e);
    }
  }, []);

  const alreadyPresent = agamiExpRelationIds?.includes(currentPatternId);

  return (
    <>
      <ResponsiveDialog
        isOpen={adding === 'awaitingConfirmation'}
        setIsOpen={(isOpen) => !isOpen && setAdding()}
      >
        <DialogHeader>Agami is part of another pattern</DialogHeader>

        <DialogBody>
          <span className="block my-2">
            This Agami is already part of another pattern, this operation will add it to the current pattern
            and remove it from the other. Do you want to proceed?
          </span>
          <span />
        </DialogBody>
        <DialogFooter className="justify-between gap-2">
          <Button size="sm" color="primary" variant="text" onClick={cancelAddAgamiToPattern}>
            CANCEL
          </Button>
          <Button size="sm" onClick={confirmAddAgamiToPattern}>
            CONFIRM
          </Button>
        </DialogFooter>
      </ResponsiveDialog>

      <tr key={id} className="even:bg-blue-gray-50/50 text-center">
        <td className="py-2">
          <Typography title={name} variant="small" className="font-black">
            {truncate(name, {
              separator: ' ',
            })}
          </Typography>
        </td>
        <td className="py-2">
          <Typography title={description} variant="small">
            {truncate(description, {
              separator: ' ',
            })}
          </Typography>
        </td>
        <td className="py-2">
          <Typography variant="small">{new Date(inserted_at).toLocaleDateString('en-GB')}</Typography>
        </td>
        <td className="py-2">
          {alreadyPresent ? (
            <span>
              <PiCheckCircleDuotone className="mx-auto text-green-800 text-[20px] my-1" />
            </span>
          ) : (
            <IconButton
              variant="text"
              size="sm"
              color="yellow"
              onClick={handleAddAgamiToPattern}
              disabled={adding}
            >
              <PiPlusBold className="text-primary text-[14px]" />
            </IconButton>
          )}
        </td>
      </tr>
    </>
  );
};

AddAgamiToPatternRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  inserted_at: PropTypes.string,
  experience_content_spots: PropTypes.arrayOf(PropTypes.shape({ pattern_id: PropTypes.string })),
  currentPatternId: PropTypes.string,
  currentExpId: PropTypes.string,
  refetch: PropTypes.func,
};

export default AddAgamiToPatternRow;
