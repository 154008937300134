import React, { useMemo } from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { Source, Layer, Marker } from 'react-map-gl';
import {
  ACTIVE_RADIUS_CIRCLE_STYLE_OPTS,
  ACTIVE_DEADBAND_CIRCLE_STYLE_OPTS,
} from '../utils/constants/map.constants';
import { metersToPixelsAtMaxZoom } from '../utils/helpers/map.helpers';
import { useAgamiMarkerStyle } from '../hooks/useAgamiMarkerStyle';
import AgamiMarkerIcon from '../components/AgamiMarkerIcon';

const XpSpotLayer = ({ feature, isActive }) => {
  const { position, pattern } = feature.properties;
  const { radiusStyle, deadbandStyle } = useAgamiMarkerStyle();

  const radiusCircleStyle = useMemo(
    () =>
      isActive
        ? ACTIVE_RADIUS_CIRCLE_STYLE_OPTS
        : {
            ...radiusStyle,
            ...(pattern?.color ? { color: pattern?.color, fillColor: pattern?.color } : {}),
          },
    [isActive, radiusStyle, pattern]
  );

  const deadbandCircleStyle = useMemo(
    () =>
      isActive
        ? ACTIVE_DEADBAND_CIRCLE_STYLE_OPTS
        : {
            ...deadbandStyle,
            ...(pattern?.color ? { color: pattern?.color, fillColor: pattern?.color } : {}),
          },
    [isActive, deadbandStyle, pattern]
  );

  const radiusCircleSize = metersToPixelsAtMaxZoom(position.radius, position.lat);

  const deadbandCircleSize = metersToPixelsAtMaxZoom(position.radius + position.deadband, position.lat);

  const radiusLayerStyle = {
    id: `${position.id}_radius_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, radiusCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': radiusCircleStyle.color,
      'circle-stroke-opacity': radiusCircleStyle.opacity,
      'circle-stroke-width': radiusCircleStyle.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-color': radiusCircleStyle.fillColor,
      'circle-opacity': radiusCircleStyle.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-pitch-alignment': 'map',
    },
  };

  const deadbandLayerStyle = {
    id: `${feature.id}_deadband_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, deadbandCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': deadbandCircleStyle.color,
      'circle-stroke-opacity': deadbandCircleStyle.opacity,
      'circle-stroke-width': deadbandCircleStyle.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-color': deadbandCircleStyle.fillColor,
      'circle-opacity': deadbandCircleStyle.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-pitch-alignment': 'map',
    },
  };

  return (
    <>
      <Source id={`${feature.id}_source`} key={`${feature.id}_source`} type="geojson" data={feature}>
        <Layer {...radiusLayerStyle} />
        <Layer {...deadbandLayerStyle} />
      </Source>

      <Marker
        longitude={feature.geometry.coordinates[0]}
        latitude={feature.geometry.coordinates[1]}
        anchor="center"
      >
        <Tooltip
          className="bg-transparent"
          content={
            <div className="py-1.5 px-3 rounded-lg" style={{ backgroundColor: radiusCircleStyle.color }}>
              <Typography className="font-bold">{position.label}</Typography>
              <Typography variant="small">{position.description}</Typography>
            </div>
          }
        >
          <span>
            <AgamiMarkerIcon color={radiusCircleStyle.color} />
          </span>
        </Tooltip>
      </Marker>
    </>
  );
};

export default XpSpotLayer;
