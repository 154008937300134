import React, { useState } from 'react';
import {
  Input,
  Typography,
  Button,
  Spinner,
  Tooltip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import { BiCurrentLocation } from 'react-icons/bi';
import { PiLightbulbFilamentLight } from 'react-icons/pi';
import { getRandomSpot } from '../utils/helpers/geoxp.helpers';
import {
  MAX_SPOT_DEADBAND,
  MAX_SPOT_RADIUS,
  MIN_SPOT_DEADBAND,
  MIN_SPOT_RADIUS,
} from '../utils/constants/geoxp.constants';
import PositionSuggestion from './PositionSuggestion';
import { getLocationAndReverseGeocoding } from '../utils/helpers/mapbox.helpers';
import { useGeneralToast } from '../hooks/toast';
import { validateInputNumber } from '../utils/helpers/validate.helpers';
import { AccordionCaret } from '../components/AccordionCaret';

const SpotPositionSelector = ({ formData, setFormData, uploading, handleDataChange, checkInputIsValid }) => {
  const [suggestionInput, setSuggestionInput] = useState('');
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [openAdvSettings, setOpenAdvSettings] = useState(false);
  const { error: toastError } = useGeneralToast();

  const onSuggestionSelect = (value) => {
    handleDataChange('longitude', parseFloat(value?.center[0]));
    handleDataChange('latitude', parseFloat(value?.center[1]));
  };

  const onNeedInspiration = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { latitude, longitude, radius, deadband, label } = getRandomSpot();
    setSuggestionInput(label);
    handleDataChange();
    setFormData((current) => ({
      ...current,
      latitude,
      longitude,
      radius,
      deadband,
    }));
  };

  const onCurrentLocation = async (e) => {
    setLoadingLocation(true);
    e.preventDefault();
    e.stopPropagation();
    const { latitude, longitude, radius, label, error } = await getLocationAndReverseGeocoding();
    if (error) {
      toastError('Error getting your location');
      setLoadingLocation(false);
      return;
    }
    setSuggestionInput(label);
    setFormData((current) => ({
      ...current,
      latitude,
      longitude,
      radius,
    }));
    setLoadingLocation(false);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography className="font-bold text-primary">Position</Typography>

        <div className="flex items-center space-x-2">
          <Tooltip content="Need inspiration?">
            <Button
              type="button"
              size="sm"
              onClick={onNeedInspiration}
              disabled={loadingLocation || uploading}
            >
              <div className="flex gap-2 items-center justify-center">
                <PiLightbulbFilamentLight className="w-4 h-4" /> ?
              </div>
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="w-full flex gap-2">
        <PositionSuggestion
          suggestionInput={suggestionInput}
          setSuggestionInput={setSuggestionInput}
          onSuggestionSelect={onSuggestionSelect}
        />
        <Tooltip content="Use your current position">
          <Button color="gray" variant="outlined" onClick={onCurrentLocation}>
            {loadingLocation ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <BiCurrentLocation className="text-blue-gray-500" />
            )}
          </Button>
        </Tooltip>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-2">
        <Input
          className="w-full"
          value={formData.latitude}
          onChange={(e) => handleDataChange('latitude', validateInputNumber(e.currentTarget.value ?? ''))}
          type="number"
          step="0.001"
          label="Latitude"
          required
          disabled={uploading}
        />
        <Input
          className="w-full"
          value={formData.longitude}
          onChange={(e) => handleDataChange('longitude', validateInputNumber(e.currentTarget.value ?? ''))}
          type="number"
          step="0.001"
          label="Longitude"
          required
          disabled={uploading}
        />
      </div>

      <Input
        className="w-full"
        value={formData.radius}
        onChange={(e) => handleDataChange('radius', validateInputNumber(e.currentTarget.value ?? ''))}
        onBlur={() => {
          checkInputIsValid('radius', formData.radius > MIN_SPOT_RADIUS, MIN_SPOT_RADIUS);
          checkInputIsValid('radius', formData.radius < MAX_SPOT_RADIUS, MAX_SPOT_RADIUS);
        }}
        type="number"
        min={MIN_SPOT_RADIUS}
        max={MAX_SPOT_RADIUS}
        label="Radius (m)"
        required
        disabled={uploading}
      />
      <Typography variant="small" className="mt-[-8px]">
        Min radius: <em className="font-bold">{MIN_SPOT_RADIUS} m</em> / Max radius:{' '}
        <em className="font-bold">{MAX_SPOT_RADIUS} m</em>
      </Typography>

      <Accordion open={openAdvSettings} icon={<AccordionCaret rotate={openAdvSettings} />}>
        <AccordionHeader
          onClick={() => setOpenAdvSettings((prev) => !prev)}
          className="justify-center gap-4 text-md"
        >
          Advanced settings
        </AccordionHeader>
        <AccordionBody>
          <Input
            className="w-full"
            value={formData.deadband}
            onChange={(e) => handleDataChange('deadband', validateInputNumber(e.currentTarget.value ?? ''))}
            onBlur={() => {
              if (formData.deadband === '') return;
              checkInputIsValid('deadband', formData.deadband > MIN_SPOT_DEADBAND, MIN_SPOT_DEADBAND);
              checkInputIsValid('deadband', formData.deadband < MAX_SPOT_DEADBAND, MAX_SPOT_DEADBAND);
            }}
            type="number"
            min={MIN_SPOT_DEADBAND}
            max={MAX_SPOT_DEADBAND}
            label="Deadband (m)"
            disabled={uploading}
          />
          <Typography variant="small">
            The <strong>deadband</strong> is a ring outside the active circle where no action will happen.
            When the listener leaves the position inside the active circle, but he&apos;s still inside the
            deadband, the audio content will continue playing. When the listener leaves also the deadband the
            audio content fades out and stops.
            <strong>Leave blank to use default value of 15 meters.</strong>
          </Typography>
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default SpotPositionSelector;
