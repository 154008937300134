import React from 'react';
import { Typography } from '@material-tailwind/react';
import { Link, useSearchParams } from 'react-router-dom';
import Container from '../components/Container';
import illustration from '../assets/png/expired_invite.png';
import { usePageTitle } from '../hooks/toolkit';

const ExpiredLink = () => {
  usePageTitle('Invitation expired | Agami');
  const [searchParams] = useSearchParams();
  const redeemCode = searchParams.get('redeemCode');
  return (
    <Container direction="col" bgColorClass="bg-white" className="text-primary text-center">
      <Typography variant="h2">WE&apos;RE OUT OF TIME...</Typography>

      <Typography>Dear friend, this invitation link is expired or invalid.</Typography>

      <div className="w-full md:w-1/2 h-[1px] border my-4" />

      <Typography variant="h4">
        But <strong className="font-black">don&apos;t worry</strong>, it&apos;s no problem!
      </Typography>

      <Typography>
        You can create an account{' '}
        <strong className="underline">
          <Link to="/register">here</Link>
        </strong>
        .
      </Typography>

      {redeemCode && (
        <Typography className="mt-2">
          {' '}
          Then, once you registered, you can use this code to claim your Early Bird perks:{' '}
          <span className="font-black">{redeemCode}</span>
        </Typography>
      )}
      <img
        title="Invalid invitation"
        alt="Invalid invitation"
        src={illustration}
        className="opacity-90 mt-16 mb-8 mx-auto md:w-1/4"
      />

      <Typography className="mb-8">
        Need support?{' '}
        <strong className="underline">
          <Link to="mailto:agami@mezzoforte.design">contact us!</Link>
        </strong>
      </Typography>
    </Container>
  );
};

export default ExpiredLink;
