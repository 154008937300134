import { Typography } from '@material-tailwind/react';
import { PiProhibitInset } from 'react-icons/pi';

const Unauthorized = () => (
  <div className="w-full flex flex-col justify-between items-start md:items-center py-4 gap-4">
    <div className="flex justify-center items-end gap-4 text-accent-red-700">
      <PiProhibitInset className="w-7 h-7" />
      <Typography variant="h3">UNAUTHORIZED</Typography>
    </div>
    <Typography className="font-bold">You don&apos;t have the right permissions to see this page.</Typography>
  </div>
);

export default Unauthorized;
