import React, { useState } from 'react';

import { IconButton, Spinner } from '@material-tailwind/react';

import { BsDownload } from 'react-icons/bs';
import { downloadGeoXpExperience } from '../utils/helpers/supabase.helpers';

import { useGeneralToast } from '../hooks/toast';

import { downloadJson } from '../utils/helpers/download.helpers';

const DownloadExpButton = ({ experienceId }) => {
  const { success, error } = useGeneralToast();

  const [downloading, setDownloading] = useState(false);

  const downloadExperience = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDownloading(true);

    const { data, error: err } = await downloadGeoXpExperience({ experienceId });

    if (err) {
      console.error(err);
      error('Error downloading experience');
      setDownloading(false);
      return;
    }

    await downloadJson({
      data,
      filename: `agami-exp-config-${experienceId}`,
    });

    success('Experience downloaded successfully');

    setDownloading(false);
  };

  return (
    <IconButton size="sm" color="primary" onClick={downloadExperience} disabled={downloading}>
      {downloading ? <Spinner className="h-4 w-4" /> : <BsDownload className="h-4 w-4" />}
    </IconButton>
  );
};

export default DownloadExpButton;
