import React, { useState } from 'react';
import { Typography, Input, Button, Spinner } from '@material-tailwind/react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { resetPasswordEmail } from '../utils/helpers/supabase.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { useGeneralToast } from '../hooks/toast';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';

const ResetPassword = () => {
  usePageTitle('Reset password | Agami');

  const { user } = useSessionContext();
  const location = useLocation();

  const { success: toastSuccess, error: toastError, warn: toastWarn } = useGeneralToast();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const forgotPassword = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!email) {
      toastWarn('Write your email to send a password reset link');
      return;
    }

    setLoading(true);

    const { error } = await resetPasswordEmail({ email });

    if (error) {
      toastError('Cannot send the reset link');
      console.error(error);
    } else {
      toastSuccess('A message containing password reset istructions has been sent to your email');
    }

    setLoading(false);
  };

  if (user) {
    // send a logged user to its dashboard
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return (
    <Container direction="col">
      <Typography className="mt-4 mb-12" variant="h1" color="white">
        RESET PASSWORD
      </Typography>

      <form className="w-full md:w-1/2 flex flex-col gap-8" onSubmit={forgotPassword}>
        <Input
          color="white"
          value={email || ''}
          onChange={(e) => setEmail(e.currentTarget.value)}
          type="email"
          label="Email address"
          required
        />
        <Button type="submit" disabled={loading}>
          {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'SEND RESET LINK'}
        </Button>
      </form>

      <hr className="w-full md:w-1/2 my-12 opacity-50" />

      <div className="flex w-full justify-center items-center gap-2">
        <Typography color="white">Already have an account?</Typography>
        <Link to="/signin">
          <Typography color="white" className="underline font-bold">
            Sign In
          </Typography>
        </Link>
      </div>
    </Container>
  );
};

export default ResetPassword;
