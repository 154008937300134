import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { useGeneralToast } from '../hooks/toast';
import { getAdminAgamiList } from '../utils/helpers/supabase.helpers';
import Container from '../components/Container';
import GoBack from '../components/GoBack';
import ExperienceSpotsList from '../ViewDashboard/ExperienceSpotsList';
import { useQueryParams } from '../hooks/toolkit';
import SearchBar from '../components/Search';

const PAGE_SIZE = 50;

const SpotsList = () => {
  const { error: toastError } = useGeneralToast();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [agamiList, setAgamiList] = useState([]);
  const [agamiCount, setAgamiCount] = useState(0);

  const page = useMemo(() => {
    try {
      return parseInt(queryParams.get('page') ?? 1, 10);
    } catch {
      return 1;
    }
  }, [queryParams]);

  const sort = useMemo(() => queryParams.get('sort') ?? '-inserted_at', [queryParams]);

  const getAgamiList = useCallback(async () => {
    setLoading(true);

    const sortKey = sort.startsWith('-') ? sort.substring(1) : sort;
    const ascending = sort.startsWith('-');

    const keys = sortKey?.split('.') ?? [];
    const column = keys.length > 1 ? keys[1] : keys[0];
    const referencedTable = keys.length > 1 ? keys[0] : undefined;

    const { data, count, error } = await getAdminAgamiList({
      range: [(page - 1) * PAGE_SIZE, page * PAGE_SIZE - 1],
      sort: [referencedTable ? `${referencedTable}(${column})` : column, { ascending }],
      search,
    });

    if (error) {
      toastError(error);
    }

    setAgamiList(data ?? []);
    setAgamiCount(count ?? 0);
    setLoading(false);
  }, [page, sort, search]);

  const setPage = (newPage) => {
    navigate(`/admin/list-spots?page=${newPage}&sort=${sort}`);
  };

  const setSort = (newSort) => {
    navigate(`/admin/list-spots?page=${page}&sort=${newSort}`);
  };

  useEffect(() => {
    getAgamiList();
  }, [page, sort, search]);

  return (
    <Container bgColorClass="bg-white" direction="col">
      <div className="mb-2 self-start">
        <GoBack />
      </div>

      <Typography variant="lead">Spots list</Typography>

      <div className="w-full p-4">
        <SearchBar search={search} setSearch={setSearch} />

        <ExperienceSpotsList
          agamiListLoading={loading}
          agamiList={agamiList}
          agamiCount={agamiCount}
          pageSize={PAGE_SIZE}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          showOwner
        />
      </div>
    </Container>
  );
};

export default SpotsList;
