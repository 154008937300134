import { Link } from 'react-router-dom';
import { IconButton, Typography } from '@material-tailwind/react';
import { AiOutlinePlus } from 'react-icons/ai';
import AgamiCardGrid from '../ViewDashboard/AgamiCardGrid';
import { useFeatureAuth } from '../hooks/authorization';
import { usePageTitle } from '../hooks/toolkit';
import { FEATURES } from '../utils/constants/features.constants';

const DashboardAgami = () => {
  usePageTitle('Agami Library | Agami Dashboard');
  const uploadSpotsAuth = useFeatureAuth(FEATURES.UPLOAD_SPOTS_UI);
  return (
    <>
      <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center py-4">
        <Typography variant="h1" className="text-2xl md:text-5xl">
          <em>AGAMI</em> LIBRARY
        </Typography>
        <Link to="/upload/spot">
          <div className="flex items-center space-x-4">
            <IconButton size="sm" color="primary" className="rounded-full">
              <AiOutlinePlus className="text-[20px]" />
            </IconButton>
            <Typography className="font-bold">Upload agami</Typography>
          </div>
        </Link>
      </div>
      {uploadSpotsAuth && <AgamiCardGrid />}
    </>
  );
};

export default DashboardAgami;
