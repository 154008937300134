import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Spinner } from '@material-tailwind/react';
import { useWindowSize } from '../hooks/toolkit';
import { useGeneralToast } from '../hooks/toast';
import { removeAgamiFromPattern } from '../utils/helpers/supabase.helpers';
import { AgamiSpotDetailPropType, PatternDetailType } from '../PropTypes';

const RemoveAgamiFromPatternDialog = ({
  isOpen,
  setIsOpen,
  agamiToRemove,
  patternDetails,
  refetchAgamiList,
}) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [deleting, setDeleting] = useState(false);
  const { width } = useWindowSize();
  const dialogSize = width <= 640 ? 'xl' : 'xs';

  const deleteFromPattern = useCallback(async () => {
    if (!agamiToRemove || !patternDetails) return;
    setDeleting(true);
    const { error } = await removeAgamiFromPattern({
      agamiId: agamiToRemove.id,
      patternId: patternDetails.pattern_id,
      experienceId: patternDetails.experience_id,
    });

    if (error) {
      console.error(error);
      toastError('Something went wrong');
      setDeleting(false);
      return;
    }

    await refetchAgamiList();
    toastSuccess('Agami removed from this pattern');
    setDeleting(false);
    setIsOpen(false);
  }, [agamiToRemove, patternDetails, removeAgamiFromPattern, refetchAgamiList]);

  return (
    <Dialog size={dialogSize} open={isOpen} handler={setIsOpen}>
      <DialogHeader>Remove agami from pattern</DialogHeader>

      <DialogBody>
        <span className="block my-2">
          Are you sure you want to remove your agami <strong>{agamiToRemove?.name}</strong> from the pattern{' '}
          <strong>{patternDetails?.name}</strong>?
        </span>
        <span />
      </DialogBody>
      <DialogFooter className="justify-between gap-2">
        <Button size="sm" color="primary" variant="text" onClick={() => setIsOpen(false)} disabled={deleting}>
          CANCEL
        </Button>
        <Button
          size="sm"
          color="red"
          className="bg-accent-red"
          onClick={deleteFromPattern}
          disabled={deleting}
        >
          {deleting ? <Spinner className="h-4 w-4" /> : 'CONFIRM REMOVE'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

RemoveAgamiFromPatternDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  agamiToRemove: AgamiSpotDetailPropType,
  patternDetails: PatternDetailType,
  refetchAgamiList: PropTypes.func,
};

export default RemoveAgamiFromPatternDialog;
