import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@material-tailwind/react';
import ResponsiveDialog from '../components/ResponsiveDialog';
import { useUserAgamiListByExperience } from '../hooks/useUserAgamiListByExperience';
import Center from '../components/Center';
import AddAgamiToPatternTable from './AddAgamiToPatternTable';

const AddAgamiToPattern = ({
  isOpen,
  setIsOpen,
  refetchAgamiList,
  currentPatternId,
  currentExperienceId,
}) => {
  const {
    agamiList: agamiExpList,
    isLoading: agamiExpListLoading,
    refetch: refetchAgamiExpList,
  } = useUserAgamiListByExperience({
    experienceId: currentExperienceId,
    enabled: isOpen,
  });

  const refetchAfterChange = useCallback(async () => {
    await refetchAgamiList();
    await refetchAgamiExpList();
  }, [refetchAgamiList, refetchAgamiExpList]);

  return (
    <ResponsiveDialog isOpen={isOpen} setIsOpen={setIsOpen} title="Add agami to this pattern">
      {agamiExpListLoading ? (
        <Center>
          <Spinner className="h-6 w-6 my-4" />
        </Center>
      ) : (
        <>
          {(!agamiExpList || !agamiExpList.length === 0) && (
            <span>
              There are no <em>agami</em> in the experience that contains this pattern.
            </span>
          )}

          {agamiExpList && agamiExpList.length > 0 && (
            <AddAgamiToPatternTable
              currentExpId={currentExperienceId}
              currentPatternId={currentPatternId}
              refetchAfterChange={refetchAfterChange}
              agamiList={agamiExpList}
            />
          )}
        </>
      )}
    </ResponsiveDialog>
  );
};

AddAgamiToPattern.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetchAgamiList: PropTypes.func,
  currentPatternId: PropTypes.string,
  currentExperienceId: PropTypes.string,
};

export default AddAgamiToPattern;
