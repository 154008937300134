import { IconButton, Tooltip } from '@material-tailwind/react';
import { PiFootprintsFill, PiSidebarSimpleBold } from 'react-icons/pi';
import { MdOutlineFitScreen } from 'react-icons/md';
import VolumeButton from './VolumeButton';
import { useMapControls } from '../contexts/MapControlsContext';
import { useXp } from '../contexts/XpContext';
import { useDevice } from '../hooks/device';
import { basicControlStyle, activeControlStyle, inactiveControlStyle } from '../style/tailwindClasses';

const Controls = () => {
  const { fitMapBounds, setDrawer, drawerForced } = useMapControls();
  const { manualMode, setManualMode, volume, setVolume, mute, setMute } = useXp();

  const { platformType } = useDevice();
  const isMobile = platformType === 'mobile';

  return (
    <div className="absolute top-1 left-1 p-3 z-[33]">
      <div className="flex flex-col gap-y-2 md:gap-y-3 align-middle">
        {!drawerForced && (
          <Tooltip content="Open experiences list" placement="left" {...(isMobile && { open: false })}>
            <IconButton
              size="md"
              color="white"
              className={`${basicControlStyle}`}
              onClick={() => setDrawer((prev) => !prev)}
            >
              <PiSidebarSimpleBold className="h-4 w-4 md:h-5 md:w-5" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip
          content={manualMode ? 'Use your real geolocation' : 'Simulate your position'}
          placement="left"
          {...(isMobile && { open: false })}
        >
          <IconButton
            size="md"
            color="white"
            className={`${basicControlStyle} ${manualMode ? activeControlStyle : inactiveControlStyle}`}
            onClick={() => setManualMode((prev) => !prev)}
          >
            <PiFootprintsFill className="h-4 w-4 md:h-5 md:w-5" />
          </IconButton>
        </Tooltip>

        <Tooltip content="Fit bounds" placement="left" {...(isMobile && { open: false })}>
          <IconButton size="md" color="white" className={basicControlStyle} onClick={() => fitMapBounds()}>
            <MdOutlineFitScreen className="h-4 w-4 md:h-5 md:w-5" />
          </IconButton>
        </Tooltip>

        <Tooltip content={mute ? 'Unmute' : 'Mute'} placement="left" {...(isMobile && { open: false })}>
          <IconButton
            size="md"
            color="white"
            className={`${basicControlStyle} ${mute ? activeControlStyle : inactiveControlStyle}`}
            onClick={() => setMute(!mute)}
          >
            <VolumeButton mute={mute} volume={volume} />
          </IconButton>
        </Tooltip>

        <Tooltip content="Set volume" placement="left" {...(isMobile && { open: false })}>
          <div className="text-center">
            <input
              type="range"
              className="w-2 accent-primary cursor-pointer"
              orient="vertical"
              style={{ MozOrient: 'vertical', appearance: 'slider-vertical' }}
              onChange={(e) => {
                setVolume(e.target.valueAsNumber);
              }}
              value={volume}
              max={1}
              min={0}
              step={0.01}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Controls;
