import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';
import AgamiPreviewCard from './AgamiPreviewCard';
import NoUploads from '../components/NoUploads';
import { AgamiSpotDetailPropType } from '../PropTypes';

const ExperienceSpotsCards = ({ agamiListLoading, agamiList, removeFromExperience }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
    {!agamiListLoading && (!agamiList || agamiList.length === 0) && (
      <div className="col-span-2">
        <NoUploads>
          <Typography>
            There are no <em>agamis</em> in this experience yet.
          </Typography>
        </NoUploads>
      </div>
    )}

    {!agamiListLoading &&
      agamiList?.map((agami) => (
        <div key={agami.id} className="flex-1 mb-4 basis-1/4">
          <AgamiPreviewCard spot={agami} removeFromExperience={() => removeFromExperience(agami)} />
        </div>
      ))}
  </div>
);

ExperienceSpotsCards.propTypes = {
  agamiListLoading: PropTypes.bool,
  agamiList: PropTypes.arrayOf(AgamiSpotDetailPropType),
  removeFromExperience: PropTypes.func,
};

export default ExperienceSpotsCards;
