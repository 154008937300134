import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { getUserAgamiListWithExpRelations, getUserAgamiList } from '../utils/helpers/user-spot.helpers';

export const useUserAgamiList = (options = {}) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [agamiList, setAgamiList] = useState(null);
  const { enabled, getExpRelations, getAudioUrls } = options;

  const fetchSpotsData = useCallback(async () => {
    if (enabled === false) {
      return;
    }

    const { spots, error } = getExpRelations
      ? await getUserAgamiListWithExpRelations({ userId: user.id, getAudioUrls })
      : await getUserAgamiList({ userId: user.id, getAudioUrls });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setAgamiList(spots);
    setIsLoading(false);
  }, [user, enabled, getExpRelations, getAudioUrls, getUserAgamiListWithExpRelations, getUserAgamiList]);

  useEffect(() => {
    if (!user || enabled === false) {
      return;
    }

    fetchSpotsData();
  }, [user, enabled]);

  return { agamiList, isLoading, refetch: fetchSpotsData };
};
