import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { Alert, Button, Card, CardBody, IconButton, Typography } from '@material-tailwind/react';
import { PiTrash, PiDownloadSimple, PiDetective } from 'react-icons/pi';
import { formatDateString } from '../utils/helpers/dates.helpers';
import { ROUTE_BETA_URL, ROUTE_PROD_URL } from '../utils/constants/url.constants';
import { theme } from '../tailwind.config';
import DeleteAccessCodeDialog from './DeleteAccessCodeDialog';
import { AccessCodeType, ExperienceDataPropType } from '../PropTypes';
import { isBeta } from '../utils/helpers/url.helpers';

const AccessCodePreviewCard = ({ accessCode, experienceList, refetchCodesList }) => {
  const { colors } = theme.extend;
  const PRIMARY = colors.primary;
  const GRAY = colors['base-gray'];

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // find exp name from ID
  const experience = experienceList.find((exp) => exp.id === accessCode.experience_id);

  const expNameUrlComp = experience?.name ? `&expName=${encodeURIComponent(experience?.name)}` : '';

  const baseUrl = isBeta() ? ROUTE_BETA_URL : ROUTE_PROD_URL;

  const pageUrl = accessCode.anonymous_access ? '/redeem-experience-anonymous' : '/redeem-experience';

  const routeUrl = `https://${baseUrl}${pageUrl}?code=${encodeURIComponent(accessCode.code ?? '')}${expNameUrlComp}`;

  const qrCodeRef = useRef(null);

  const downloadQr = () => {
    if (!qrCodeRef.current) return;
    const svgData = new XMLSerializer().serializeToString(qrCodeRef.current);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${experience.name} QR Code`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
  };

  const manageDelete = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  }, []);

  const expired = accessCode?.expires_at && Date.now() > new Date(accessCode?.expires_at).getTime();

  return (
    <>
      <DeleteAccessCodeDialog
        isOpen={deleteDialogOpen}
        setIsOpen={setDeleteDialogOpen}
        codeToDelete={accessCode}
        refetchCodesList={refetchCodesList}
      />

      <Card className="w-full h-full flex flex-col">
        <CardBody className="flex flex-col w-full">
          <div className="mb-4 flex items-center justify-end">
            <IconButton variant="text" size="sm" color="yellow" onClick={manageDelete} className="ml-auto">
              <PiTrash className="text-primary text-[20px]" />
            </IconButton>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <Typography className="font-bold text-primary-500">Experience</Typography>
            <em>{experience.name}</em>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <Typography className="font-bold text-primary-500">Created at</Typography>
            <em>{accessCode.created_at ? formatDateString(accessCode.created_at) : '-'}</em>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <Typography className="font-bold text-primary-500">Code expires at</Typography>
            <em>{accessCode.expires_at ? formatDateString(accessCode.expires_at) : 'never'}</em>
          </div>

          <div className="mb-4 flex items-center justify-between">
            <Typography className="font-bold text-primary-500">Access expires after</Typography>

            <em>{accessCode.access_expires_after} days</em>
          </div>

          {accessCode.anonymous_access && (
            <div className="mb-4">
              <Alert color="blue" variant="ghost" icon={<PiDetective className="w-6 h-6" />}>
                <Typography variant="h3">Anonymous access code</Typography>
                <Typography variant="small">
                  This code allows anonymous users (without an accout) to access the experience.
                </Typography>
              </Alert>
            </div>
          )}

          <div className="w-full md:w-2/3 h-auto rounded-xl shadow-lg py-4 px-8 mx-auto relative text-center bg-base-gray-400 flex flex-col justify-center items-center gap-4">
            {expired && (
              <div className="absolute rounded-xl top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-primary-500 bg-opacity-80">
                <Typography className="font-bold text-3xl text-accent-500">EXPIRED</Typography>
              </div>
            )}

            <Typography className="font-bold text-primary-500 text-xl">{accessCode.code}</Typography>
            <QRCode
              className="w-full"
              fgColor={PRIMARY[500]}
              bgColor={GRAY[400]}
              ref={qrCodeRef}
              value={routeUrl}
              title={`Route QR Code - ${accessCode.code}`}
            />
            <Button
              size="sm"
              color="primary"
              onClick={downloadQr}
              className="flex justify-center items-center gap-2 mx-auto"
            >
              <PiDownloadSimple className="h-4 w-4" /> DOWNLOAD
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

AccessCodePreviewCard.propTypes = {
  accessCode: AccessCodeType,
  experienceList: PropTypes.arrayOf(ExperienceDataPropType),
  refetchCodesList: PropTypes.func,
};

export default AccessCodePreviewCard;
