import React, { useState } from 'react';
import {
  Button,
  Chip,
  IconButton,
  Typography,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
  Select,
  Option,
  Spinner,
} from '@material-tailwind/react';
import { PiShareNetworkLight, PiPencilSimpleLight } from 'react-icons/pi';
import { shareExperience } from '../utils/helpers/supabase.helpers';
import { useGeneralToast } from '../hooks/toast';
import { useSessionContext } from '../contexts/SessionContext';
import { useDevice } from '../hooks/device';

const EXP_ROLES = {
  1: 'Owner',
  2: 'Editor',
  3: 'Listener',
};

const TABLE_HEAD = ['User', 'Role', ''];

const ShareExperiencePanel = ({ experience, onSuccess }) => {
  const { platformType } = useDevice();

  const { success: toastSuccess, error: toastError } = useGeneralToast();

  const { user } = useSessionContext();

  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [targetEmail, setTargetEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [targetRole, setTargetRole] = useState();
  const [isEditing, setIsEditing] = useState(false);

  const performShare = async (revoke) => {
    if (targetEmail === user.email) {
      toastError("You can't share it with yourself :)");
      return;
    }

    setLoading(true);
    setEmailError(false);

    const { data, error } = await shareExperience({
      email: targetEmail,
      experienceId: experience.id,
      role: Number(targetRole),
      revoke,
    });

    if (error) {
      console.error(error);
      if (error === 'User not found') {
        setEmailError(true);
      }
      toastError(error);
      setLoading(false);
      return;
    }

    console.info({ data });
    toastSuccess('Sharing successfully');
    setShowDialog(false);
    setLoading(false);
    if (typeof onSuccess === 'function') onSuccess();
  };

  const startNewSharing = () => {
    setIsEditing(false);
    setTargetEmail('');
    setShowDialog(true);
  };

  const handleNewSharing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    performShare();
  };

  const startEditSharing = (userEmail) => {
    setIsEditing(true);
    setTargetEmail(userEmail);
    setShowDialog(true);
  };

  const handleEditSharing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const revoke = e.nativeEvent?.submitter?.dataset?.revoke;
    performShare(revoke);
  };

  return (
    <>
      <Dialog
        size={platformType === 'mobile' ? 'xl' : 'xs'}
        open={showDialog}
        handler={() => setShowDialog((cur) => !cur)}
        className="bg-transparent shadow-none porcodio"
      >
        <Card className="mx-auto w-full max-w-full lg:max-w-[24rem] text-primary flex flex-col">
          <form onSubmit={isEditing ? handleEditSharing : handleNewSharing}>
            <CardBody className="flex flex-col gap-4">
              <Typography variant="h4">Share experience</Typography>
              {isEditing && (
                <Typography className="mb-3 font-normal" variant="paragraph">
                  Edit sharing settings for this user.
                </Typography>
              )}

              {!isEditing && (
                <Typography className="mb-3 font-normal" variant="paragraph">
                  Enter the email of the user you want to share the experience with.
                </Typography>
              )}
              <Input
                value={targetEmail}
                label="Email"
                size="lg"
                onChange={(e) => {
                  setEmailError(false);
                  setTargetEmail(e.target.value);
                }}
                disabled={loading || isEditing}
                error={emailError}
                required
              />
              <Select
                label="Role"
                onChange={(value) => setTargetRole(value)}
                value={targetRole}
                disabled={loading}
              >
                <Option value="2">EDITOR</Option>
                <Option value="3">LISTENER</Option>
              </Select>
            </CardBody>
            <CardFooter className="pt-0 w-full">
              {isEditing && (
                <div className="flex items-center justify-evenly gap-6">
                  <Button
                    type="submit"
                    size="sm"
                    fullWidth
                    disabled={loading}
                    className="flex justify-center"
                  >
                    {loading ? <Spinner className="h-4 w-4" /> : 'Update role'}
                  </Button>
                  <Button
                    type="submit"
                    data-revoke
                    color="red"
                    size="sm"
                    fullWidth
                    disabled={loading}
                    className="flex justify-center"
                  >
                    {loading ? <Spinner className="h-4 w-4" /> : 'Revoke'}
                  </Button>
                </div>
              )}

              {!isEditing && (
                <Button type="submit" fullWidth disabled={loading} className="flex justify-center">
                  {loading ? <Spinner className="h-4 w-4" /> : 'Share'}
                </Button>
              )}
            </CardFooter>
          </form>
        </Card>
      </Dialog>

      <div className="flex items-center justify-start gap-4">
        <Typography variant="h5">SHARING CENTER</Typography>
        <Button size="sm" disabled={loading} onClick={startNewSharing}>
          <PiShareNetworkLight />
        </Button>
      </div>

      <div className="my-4">
        {experience.shared_with?.length === 0 && (
          <Typography variant="small" color="primary" className="font-bold leading-none">
            You haven&apos;t shared with anyone yet.
          </Typography>
        )}

        {experience.shared_with?.length > 0 && (
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-500/30 p-4">
                    <Typography variant="small" color="primary" className="font-bold leading-none">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {experience.shared_with?.map((sharedUser) => (
                <tr key={sharedUser.userId} className="even:bg-blue-gray-50/50">
                  <td className="p-4">
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {sharedUser.userEmail}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      <Chip className="inline-block" value={EXP_ROLES[sharedUser.role]} />
                    </Typography>
                  </td>
                  <td className="p-4 text-right">
                    <IconButton
                      size="sm"
                      variant="text"
                      color="blue-gray"
                      onClick={() => startEditSharing(sharedUser.userEmail)}
                    >
                      <PiPencilSimpleLight className="w-4 h-4" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default ShareExperiencePanel;
