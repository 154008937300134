import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { IconButton, Tooltip } from '@material-tailwind/react';
import { LiaSatelliteSolid } from 'react-icons/lia';
import { PiSun, PiStackSimple, PiCubeDuotone } from 'react-icons/pi';
import { useFeatureAuth } from '../hooks/authorization';
import { useUserPreferences } from '../contexts/UserPreferencesContext';
import { FEATURES } from '../utils/constants/features.constants';
import { basicControlStyle, activeControlStyle } from '../style/tailwindClasses';
import { MAPBOX_LIGHT_MAP_STYLE, MAPBOX_SATELLITE_MAP_STYLE } from '../utils/constants/mapbox.constants';

const MapStyleSelector = () => {
  const [showOpts, setShowOpts] = useState(false);
  const { mapStyle, setMapStyle, apply3DMap, setApply3DMap } = useUserPreferences();
  const advancedMapStyles = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);
  return (
    <div className="flex flex-col gap-2">
      <ClickAwayListener onClickAway={() => setShowOpts(false)}>
        <div className="relative z-10">
          {showOpts && (
            <div className="flex flex-col gap-3 mb-3">
              <Tooltip content="Light mode" placement="right">
                <IconButton
                  size="md"
                  color="white"
                  className={`
                    ${mapStyle === MAPBOX_LIGHT_MAP_STYLE ? activeControlStyle : basicControlStyle}
                  `}
                  onClick={() => setMapStyle(MAPBOX_LIGHT_MAP_STYLE)}
                >
                  <PiSun className="h-4 w-4 md:h-5 md:w-5" />
                </IconButton>
              </Tooltip>

              {advancedMapStyles && (
                <Tooltip content="Satellite mode" placement="right">
                  <IconButton
                    size="md"
                    color="white"
                    className={`
                      rounded
                      ${mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? activeControlStyle : basicControlStyle}
                    `}
                    onClick={() => setMapStyle(MAPBOX_SATELLITE_MAP_STYLE)}
                  >
                    <LiaSatelliteSolid className="h-4 w-4 md:h-5 md:w-5" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}

          <Tooltip content="Change map style" placement="right">
            <IconButton
              size="md"
              color={mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? 'white' : 'primary'}
              className={`rounded ${showOpts ? activeControlStyle : basicControlStyle}`}
              onClick={() => setShowOpts((prev) => !prev)}
            >
              <PiStackSimple className="w-4 h-4" />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>

      <Tooltip content="Use 3D data" placement="right">
        <IconButton
          size="md"
          color={mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? 'white' : 'primary'}
          className={`rounded ${apply3DMap ? activeControlStyle : basicControlStyle}`}
          onClick={() => setApply3DMap((prev) => !prev)}
        >
          <PiCubeDuotone className="w-4 h-4" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default MapStyleSelector;
