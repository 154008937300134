import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { ToastContainer } from 'react-toastify';
import { customTheme } from './theme/theme';
import { SessionContextProvider } from './contexts/SessionContext';
import CookieConsentBanner from './components/CookieConsentBanner';
import AppRouting from './AppRouting';
import OnboardingTutorial from './components/OnboardingTutorial';
import ExpiredRedirect from './components/ExpiredRedirect';
import LabelsContextProvider from './contexts/LabelsContext';
import UserPreferencesContextProvider from './contexts/UserPreferencesContext';

const App = () => (
  <>
    <ToastContainer
      enableMultiContainer
      containerId="general-toast-container"
      position="bottom-left"
      autoClose={4000}
      closeButton
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />

    <BrowserRouter>
      <ThemeProvider value={customTheme}>
        <SessionContextProvider>
          <UserPreferencesContextProvider>
            <LabelsContextProvider>
              <CookieConsentBanner />
              <OnboardingTutorial />
              <AppRouting />
              <ExpiredRedirect />
            </LabelsContextProvider>
          </UserPreferencesContextProvider>
        </SessionContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </>
);

export default App;
