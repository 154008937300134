import React from 'react';
import { Spinner, Typography, IconButton } from '@material-tailwind/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import ExperiencePreviewCard from '../ViewDashboard/ExperiencePreviewCard';
import { usePageTitle } from '../hooks/toolkit';
import { useUserExperiences } from '../hooks/useUserExperiences';
import NoUploads from '../components/NoUploads';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import Unauthorized from './Unauthorized';

const DashboardExperiences = () => {
  usePageTitle('Experience Library | Agami Dashboard');
  const { experienceList, isLoading, refetch } = useUserExperiences({
    excludeDefaultExperience: true,
  });

  const expAuth = useFeatureAuth(FEATURES.EXP_ACCESS_CODES);

  if (!expAuth) {
    return <Unauthorized />;
  }

  return (
    <>
      <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center py-4">
        <Typography variant="h1" className="text-2xl md:text-5xl">
          EXPERIENCES LIBRARY
        </Typography>
        <Link to="/upload/experience">
          <div className="flex items-center space-x-4">
            <IconButton size="sm" color="primary" className="rounded-full">
              <AiOutlinePlus className="text-[20px]" />
            </IconButton>
            <Typography className="font-bold">Create experience</Typography>
          </div>
        </Link>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {isLoading && <Spinner className="h-6 w-6" />}

        {!isLoading && (!experienceList || experienceList.length === 0) && (
          <div className="w-full col-span-3 text-center">
            <NoUploads>
              <Typography>
                Looks like you haven&apos;t created any
                <strong>experience</strong> yet.
              </Typography>
            </NoUploads>
          </div>
        )}

        {!isLoading &&
          experienceList?.map((exp) => (
            <div key={exp.id} className="flex-1 mb-4 basis-1/4">
              <ExperiencePreviewCard experience={exp} refetchExpList={refetch} />
            </div>
          ))}
      </div>
    </>
  );
};

export default DashboardExperiences;
