import React, { useContext, useState, useMemo } from 'react';

const LogsContext = React.createContext({
  logs: [],
  msg: () => {},
  warn: () => {},
  error: () => {},
  clear: () => {},
});

const useLogs = () => useContext(LogsContext);

const LogsContextProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);

  const addLog = (type, text, ...args) => {
    const log = {
      type,
      timestamp: new Date(),
      text,
      data: args,
    };
    setLogs((prev) => [...prev, log]);
  };

  const msg = (text, ...args) => {
    addLog('msg', text, ...args);
    console.info(text, ...args);
  };

  const warn = (text, ...args) => {
    addLog('warn', text, ...args);
    console.warn(text, ...args);
  };

  const error = (text, ...args) => {
    addLog('error', text, ...args);
    console.error(text, ...args);
  };

  const clear = () => {
    setLogs([]);
  };

  const contextValue = useMemo(
    () => ({
      logs,
      msg,
      warn,
      error,
      clear,
    }),
    [logs, msg, warn, error, clear]
  );

  return <LogsContext.Provider value={contextValue}>{children}</LogsContext.Provider>;
};

export { LogsContextProvider, useLogs };
