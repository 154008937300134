import React from 'react';
import { Outlet } from 'react-router-dom';
import { PiUserCircleLight, PiPasswordLight, PiIdentificationCardThin } from 'react-icons/pi';
import Sidebar from './Sidebar';
import HeaderSpace from './HeaderSpace';
import { useScreenBreakpoint } from '../hooks/toolkit';
import Tabs from './Tabs';

const ProfileLayout = () => {
  const screenBreakpoint = useScreenBreakpoint();

  const links = [
    {
      label: 'Profile',
      to: '/profile',
      Icon: PiUserCircleLight,
    },
    {
      label: 'Change password',
      to: '/profile/new-password',
      Icon: PiPasswordLight,
    },
    {
      label: 'Membership',
      to: '/profile/membership',
      Icon: PiIdentificationCardThin,
    },
  ];
  return (
    <>
      <HeaderSpace />
      <div className="h-full min-h-[800px] flex flex-col md:flex-row items-stretch">
        {screenBreakpoint === 'sm' ? <Tabs links={links} /> : <Sidebar links={links} />}
        <div className="w-full bg-white h-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ProfileLayout;
