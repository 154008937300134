import React, { useState } from 'react';
import { Card, CardBody, Chip, IconButton, Typography } from '@material-tailwind/react';
import { PiTrash } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import RemoveExperienceDialog from './RemoveExperienceDialog';

const ExperiencePreviewCard = ({ experience, refetchExpList }) => {
  const navigate = useNavigate();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const startDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenConfirmDelete(true);
  };

  return (
    <>
      <RemoveExperienceDialog
        isOpen={openConfirmDelete}
        setIsOpen={setOpenConfirmDelete}
        experienceId={experience.id}
        experienceName={experience.name}
        refetchExpList={refetchExpList}
      />

      <Card
        className="w-full h-full cursor-pointer hover:bg-base-gray-400"
        onClick={() => navigate(`/experiences/${experience.id}`)}
      >
        <CardBody>
          <div className="flex space-x-2 justify-between items-start">
            <div className="flex space-x-4 items-center">
              <div className="flex-1">
                <Typography className="font-bold">{experience.name}</Typography>
                {!experience.is_default && (
                  <span className="inline-block mb-2">
                    {experience.is_active ? (
                      <Chip value="active" size="sm" variant="ghost" color="green" />
                    ) : (
                      <Chip value="inactive" size="sm" variant="ghost" color="amber" />
                    )}
                  </span>
                )}
                <Typography variant="small">
                  Created <strong>{new Date(experience.created_at).toLocaleDateString('en-GB')}</strong>
                </Typography>
              </div>
            </div>
            {!experience.is_default && (
              <IconButton variant="text" size="sm" color="yellow" onClick={startDelete}>
                <PiTrash className="text-primary text-[20px]" />
              </IconButton>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ExperiencePreviewCard;
