import React, { useRef, useEffect, useCallback } from 'react';
import Mapbox, { GeolocateControl, NavigationControl, ScaleControl } from 'react-map-gl';
import { useXp } from '../contexts/XpContext';
import { MAPBOX_TOKEN } from '../utils/constants/mapbox.constants';
import HeaderSpace from '../layout/HeaderSpace';
import Controls from './Controls';
import ManualModeInstructions from './ManualModeInstructions';
import { useMapControls } from '../contexts/MapControlsContext';
import ManualLocationMarker from './ManualLocationMarker';
import { useDevice } from '../hooks/device';
import MapStyleSelector from '../components/MapStyleSelector';
import { useUserPreferences } from '../contexts/UserPreferencesContext';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import Buildings3DLayer from './Buildings3DLayer';
import TerrainSourceLayer from './TerrainSourceLayer';
import XpSpotsCluster from './XpSpotsCluster';

const MapboxMap = () => {
  const { manualMode, updateLocation } = useXp();
  const { mapRef, setRouteSegment } = useMapControls();
  const { platformType } = useDevice();
  const isMobile = platformType === 'mobile';

  const geoControlRef = useRef(null);

  useEffect(() => {
    // Activate as soon as the control is loaded
    if (geoControlRef.current) {
      setTimeout(() => geoControlRef.current?.trigger(), 1000);
    }
  }, [geoControlRef.current]);

  const handleMapClick = useCallback(
    (event) => {
      if (manualMode) {
        const { lng, lat } = event.lngLat;
        updateLocation({ latitude: lat, longitude: lng });
      }
    },
    [manualMode, updateLocation]
  );

  const { mapStyle, apply3DMap } = useUserPreferences();

  const advMapsAuth = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);

  return (
    <div className="h-[calc(100%_-_4.5rem)] w-full relative">
      <HeaderSpace className="headerspace" />

      <Mapbox
        ref={mapRef}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={mapStyle}
        attributionControl={false}
        style={{
          height: '100%',
          width: '100%',
        }}
        initialViewState={{
          longitude: 7.675806773171514,
          latitude: 45.06451515609597,
          zoom: 4,
        }}
        onClick={handleMapClick}
        terrain={{
          source: 'mapbox-dem',
          exaggeration: apply3DMap ? 1 : 0,
        }}
      >
        {manualMode && (
          <>
            <ManualModeInstructions />
            <ManualLocationMarker />
          </>
        )}

        {advMapsAuth && (
          <>
            <div className="absolute p-3 bottom-6 left-1">
              <MapStyleSelector />
            </div>
            <Buildings3DLayer />
            <TerrainSourceLayer />
          </>
        )}

        <ScaleControl position="bottom-right" />

        <Controls />

        <NavigationControl />

        {!manualMode && (
          <GeolocateControl
            ref={geoControlRef}
            trackUserLocation={isMobile}
            showUserHeading={isMobile}
            positionOptions={{
              enableHighAccuracy: true,
            }}
            onGeolocate={(location) => {
              setRouteSegment({
                longitude: location.coords.longitude,
                latitude: location.coords.latitude,
              });
            }}
          />
        )}

        <XpSpotsCluster zoomOnClick />
      </Mapbox>
    </div>
  );
};

export default MapboxMap;
