import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { getSpotsByExperienceId } from '../utils/helpers/user-spot.helpers';

export const useUserAgamiListByExperience = (options = {}) => {
  const { experienceId, enabled } = options;
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [agamiList, setAgamiList] = useState(null);

  const fetchSpotsDataByExpId = useCallback(async () => {
    if (enabled === false) {
      return;
    }

    const { spots, error } = await getSpotsByExperienceId({
      userId: user.id,
      experienceId,
    });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setAgamiList(spots);
    setIsLoading(false);
  }, [user, getSpotsByExperienceId, experienceId, enabled]);

  useEffect(() => {
    if (!user || !experienceId || enabled === false) {
      return;
    }
    fetchSpotsDataByExpId();
  }, [user, experienceId, enabled]);

  return { agamiList, isLoading, refetch: fetchSpotsDataByExpId };
};
