import PropTypes from 'prop-types';
import { BiVolume, BiVolumeLow, BiVolumeFull, BiVolumeMute } from 'react-icons/bi';

const VolumeButton = ({ volume, mute }) => {
  if (mute) {
    return <BiVolumeMute className="h-4 w-4 md:h-5 md:w-5" />;
  }

  if (volume > 0.5) {
    return <BiVolumeFull className="h-4 w-4 md:h-5 md:w-5" />;
  }

  if (volume > 0) {
    return <BiVolumeLow className="h-4 w-4 md:h-5 md:w-5" />;
  }

  return <BiVolume className="h-4 w-4 md:h-5 md:w-5" />;
};

VolumeButton.propTypes = {
  volume: PropTypes.number,
  mute: PropTypes.bool,
};

export default VolumeButton;
