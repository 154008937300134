import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Typography } from '@material-tailwind/react';
import UploadAgamiInterface from '../ViewUpload/UploadAgamiInterface';
import { getSpotTypes } from '../utils/helpers/supabase.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { usePageTitle } from '../hooks/toolkit';
import { useBucketInfo } from '../hooks/supabase';
import GoBack from '../components/GoBack';
import { useAgami } from '../hooks/useAgami';

const DashboardAgamiDetail = () => {
  usePageTitle('Edit Agami Details | Agami Dashboard');

  const { id } = useParams();
  const { loading, user } = useSessionContext();

  const { loading: bucketLoading, uploadSizeLimit, allowedFileTypes } = useBucketInfo();

  const [availableSpotTypes, setAvailableSpotTypes] = useState();

  const { agami, isLoading: agamiLoading, refetch } = useAgami({ agamiId: id });

  const getAvailableSpotTypes = async () => {
    const { data } = await getSpotTypes();
    const types = data?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.label,
      }),
      {}
    );
    setAvailableSpotTypes(types);
  };

  useEffect(() => {
    if (loading || !user?.id) {
      return;
    }
    getAvailableSpotTypes();
  }, [loading, user]);

  const isLoading = useMemo(
    () => loading || bucketLoading || agamiLoading,
    [loading, bucketLoading, agamiLoading]
  );

  return (
    <div className="w-full flex flex-col py-4">
      <div className="flex items-center justify-center gap-2 self-start">
        <GoBack />
        <Typography variant="lead" className="text-primary">
          {agami?.name ?? ''}
        </Typography>
      </div>

      {isLoading && (
        <div className="w-full my-10">
          <Spinner className="h-12 w-12 mx-auto" />
        </div>
      )}

      {!isLoading && !agami && (
        <div className="w-full my-10">
          <Typography>Agami not found</Typography>
        </div>
      )}

      {!isLoading && availableSpotTypes && agami && (
        <UploadAgamiInterface
          uploadSizeLimit={uploadSizeLimit}
          allowedFileTypes={allowedFileTypes}
          availableSpotTypes={availableSpotTypes}
          agami={agami}
          refetchAgami={refetch}
        />
      )}
    </div>
  );
};

export default DashboardAgamiDetail;
