import React from 'react';
import { Typography } from '@material-tailwind/react';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';

const PrivacyPolicy = () => {
  usePageTitle('Privacy Policy | Agami');
  return (
    <Container direction="col" bgColorClass="bg-white">
      <div id="privacy-policy-container" className="w-full px-2 md:px-0">
        <Typography variant="h1">PRIVACY POLICY</Typography>
        <p>
          If you have any questions regarding our privacy policy, or if you want to request the deletion of
          your data, please reach out at{' '}
          <a href="mailto:dpo@mezzoforte.design" className="underline">
            dpo@mezzoforte.design
          </a>
          .
        </p>

        <p>
          agami is the first pro audio platform for immersive experiences with bone conduction technology,
          based on 3 years of creative R&D by Mezzo Forte team. Sounds are associated with a place of
          interest, by designing the ecological blend between the natural sound environment and virtual sound
          sources.
        </p>

        <h2>Definitions</h2>
        <p>
          <strong>User:</strong> The entity (moral or physical) who uses Mezzo Forte technologies.
        </p>
        <p>
          <strong>Executable Software:</strong> Mezzo Forte software that is installed locally on User&apos;s
          devices.
        </p>
        <p>
          <strong>Platform:</strong> The cloud platform that allows the User to manage sound contents.
        </p>

        <h2>Data Collection Information</h2>

        <h3>From the Executable Software</h3>
        <p>
          We process telemetry information that is collected by the Executable Software and sent to our
          servers. This telemetry information consists of:
        </p>
        <ul>
          <li>
            Software information
            <ul>
              <li>Version</li>
              <li>Memory usage</li>
              <li>CPU usage</li>
            </ul>
          </li>

          <li>
            Audio process information
            <ul>
              <li>Process health status.</li>
              <li>Process activity, consumption.</li>
              <li>Algorithm parameters.</li>
              <li>Audio Measures for algorithm parameterization.</li>
            </ul>
          </li>

          <li>
            Audio processing
            <ul>
              <li>Available devices.</li>
              <li>Devices used.</li>
              <li>Status information.</li>
            </ul>
          </li>

          <li>Networking: consumption and potential errors</li>
          <li>GUI: Current configuration in order to restore it later on.</li>

          <li>
            Operating System
            <ul>
              <li>Name (Windows, Linux, MacOS)</li>
              <li>Version, architecture</li>
              <li>Local IP address</li>
              <li>Web IP address</li>
              <li>Time zone</li>
            </ul>
          </li>

          <li>
            Computer brand
            <ul>
              <li>CPU model</li>
              <li>Session: active, locked, administrator mode</li>
            </ul>
          </li>

          <li>User identifier (pseudo-anonymised or clear depending on User&apos;s Consent).</li>
          <li>Machine identifier (pseudo-anonymised)</li>
        </ul>

        <h3>From the Platform</h3>
        <p>To operate the Platform, Mezzo Forte stores the following personal information in its database:</p>
        <ul>
          <li>Account email</li>
          <li>Account first and last name</li>
          <li>Connection logs</li>
        </ul>

        <p>
          No cookie is used when the Client Space is accessed through a web browser. Some data is stored in
          the browser through the LocalStorage mechanism, in particular:
        </p>
        <ul>
          <li>A connection token, which is required for authentication</li>
          <li>Cached data to improve the user experience and reduce the load on the device</li>
        </ul>

        <h2>Consent</h2>
        <p>
          Users can grant or remove consent for some or all types of data collection. Such consent is
          processed and stored in our database so that all our applications can behave accordingly and comply
          with the User&apos;s choice.
        </p>

        <h2>Data Request and Removal</h2>
        <p>
          Users can contact us by email to request access to their data, or deletion of this data. When we
          receive such requests, we first ensure the request comes from the adequate party, by verifying
          several identifiers, then our infrastructure team proceeds to the extraction or deletion of the data
          through scripts that are written and tested for this purpose.
        </p>

        <h2>Purpose for Collecting Information</h2>
        <p>We collect information about the User and the User&apos;s machine in order to:</p>
        <ul>
          <li>
            Verify the good behavior of our software. The audio processing being a real-time critical
            operation, the health of this processing is of prime importance.
          </li>
          <li>Improve our software to make it more efficient, easier to handle.</li>
          <li>Fine-tune our voice transformations based on a user&apos;s voice.</li>
          <li>Improve our technology through an R&D program that can rely on real-world data.</li>
        </ul>

        <h2>Retention</h2>
        <p>
          We only collect and keep the data that is necessary for our services to work. This is enforced
          through several automatic tasks and constraints, both at the application and database level:
        </p>
        <ul>
          <li>Error and crash reports are automatically purged after 90 days.</li>
          <li>User and/or client deletion automatically cascade to related rows in the database.</li>
          <li>
            Expired data such as telemetry entries, audio recordings, and support requests is purged from our
            database on a daily basis, through automated tasks.
          </li>
          <li>
            Within the Executable Software, the audio stream is lost after 50 milliseconds. The audio stream
            is never transmitted to Mezzo Forte&apos;s server.
          </li>
        </ul>

        <h2>Communication</h2>
        <p>
          <strong>Responsible people at Mezzo Forte:</strong>
        </p>

        <ul>
          <li>Data Protection Officer (DPO) · dpo@mezzoforte.design</li>
          <li>Responsible of GDPR compliance across the organization · dpo@mezzoforte.design</li>
          <li>The data controller is Mezzo Forte SAS, under the responsibility of the President.</li>
        </ul>

        <h2>Third parties</h2>
        <p>Collected data may be processed by the following third-parties, in compliance with GDPR:</p>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Processed Data</th>
              <th>Country</th>
              <th>Applicable Regulations and Guarantees</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>OVH SAS</td>
              <td>Hosting Provider</td>
              <td>All data collected by the Executable and the Client Space</td>
              <td>France</td>
              <td>GDPR</td>
            </tr>
            <tr>
              <td>GitLab Inc</td>
              <td>Support Provider</td>
              <td>Support requests and data removal requests</td>
              <td>USA</td>
              <td>GDPR</td>
            </tr>
          </tbody>
        </table>

        <p>
          Except from the processors listed above, Mezzo Forte does not transmit its data to any other
          company, nor freely or for any kind of reward.
        </p>

        <h2>Data breach</h2>
        <p>In case of a breach, the following Supervisory Authority will be notified within 48 hours:</p>
        <p>
          <strong>Commission Nationale de l&apos;Informatique et des Libertés – CNIL</strong>
        </p>
        <p>8 rue Vivienne, CS 30223</p>
        <p>F-75002 Paris, Cedex 02</p>
        <p>Tel. +33 1 53 73 22 22</p>
        <p>Fax +33 1 53 73 22 00</p>
        <p>
          <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
        </p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
