import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import { PiPathFill } from 'react-icons/pi';
import { useFeatureAuth } from '../hooks/authorization';
import { usePageTitle } from '../hooks/toolkit';
import { FEATURES } from '../utils/constants/features.constants';
import Container from '../components/Container';
import { useSessionContext } from '../contexts/SessionContext';
import spotIcon from '../assets/svg/spot-marker.svg';

const Upload = () => {
  usePageTitle('Upload | Agami');

  const { loading } = useSessionContext();

  const navigate = useNavigate();

  const uploadSpotAuth = useFeatureAuth(FEATURES.UPLOAD_SPOTS_UI);

  const experienceEditorAuth = useFeatureAuth(FEATURES.EXP_EDITOR_UI);

  // if use can't create experiences, redirect to single spot upload
  useEffect(() => {
    if (loading) return;
    if (!experienceEditorAuth) navigate('/upload/spot', { replace: true });
  }, [loading, experienceEditorAuth]);

  return (
    <Container bgColorClass="bg-white" direction="col">
      <Typography variant="h1" className="text-primary">
        UPLOAD NEW CONTENT
      </Typography>

      <div className="w-full mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
        {uploadSpotAuth && (
          <Card className="cursor-pointer hover:bg-base-gray-400" onClick={() => navigate('/upload/spot')}>
            <CardBody>
              <img src={spotIcon} alt="Agami Spot" title="Agami Spot" className="w-12 h-12 mb-3" />
              <Typography variant="h5">UPLOAD AN AGAMI</Typography>
              <Typography>
                Upload a single agami, with a single audio content linked to a geographical position.
              </Typography>
            </CardBody>
          </Card>
        )}

        {experienceEditorAuth && (
          <Card
            className="cursor-pointer hover:bg-base-gray-400"
            onClick={() => navigate('/upload/experience')}
          >
            <CardBody>
              <PiPathFill className="text-black w-12 h-12 mb-4" />
              <Typography variant="h5">CREATE AN EXPERIENCE</Typography>
              <Typography>
                Upload and organize multiple agami, defining parameters such as content replay and
                transitions.
              </Typography>
            </CardBody>
          </Card>
        )}
      </div>
    </Container>
  );
};

export default Upload;
