import * as L from 'leaflet';
import icon from '../../assets/svg/spot-marker.svg';
import { theme } from '../../tailwind.config';

const { colors } = theme.extend;
const PRIMARY = colors.primary;
const ACCENT = colors.accent;
const ACCENT_RED = colors['accent-red'];
const BASE_GRAY = colors['base-gray'];

export const DEFAULT_ICON = L.icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: [18, 30],
  iconAnchor: [9, 18],
  popupAnchor: [2, -40],
});
L.Marker.prototype.options.icon = DEFAULT_ICON;

export const DEFAULT_ZOOM = 5;

export const DEFAULT_POS = L.latLng([45.071104, 7.670225]);

export const ROUTE_STYLE_OPTS = { color: PRIMARY.DEFAULT, opacity: 0.8 };

export const POSITION_MARKER_COLOR = {
  fill: ACCENT.DEFAULT,
  stroke: ACCENT_RED.DEFAULT,
  icon: ACCENT_RED.DEFAULT,
};

export const POSITION_MARKER_STYLE_OPTS = {
  color: PRIMARY.DEFAULT,
  fillOpacity: 1,
  opacity: 1,
  weight: 4,
};

export const POSITION_OUTER_MARKER_STYLE_OPTS = {
  color: ACCENT_RED.DEFAULT,
  fillOpacity: 0.3,
  opacity: 0,
  weight: 4,
};

// *** Inactive spot ****
// inner radius
export const RADIUS_CIRCLE_STYLE_OPTS = {
  color: PRIMARY[700],
  opacity: 1,
  weight: 3,
  fillColor: PRIMARY[500],
  fillOpacity: 0.5,
};

export const LIGHT_RADIUS_CIRCLE_STYLE_OPTS = {
  color: PRIMARY[500],
  opacity: 1,
  weight: 2,
  fillColor: BASE_GRAY[500],
  fillOpacity: 0.7,
};

// outer deadband
export const DEADBAND_CIRCLE_STYLE_OPTS = {
  color: PRIMARY[700],
  opacity: 1,
  weight: 0,
  fillColor: PRIMARY[500],
  fillOpacity: 0.2,
};

export const LIGHT_DEADBAND_CIRCLE_STYLE_OPTS = {
  color: PRIMARY[700],
  opacity: 1,
  weight: 0,
  fillColor: BASE_GRAY[500],
  fillOpacity: 0.5,
};

// cluster
export const CLUSTER_CIRCLE_STYLE_OPTS = {
  color: PRIMARY[700],
  opacity: 1,
  weight: 0,
  fillColor: PRIMARY[500],
  fillOpacity: 0.5,
};

export const LIGHT_CLUSTER_CIRCLE_STYLE_OPTS = {
  color: PRIMARY[700],
  opacity: 1,
  weight: 0,
  fillColor: BASE_GRAY[500],
  fillOpacity: 0.8,
};

// pattern cluster
export const PATTERN_CIRCLE_STYLE_OPTS = {
  color: ACCENT[700],
  opacity: 1,
  weight: 0,
  fillColor: ACCENT[500],
  fillOpacity: 0.5,
};

export const LIGHT_PATTERN_CIRCLE_STYLE_OPTS = {
  color: ACCENT[700],
  opacity: 1,
  weight: 0,
  fillColor: ACCENT[500],
  fillOpacity: 0.8,
};

// *** Active spot ****
// inner radius
export const ACTIVE_RADIUS_CIRCLE_STYLE_OPTS = {
  color: ACCENT[700],
  opacity: 1,
  weight: 3,
  fillColor: ACCENT[500],
  fillOpacity: 0.8,
};

// outer deadband
export const ACTIVE_DEADBAND_CIRCLE_STYLE_OPTS = {
  color: ACCENT[700],
  opacity: 0.1,
  weight: 0,
  fillColor: ACCENT[500],
  fillOpacity: 0.3,
};

// supercluster options
export const SUPERCLUSTER_OPTIONS = {
  radius: 100,
  minZoom: 0,
  maxZoom: 13,
  nodeSize: 64,
  log: false,
  generateId: true,
};

export const FIT_BOUNDS_PADDING = 100;
export const FIT_BOUNDS_ANIMATION_DURATION = 500;
