import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Source, Layer, Marker } from 'react-map-gl';
import { metersToPixelsAtMaxZoom } from '../utils/helpers/map.helpers';
import { useAgamiMarkerStyle } from '../hooks/useAgamiMarkerStyle';
import AgamiMarkerIcon from '../components/AgamiMarkerIcon';

const XpSpotDraggableMarker = ({ position, onDragStart, onDragEnd }) => {
  const [isDragging, setIsDragging] = useState(false);

  const { radiusStyle, deadbandStyle } = useAgamiMarkerStyle();

  const center = [position.longitude, position.latitude];

  const feature = {
    type: 'Feature',
    id: `${position.id}_feature`,
    geometry: {
      type: 'Point',
      coordinates: center,
    },
  };

  const radiusCircleSize = metersToPixelsAtMaxZoom(position.radius, position.latitude);

  const deadbandCircleSize = metersToPixelsAtMaxZoom(position.radius + position.deadband, position.latitude);

  const radiusLayerStyle = {
    id: `${position.id}_radius_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, radiusCircleSize],
        ],
        base: 2,
      },
      'circle-stroke-color': radiusStyle.color,
      'circle-stroke-opacity': radiusStyle.opacity,
      'circle-stroke-width': radiusStyle.weight,
      'circle-stroke-color-transition': {
        duration: 5000,
        delay: 0,
      },
      'circle-color': radiusStyle.fillColor,
      'circle-opacity': radiusStyle.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-pitch-alignment': 'map',
    },
  };

  const deadbandLayerStyle = {
    id: `${position.id}_deadband_circle`,
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [20, deadbandCircleSize],
        ],
        base: 2,
      },
      'circle-radius-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-stroke-color': deadbandStyle.color,
      'circle-stroke-opacity': deadbandStyle.opacity,
      'circle-stroke-width': deadbandStyle.weight,
      'circle-stroke-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-color': deadbandStyle.fillColor,
      'circle-opacity': deadbandStyle.fillOpacity,
      'circle-color-transition': {
        duration: 1000,
        delay: 0,
      },
      'circle-pitch-alignment': 'map',
    },
  };

  return (
    <>
      {!isDragging && (
        <Source id={`${position.id}_source`} type="geojson" data={feature}>
          <Layer {...radiusLayerStyle} />
          <Layer {...deadbandLayerStyle} />
        </Source>
      )}

      <Marker
        longitude={position.longitude}
        latitude={position.latitude}
        anchor="center"
        draggable
        onDragStart={(evt) => {
          setIsDragging(true);
          if (typeof onDragStart === 'function') {
            onDragStart(evt);
          }
        }}
        onDragEnd={(evt) => {
          setIsDragging(false);
          if (typeof onDragEnd === 'function') {
            onDragEnd(evt);
          }
        }}
      >
        <AgamiMarkerIcon />
      </Marker>
    </>
  );
};

XpSpotDraggableMarker.propTypes = {
  position: PropTypes.shape({
    longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deadband: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

export default XpSpotDraggableMarker;
