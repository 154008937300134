import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { getUserExpAccessCodes } from '../utils/helpers/supabase.helpers';

export const useExperiencesAccessCodes = (options = {}) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [accessCodesList, setAccessCodesList] = useState(null);
  const { enabled } = options;

  const fetchAccessCodesList = useCallback(async () => {
    if (enabled === false) {
      return;
    }

    const { data, error } = await getUserExpAccessCodes({ userId: user.id });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setAccessCodesList(data);
    setIsLoading(false);
  }, [user, enabled]);

  useEffect(() => {
    if (!user || enabled === false) {
      return;
    }

    fetchAccessCodesList();
  }, [user, enabled]);

  return { accessCodesList, isLoading, refetch: fetchAccessCodesList };
};
