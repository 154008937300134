import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useScreenBreakpoint } from '../hooks/toolkit';
import { useUserAgamiList } from '../hooks/useUserAgamiList';
import AddAgamiToExperienceDialog from './AddAgamiToExperienceDialog';
import AddAgamiToExperienceSheet from './AddAgamiToExperienceSheet';

const AddAgamiToExperience = ({ isOpen, setIsOpen, currentExpId, refetchAgamiList }) => {
  const { isLoading, agamiList, refetch } = useUserAgamiList({
    getExpRelations: true,
    getAudioUrls: false,
    enabled: isOpen,
  });

  const refetchAfterChange = useCallback(async () => {
    await refetchAgamiList();
    await refetch();
  }, [refetchAgamiList, refetch]);

  const screenBreakpoint = useScreenBreakpoint();

  return (
    <>
      {/* eslint-disable-line */}
      {screenBreakpoint === 'sm' ? (
        <AddAgamiToExperienceSheet
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentExpId={currentExpId}
          isLoading={isLoading}
          agamiList={agamiList}
          refetchAfterChange={refetchAfterChange}
        />
      ) : (
        <AddAgamiToExperienceDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentExpId={currentExpId}
          isLoading={isLoading}
          agamiList={agamiList}
          refetchAfterChange={refetchAfterChange}
        />
      )}
    </>
  );
};

AddAgamiToExperience.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentExpId: PropTypes.string,
  refetchAgamiList: PropTypes.func,
};

export default AddAgamiToExperience;
