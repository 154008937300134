export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiZnJhbmNlc2NvY3JldHRpLW1mIiwiYSI6ImNsa254NmdmNjBjbnUzZG9jd2UycmR3MHgifQ.X_R67oCFSGO_Y5YgEcDHXw';

export const MAPBOX_USER = 'francescocretti-mf';

export const MAPBOX_MONOCHROME_ID = 'clknwpdkc005801o8cqujagyx';

export const MAPBOX_STREETS_ID = 'clknwqtph00l601pcdlulhzia';

export const MAPBOX_MONOCHROME_TILESET_URL = `https://api.mapbox.com/styles/v1/${MAPBOX_USER}/${MAPBOX_MONOCHROME_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`;

export const MAPBOX_STREETS_TILESET_URL = `https://api.mapbox.com/styles/v1/${MAPBOX_USER}/${MAPBOX_STREETS_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`;

export const MAPBOX_LIGHT_MAP_STYLE = 'mapbox://styles/mapbox/light-v11';

export const MAPBOX_SATELLITE_MAP_STYLE = 'mapbox://styles/mapbox/satellite-streets-v12';

export const DEFAULT_MAP_ZOOM = 17.5;

export const MAPBOX_TERRAIN_SOURCE = 'mapbox://mapbox.mapbox-terrain-dem-v1';
