import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Spinner, Typography } from '@material-tailwind/react';
import UploadAgamiInterface from './UploadAgamiInterface';
import Container from '../components/Container';
import { getSpotTypes } from '../utils/helpers/supabase.helpers';
import { getUserGeoXpSpotCount } from '../utils/helpers/user-spot.helpers';
import { useSessionContext } from '../contexts/SessionContext';
import { usePageTitle } from '../hooks/toolkit';
import GoBack from '../components/GoBack';
import { useBucketInfo } from '../hooks/supabase';

const UploadSingleSpot = () => {
  usePageTitle('Upload Spot | Agami');

  const { loading, user } = useSessionContext();

  const { loading: bucketLoading, uploadSizeLimit, allowedFileTypes } = useBucketInfo();

  const spotLimit = user?.uploadLimit;
  const [uploadLimitReached, setUploadLimitReached] = useState(false);
  const [countLoading, setCountLoading] = useState(true);
  const [availableSpotTypes, setAvailableSpotTypes] = useState();

  const getAvailableSpotTypes = async () => {
    const { data } = await getSpotTypes();
    const types = data?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.label,
      }),
      {}
    );
    setAvailableSpotTypes(types);
  };

  const getSpotCount = async () => {
    const { count } = await getUserGeoXpSpotCount();
    setUploadLimitReached(count >= spotLimit);
    setCountLoading(false);
  };

  useEffect(() => {
    if (loading || !user?.id) {
      return;
    }

    getAvailableSpotTypes();
    getSpotCount();
  }, [loading, user]);

  const isLoading = useMemo(
    () => loading || countLoading || bucketLoading,
    [loading, countLoading, bucketLoading]
  );

  return (
    <Container bgColorClass="bg-white" direction="col">
      <div className="mb-2 self-start">
        <GoBack />
      </div>

      <Typography variant="lead" className="text-primary">
        UPLOAD AN AGAMI
      </Typography>

      {isLoading && (
        <div className="w-full my-10">
          <Spinner className="h-12 w-12 mx-auto" />
        </div>
      )}

      {!isLoading && uploadLimitReached && (
        <div className="my-10 text-center">
          <Alert variant="ghost" color="amber">
            <Typography variant="paragraph">
              You reached your limit of uploaded agami! You can check them in your{' '}
              <span className="font-bold underline">
                <Link to="/">dashboard</Link>
              </span>
              .
            </Typography>
          </Alert>
        </div>
      )}

      {!isLoading && availableSpotTypes && !uploadLimitReached && (
        <UploadAgamiInterface
          uploadSizeLimit={uploadSizeLimit}
          allowedFileTypes={allowedFileTypes}
          availableSpotTypes={availableSpotTypes}
        />
      )}
    </Container>
  );
};

export default UploadSingleSpot;
