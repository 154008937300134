import { Typography } from '@material-tailwind/react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const TableHeaderWithSorting = ({ title, sortKey, sort, setSort }) => {
  const isDesc = sort?.startsWith('-');
  const isSorting = isDesc ? sort?.substring(1) === sortKey : sort === sortKey;

  const toggleSorting = () => {
    if (!setSort || !sortKey) return;
    setSort(sort === sortKey ? `-${sortKey}` : sortKey);
  };

  return (
    <th
      className={`border-b border-blue-gray-100 bg-blue-gray-500/30 p-4 ${setSort && sortKey && 'cursor-pointer'}`}
      {...(setSort && sortKey
        ? {
            onClick: toggleSorting,
          }
        : {})}
    >
      <Typography variant="small" color="primary" className="font-bold leading-none flex gap-2">
        {title}
        {isSorting && isDesc && <IoIosArrowUp />}
        {isSorting && !isDesc && <IoIosArrowDown />}
      </Typography>
    </th>
  );
};

export default TableHeaderWithSorting;
