import React, { useState } from 'react';
import { IconButton, Spinner, Typography } from '@material-tailwind/react';
import { AiOutlinePlus } from 'react-icons/ai';
import ResponsiveDialog from '../components/ResponsiveDialog';
import { useUserExperiences } from '../hooks/useUserExperiences';
import { usePageTitle } from '../hooks/toolkit';
import EditPatternForm from '../components/EditPatternForm';
import Center from '../components/Center';
import { useExperiencesPatterns } from '../hooks/useExperiencesPatterns';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import Unauthorized from './Unauthorized';
import NoUploads from '../components/NoUploads';
import PatternPreviewCard from '../ViewDashboard/PatternPreviewCard';

const DashboardPatterns = () => {
  usePageTitle('Patterns | Agami Dashboard');
  const { experienceList, isLoading: expLoading } = useUserExperiences({
    excludeDefaultExperience: true,
  });

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { patternsList, refetch, isLoading: patternsLoading } = useExperiencesPatterns();
  const isLoading = expLoading || patternsLoading;

  const patternsAuth = useFeatureAuth(FEATURES.CREATE_PATTERNS);

  if (!patternsAuth) {
    return <Unauthorized />;
  }

  return (
    <>
      <ResponsiveDialog isOpen={createDialogOpen} setIsOpen={setCreateDialogOpen} title="Create pattern">
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <EditPatternForm
            experienceList={experienceList}
            refetch={refetch}
            closeDialog={() => setCreateDialogOpen(false)}
          />
        )}
      </ResponsiveDialog>

      <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-start py-4">
        <div className="flex flex-col lg:max-w-[60%]">
          <Typography variant="h1" className="text-2xl md:text-5xl">
            PATTERNS
          </Typography>
          <Typography>
            Patterns are groups of <em>agami</em> within an Experience, which may be part of the same sound
            work or share a semantic connection. You can create and name a pattern in an Experience, then add{' '}
            <em>agami</em> to it. Patterns will appear on the map with a different marker to show their
            connection.
          </Typography>
        </div>

        <div className="flex items-center space-x-4">
          <IconButton
            size="sm"
            color="primary"
            className="rounded-full"
            onClick={() => setCreateDialogOpen(true)}
          >
            <AiOutlinePlus className="text-[20px]" />
          </IconButton>
          <Typography className="font-bold">Create pattern</Typography>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {isLoading && <Spinner className="h-6 w-6" />}

        {!isLoading && (!patternsList || patternsList.length === 0) && (
          <div className="w-full col-span-3 text-center">
            <NoUploads>
              <Typography>
                Looks like you haven&apos;t created any <strong>PATTERNS</strong> yet.
              </Typography>
            </NoUploads>
          </div>
        )}

        {!isLoading &&
          patternsList?.map((pattern) => (
            <div key={pattern.id} className="flex-1 mb-4 basis-1/4">
              <PatternPreviewCard pattern={pattern} experienceList={experienceList} refetch={refetch} />
            </div>
          ))}
      </div>
    </>
  );
};

export default DashboardPatterns;
