import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogHeader, DialogBody, Spinner, Typography, IconButton } from '@material-tailwind/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Center from '../components/Center';
import AddAgamiToExperienceTable from './AddAgamiToExperienceTable';
import { AgamiSpotDetailPropType } from '../PropTypes';

const AddAgamiToExperienceDialog = ({
  isOpen,
  setIsOpen,
  currentExpId,
  isLoading,
  agamiList,
  refetchAfterChange,
}) => {
  const navigate = useNavigate();
  return (
    <Dialog open={isOpen} handler={setIsOpen}>
      <DialogHeader className="justify-between">
        Add your agami to this experience
        <div className="flex items-center space-x-2">
          <Typography variant="small" className="font-bold">
            Upload new agami
          </Typography>
          <IconButton
            size="sm"
            color="primary"
            className="rounded-full w-6 h-6"
            onClick={() => navigate(`/upload/spot?addToExperience=${currentExpId}`)}
          >
            <AiOutlinePlus className="w-4 h-4" />
          </IconButton>
        </div>
      </DialogHeader>

      <DialogBody className="body">
        {isLoading && (
          <Center>
            <Spinner className="h-6 w-6 my-4" />
          </Center>
        )}

        {!isLoading && agamiList.length === 0 && (
          <span>
            You didn&apos;t create any <em>agami</em> yet.
          </span>
        )}

        {!isLoading && agamiList.length > 0 && (
          <AddAgamiToExperienceTable
            currentExpId={currentExpId}
            refetchAfterChange={refetchAfterChange}
            agamiList={agamiList}
          />
        )}
      </DialogBody>
    </Dialog>
  );
};

AddAgamiToExperienceDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentExpId: PropTypes.string,
  isLoading: PropTypes.bool,
  agamiList: PropTypes.arrayOf(AgamiSpotDetailPropType),
  refetchAfterChange: PropTypes.func,
};

export default AddAgamiToExperienceDialog;
