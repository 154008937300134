import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Spinner } from '@material-tailwind/react';
import { useGeneralToast } from '../hooks/toast';
import { useWindowSize } from '../hooks/toolkit';
import { removeAgamiFromExperience } from '../utils/helpers/supabase.helpers';

const RemoveAgamiFromExperienceDialog = ({
  isOpen,
  setIsOpen,
  experienceId,
  agamiToRemove,
  experienceDetails,
  refetchAgamiList,
}) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [deleting, setDeleting] = useState(false);
  const { width } = useWindowSize();
  const dialogSize = width <= 640 ? 'lg' : 'xs';

  const deleteFromExperience = useCallback(async () => {
    if (!experienceId || !agamiToRemove) return;

    setDeleting(true);

    const { error } = await removeAgamiFromExperience({
      experienceId,
      spotId: agamiToRemove.id,
    });

    if (error) {
      console.error(error);
      toastError('Something went wrong');
      setDeleting(false);
      return;
    }

    await refetchAgamiList();
    toastSuccess('Agami removed from this experience');
    setDeleting(false);
    setIsOpen(false);
  }, [experienceId, agamiToRemove?.id, removeAgamiFromExperience, refetchAgamiList]);

  return (
    <Dialog size={dialogSize} open={isOpen} handler={setIsOpen}>
      <DialogHeader>Remove agami from experience</DialogHeader>
      <DialogBody>
        <span className="block my-2">
          Are you sure you want to remove your agami <strong>{agamiToRemove?.name}</strong> from the
          experience <strong>{experienceDetails?.name}</strong>?
        </span>
        <span />
      </DialogBody>
      <DialogFooter className="justify-between gap-2">
        <Button size="sm" color="primary" variant="text" onClick={() => setIsOpen(false)} disabled={deleting}>
          CANCEL
        </Button>
        <Button
          size="sm"
          color="red"
          className="bg-accent-red"
          onClick={deleteFromExperience}
          disabled={deleting}
        >
          {deleting ? <Spinner className="h-4 w-4" /> : 'CONFIRM REMOVE'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default RemoveAgamiFromExperienceDialog;
