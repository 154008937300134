import { useMemo } from 'react';

import { Chip } from '@material-tailwind/react';

import { getRoleColor, getRoleLabel } from '../utils/helpers/roles.helpers';

const RoleChip = ({ role }) => {
  const chipColor = useMemo(() => getRoleColor(role), [role]);

  const roleLabel = useMemo(() => getRoleLabel(role), [role]);

  return <Chip color={chipColor} size="sm" value={roleLabel} />;
};

export default RoleChip;
