import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography, Button } from '@material-tailwind/react';
import UserDataPanel from '../components/UserDataPanel';
import RedeemCodePanel from '../components/RedeemCodePanel';
import { usePageTitle } from '../hooks/toolkit';

const Membership = () => {
  usePageTitle('Membership | Agami');

  const [showRedeemUi, setShowRedeemUi] = useState(false);
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('redeemCode');

  useEffect(() => {
    if (codeParam) {
      setShowRedeemUi(true);
    }
  }, []);

  return (
    <div className="w-11/12 mx-auto mt-12">
      <Typography variant="h1" className="text-primary">
        YOUR MEMBERSHIP
      </Typography>

      <div className="w-full md:w-1/2 flex flex-col gap-4">
        <UserDataPanel />
        {showRedeemUi ? (
          <RedeemCodePanel />
        ) : (
          <div className="mt-6">
            <Button size="sm" onClick={() => setShowRedeemUi(true)}>
              DO YOU HAVE AN UPGRADE CODE?
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Membership;
