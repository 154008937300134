import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

const LINK_INVALID = 'Email+link+is+invalid+or+has+expired';

const ExpiredRedirect = () => {
  const { hash } = useLocation();
  const isExpiredLink = hash?.includes(LINK_INVALID);
  const [searchParams] = useSearchParams();
  const redeemCode = searchParams.get('redeemCode');

  const redirectTargetUrl = redeemCode
    ? `/expired-invitation?redeemCode=${redeemCode}`
    : '/expired-invitation';

  return isExpiredLink ? <Navigate to={redirectTargetUrl} replace /> : null;
};

export default ExpiredRedirect;
