import React, { useState, useEffect } from 'react';
import { Spinner, Typography, Chip } from '@material-tailwind/react';
import { getCodesList } from '../utils/helpers/supabase.helpers';
import { formatDateString } from '../utils/helpers/dates.helpers';
import { REDEEM_CODE_VALIDITY } from '../utils/constants/supabase.constants';
import RoleChip from '../components/RoleChip';
import { useGeneralToast } from '../hooks/toast';
import Container from '../components/Container';
import GoBack from '../components/GoBack';
import CheckCode from './CheckCode';

const TABLE_HEAD = ['Email', 'Code', 'Plan', 'Issued at', 'Redeemed', 'Redeemed At'];

const CodesList = () => {
  const { error: toastError } = useGeneralToast();

  const [loading, setLoading] = useState(true);

  const [codes, setCodes] = useState([]);

  const handleCodes = async () => {
    const { data, error } = await getCodesList();

    if (error) {
      toastError(error);
    }

    setCodes(data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    handleCodes();
  }, []);

  return (
    <Container bgColorClass="bg-white" direction="col">
      <div className="mb-2 self-start">
        <GoBack />
      </div>

      <Typography variant="lead">Issued upgrade codes</Typography>

      <div className="w-full p-4" />

      {loading && <Spinner className="h-12 w-12 m-auto" />}

      {!loading && (
        <>
          <CheckCode />

          <div className="w-full h-[1px] border my-4" />

          <div className="w-full overflow-x-auto rounded shadow-sm">
            <table className="w-full table-auto text-left rounded">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {codes.map(({ user_email, code, target_role, issued_at, redeemed, redeemed_at }) => {
                  const issueDate = new Date(issued_at).getTime();
                  const now = Date.now();
                  const diff = (now - issueDate) / (1000 * 3600 * 24); // days
                  const expired = diff > REDEEM_CODE_VALIDITY;

                  return (
                    <tr key={code} className="even:bg-blue-gray-50/50">
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {user_email}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-bold">
                          {code}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal text-center">
                          <RoleChip role={target_role} />
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {formatDateString(issued_at)}{' '}
                          {expired && <Chip className="text-center" size="sm" value="EXPIRED" color="red" />}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal text-center">
                          {redeemed ? (
                            <Chip size="sm" value="YES" color="red" />
                          ) : (
                            <Chip size="sm" value="NO" color="green" />
                          )}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {redeemed_at ? formatDateString(redeemed_at) : '-'}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Container>
  );
};

export default CodesList;
