import { useEffect, useState, useCallback } from 'react';
import { getAgami } from '../utils/helpers/user-spot.helpers';
import { useSessionContext } from '../contexts/SessionContext';

export const useAgami = ({ agamiId }) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [agami, setAgami] = useState(null);

  const fetchAgami = useCallback(async () => {
    if (!agamiId) return;
    const { agamiData, error } = await getAgami({ agamiId });

    if (error || !agamiData) {
      setIsLoading(false);
      return;
    }

    setAgami(agamiData);
    setIsLoading(false);
  }, [agamiId]);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchAgami();
  }, [user]);

  return { agami, isLoading, refetch: fetchAgami };
};
