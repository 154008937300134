import { useState } from 'react';
import { Button, IconButton, Input, Spinner, Typography } from '@material-tailwind/react';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';
import { updateUserPassword } from '../utils/helpers/supabase.helpers';
import { usePageTitle } from '../hooks/toolkit';
import { useGeneralToast } from '../hooks/toast';

const UserSetNewPassword = () => {
  usePageTitle('Set new password | Agami');

  const { success: toastSuccess, error: toastError } = useGeneralToast();

  const [password, setPassword] = useState();
  const [passwordConfirm, setpasswordConfirm] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const updateUserData = async (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    const { error: updateUserPasswordError } = await updateUserPassword({
      password,
      passwordConfirm,
    });

    if (updateUserPasswordError) {
      console.error(updateUserPasswordError);
      toastError(updateUserPasswordError.message ?? updateUserPasswordError);
      setLoading(false);
      return;
    }

    setLoading(false);
    toastSuccess('The new password has been set successfully!');
  };

  return (
    <div className="w-11/12 mx-auto mt-12">
      <Typography variant="h1" className="text-primary">
        SET NEW PASSWORD
      </Typography>

      <Typography variant="h5" className="text-primary">
        Set a new password for your account
      </Typography>

      <form className="w-full md:w-1/2 flex flex-col gap-4 mt-8" onSubmit={updateUserData}>
        <div>
          <div className="relative flex w-full">
            <Input
              className="pr-20"
              value={password || ''}
              onChange={(e) => setPassword(e.currentTarget.value)}
              type={showPassword ? 'text' : 'password'}
              label="New Password"
              required
              containerProps={{
                className: 'min-w-0',
              }}
            />
            <IconButton
              size="sm"
              variant="text"
              className="!absolute right-1 top-1 rounded text-main"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <PiEyeSlashBold /> : <PiEyeBold />}
            </IconButton>
          </div>

          <Typography variant="small" className="mt-1">
            At least 10 charachters. Must contain a lowercase and an uppercase letter, a number and a symbol.
          </Typography>
        </div>

        <div className="relative flex w-full">
          <Input
            className="pr-20"
            value={passwordConfirm || ''}
            onChange={(e) => setpasswordConfirm(e.currentTarget.value)}
            type={showPassword ? 'text' : 'password'}
            label="Confirm new password"
            required
            containerProps={{
              className: 'min-w-0',
            }}
          />
          <IconButton
            size="sm"
            variant="text"
            className="!absolute right-1 top-1 rounded text-main"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <PiEyeSlashBold /> : <PiEyeBold />}
          </IconButton>
        </div>

        <Button type="submit" disabled={loading}>
          {loading ? <Spinner className="h-4 w-4 mx-auto" /> : 'SAVE NEW PASSWORD'}
        </Button>
      </form>
    </div>
  );
};

export default UserSetNewPassword;
