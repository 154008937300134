module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    extend: {
      colors: {
        primary: {
          DEFAULT: '#21323D',
          50: '#6892AD',
          100: '#5B89A6',
          200: '#4C738C',
          300: '#3E5D72',
          400: '#2F4857',
          500: '#21323D',
          600: '#0D1419',
          700: '#000000',
          800: '#000000',
          900: '#000000',
          950: '#000000',
        },
        secondary: {
          DEFAULT: '#21323D',
          50: '#6892AD',
          100: '#5B89A6',
          200: '#4C738C',
          300: '#3E5D72',
          400: '#2F4857',
          500: '#21323D',
          600: '#0D1419',
          700: '#000000',
          800: '#000000',
          900: '#000000',
          950: '#000000',
        },
        'base-gray': {
          DEFAULT: '#E3E6E6',
          50: '#FFFFFF',
          100: '#FFFFFF',
          200: '#FFFFFF',
          300: '#FFFFFF',
          400: '#F9F9F9',
          500: '#E3E6E6',
          600: '#C5CCCC',
          700: '#A8B1B1',
          800: '#8A9797',
          900: '#6E7B7B',
          950: '#606C6C',
        },
        'white-yellow': {
          DEFAULT: '#FFFCE9',
          50: '#FFFFFF',
          100: '#FFFFFF',
          200: '#FFFFFF',
          300: '#FFFFFF',
          400: '#FFFFFF',
          500: '#FFFCE9',
          600: '#FFF4B1',
          700: '#FFED79',
          800: '#FFE541',
          900: '#FFDD09',
          950: '#ECCB00',
        },
        accent: {
          DEFAULT: '#FAE632',
          50: '#FEFCE5',
          100: '#FEF9D1',
          200: '#FDF5A9',
          300: '#FCF082',
          400: '#FBEB5A',
          500: '#FAE632',
          600: '#EED706',
          700: '#B7A504',
          800: '#817403',
          900: '#4A4302',
          950: '#2E2A01',
        },
        'accent-red': {
          DEFAULT: '#F93044',
          50: '#FEE2E5',
          100: '#FECFD3',
          200: '#FCA7AF',
          300: '#FB7F8C',
          400: '#FA5868',
          500: '#F93044',
          600: '#EA071D',
          700: '#B40517',
          800: '#7D0410',
          900: '#470209',
          950: '#2B0105',
        },
        'accent-blue': {
          DEFAULT: '#6EACD6',
          50: '#FDFEFE',
          100: '#EDF5FA',
          200: '#CDE3F1',
          300: '#AED0E8',
          400: '#8EBEDF',
          500: '#6EACD6',
          600: '#4293CA',
          700: '#2F75A5',
          800: '#225679',
          900: '#16374E',
          950: '#102838',
        },
      },
      fontFamily: {
        sans: ['Messina Sans', 'sans-serif'],
        neusa: ['Neusa Next Pro', 'sans-serif'],
      },
      animation: {
        fade: 'fade 5s ease-in 1 both',
      },
      keyframes: {
        fade: {
          '0%': { opacity: 1 },
          '100%': { opacity: 0 },
        },
      },
    },
  },
  plugins: [],
};
