import React, { useContext, useState, createContext, useCallback, useEffect, useMemo } from 'react';

import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { getCompleteUserProfile, getSupabaseSession } from '../utils/helpers/supabase.helpers';

const SessionContext = createContext({
  refresh: () => {},
  loading: true,
  user: undefined,
  checkFeatureAuth: () => {},
});

export const useSessionContext = () => useContext(SessionContext);

export const SessionContextProvider = ({ children }) => {
  const [user, setUser] = useState();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const refetchUser = useCallback(async () => {
    setLoading(true);

    const { data: session } = await getSupabaseSession();
    if (!session) {
      setUser(undefined);
      setLoading(false);
      return;
    }

    const { data: profile, error: profileError } = await getCompleteUserProfile();

    if (profileError) {
      console.error({ profileError });
    }

    if (!profile || profileError) {
      setUser(undefined);
      Sentry.setUser(null);
      setLoading(false);
      return;
    }

    // we can only set ID for privacy compliances
    Sentry.setUser({
      id: session.user?.id,
    });

    setUser({
      confirmedAt: session.user?.confirmed_at,
      ...profile,
    });

    setLoading(false);
  }, []);

  const checkFeatureAuth = useCallback(
    (feature) => {
      if (!user?.features) return false;
      return user.features?.includes(feature);
    },
    [user]
  );

  const refresh = () => {
    refetchUser();
    navigate('/');
  };

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  const contextValue = useMemo(
    () => ({
      user,
      loading,
      refresh,
      checkFeatureAuth,
    }),
    [user, loading, refresh, checkFeatureAuth]
  );

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};
