import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';

const FaqParagraph = ({ title, text, id }) => {
  const { hash } = useLocation();

  const textColor = hash === `#${id}` ? 'text-accent' : 'text-white';

  return (
    <div id={id} className="mb-6 self-start text-left">
      <Typography variant="h3" className={textColor}>
        {title}
      </Typography>
      <Typography variant="paragraph" className={textColor}>
        {text}
      </Typography>
      <hr className="w-full md:w-1/2 mt-6 opacity-50" />
    </div>
  );
};

export default FaqParagraph;
