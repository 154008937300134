import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PiPathFill, PiQrCodeFill, PiCirclesThree } from 'react-icons/pi';
import Sidebar from './Sidebar';
import Tabs from './Tabs';
import HeaderSpace from './HeaderSpace';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import SpotIcon from '../components/SpotIcon';
import { useScreenBreakpoint } from '../hooks/toolkit';

const DashboardLayout = () => {
  const experienceEditorAuth = useFeatureAuth(FEATURES.EXP_EDITOR_UI);
  const accessCodeAuth = useFeatureAuth(FEATURES.EXP_ACCESS_CODES);
  const patternsCreateAuth = useFeatureAuth(FEATURES.CREATE_PATTERNS);
  const { pathname } = useLocation();
  const screenBreakpoint = useScreenBreakpoint();
  const links = useMemo(
    () => [
      {
        label: 'Agami',
        to: '/',
        Icon: SpotIcon,
        activeLinkCondition: pathname.includes('/spot/'),
      },

      ...(experienceEditorAuth
        ? [
            {
              label: 'Experiences',
              to: '/experiences',
              Icon: PiPathFill,
              activeLinkCondition: pathname.includes('/experiences/'),
            },
          ]
        : []),

      ...(patternsCreateAuth
        ? [
            {
              label: 'Patterns',
              to: '/patterns',
              Icon: PiCirclesThree,
              activeLinkCondition: pathname.includes('/patterns/'),
            },
          ]
        : []),

      ...(accessCodeAuth
        ? [
            {
              label: 'Access Codes',
              to: '/access-codes',
              Icon: PiQrCodeFill,
              activeLinkCondition: pathname.includes('/access-codes/'),
            },
          ]
        : []),
    ],
    [experienceEditorAuth, accessCodeAuth, patternsCreateAuth, pathname]
  );

  return (
    <>
      <HeaderSpace />
      <div className="relative w-full min-h-screen flex flex-col md:flex-row items-stretch z-10 dashboardlayout">
        {screenBreakpoint === 'sm' ? <Tabs links={links} /> : <Sidebar links={links} />}
        <div className="w-full bg-white min-h-screen">
          <div className="w-full mx-auto mt-2 md:mt-6 text-primary px-4 md:px-6">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
