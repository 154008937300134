import React from 'react';
import { Typography, Button } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import decorationtop from '../assets/svg/homepage-decor-top.svg';

const Page404 = () => (
  <Container direction="col" className="absolute top-0 bottom-0 left-0 right-0 gap-2">
    <img
      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-150 md:scale-100 opacity-25 z-[0]"
      src={decorationtop}
      alt="decoration top"
    />

    <Typography variant="h1" className="text-accent">
      404
    </Typography>

    <Typography variant="h5" className="text-accent">
      Sounds like there&apos;s silence here :(
    </Typography>

    <Link to="/" className="z-[1] mt-10">
      <Button className="z-[1]" size="sm">
        GO BACK HOME
      </Button>
    </Link>
  </Container>
);

export default Page404;
