import React from 'react';
import { Typography } from '@material-tailwind/react';
import { usePageTitle } from '../hooks/toolkit';
import Container from '../components/Container';

const Support = () => {
  usePageTitle('Support | Agami');
  return (
    <Container direction="col" className="text-white">
      <Typography variant="h1">SUPPORT</Typography>

      <div className="w-full mt-8 text-left">
        <Typography variant="h2">Contact us</Typography>

        <Typography variant="h4" className="mt-6 underline">
          <a href="mailto:agami@mezzoforte.design">agami@mezzoforte.design</a>
        </Typography>
        <Typography className="mt-1 mb-4">
          Questions about platform functionalities, pricing, BCH purchases and other general inquiries.
        </Typography>

        <Typography variant="h4" className="mt-6 underline">
          <a href="mailto:dpo@mezzoforte.design">dpo@mezzoforte.design</a>
        </Typography>
        <Typography className="mt-1 mb-4">
          Questions regarding our privacy policy, or if you want to request the deletion of your data.
        </Typography>
      </div>
    </Container>
  );
};

export default Support;
