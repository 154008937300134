import React from 'react';
import { Source } from 'react-map-gl';
import { MAPBOX_TERRAIN_SOURCE } from '../utils/constants/mapbox.constants';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';

const TerrainSourceLayer = () => {
  const advMapsAuth = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);
  return advMapsAuth ? (
    <Source id="mapbox-dem" type="raster-dem" url={MAPBOX_TERRAIN_SOURCE} tileSize={512} maxzoom={14} />
  ) : null;
};

export default TerrainSourceLayer;
