import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from '@material-tailwind/react';
import { PiWarningCircle } from 'react-icons/pi';
import { useGeneralToast } from '../hooks/toast';
import { useWindowSize } from '../hooks/toolkit';
import { deleteAccessCode } from '../utils/helpers/supabase.helpers';
import { AccessCodeType } from '../PropTypes';

const DeleteAccessCodeDialog = ({ isOpen, setIsOpen, codeToDelete, refetchCodesList }) => {
  const { success: toastSuccess, error: toastError } = useGeneralToast();
  const [deleting, setDeleting] = useState(false);
  const { width } = useWindowSize();
  const dialogSize = width <= 640 ? 'lg' : 'xs';

  const handleDelete = useCallback(async () => {
    setDeleting(true);

    const { error } = await deleteAccessCode({
      accessCodeId: codeToDelete.id,
    });

    if (error) {
      console.error(error);
      toastError('Something went wrong');
      setDeleting(false);
      return;
    }

    await refetchCodesList();
    toastSuccess('Access code successfully removed');
    setDeleting(false);
    setIsOpen(false);
  }, [deleteAccessCode]);

  return (
    <Dialog size={dialogSize} open={isOpen} handler={setIsOpen}>
      <DialogHeader>Delete access code</DialogHeader>
      <DialogBody>
        <Alert variant="ghost" color="amber" icon={<PiWarningCircle className="h-6 w-6" />}>
          This operation cannot be undone!
        </Alert>
        <span className="block my-2">
          Are you sure you want to delete the access code <strong>{codeToDelete?.code}</strong>?
        </span>
        <Alert variant="ghost">
          <ul className="mt-2 ml-2 list-outside list-disc">
            <li className="ml-2">Every access attempt by new users with this code will fail</li>
            <li className="ml-2">
              Users who have previously scanned this code will retain access to the experience until their
              access expires
            </li>
          </ul>
        </Alert>
      </DialogBody>
      <DialogFooter className="justify-between gap-2">
        <Button size="sm" color="primary" variant="text" onClick={() => setIsOpen(false)} disabled={deleting}>
          CANCEL
        </Button>
        <Button size="sm" color="red" className="bg-accent-red" onClick={handleDelete} disabled={deleting}>
          {deleting ? <Spinner className="h-4 w-4" /> : 'CONFIRM DELETE'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

DeleteAccessCodeDialog.propTypes = {
  codeToDelete: AccessCodeType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetchCodesList: PropTypes.func,
};

export default DeleteAccessCodeDialog;
