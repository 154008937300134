import React from 'react';
import HeaderSpace from '../layout/HeaderSpace';

const Container = ({ direction, bgColorClass, className, children }) => (
  <div className={`min-h-[calc(100vh_-_10rem)] ${bgColorClass ?? 'bg-transparent'}`}>
    <div
      className={`my-12 max-w-5xl mx-auto flex ${direction === 'col' ? 'flex-col' : 'flex-row'} justify-center items-center px-2 ${className ?? ''}`}
    >
      <HeaderSpace />
      {children}
    </div>
  </div>
);

export default Container;
