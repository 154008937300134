import React from 'react';
import uploadIllustration from '../assets/png/upload.png';

const NoUploads = ({ children }) => (
  <div className="w-full text-center">
    <img
      src={uploadIllustration}
      className="opacity-90 mt-16 mb-8 mx-auto md:w-1/4"
      alt="No uploads"
      title="No uploads"
    />
    {children}
  </div>
);

export default NoUploads;
