import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

export const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

const getMinOrMax = (coords, minOrMax, latOrLng) => {
  if (minOrMax === 'max') {
    return maxBy(coords, (value) => value[latOrLng === 'lon' ? 0 : 1])[latOrLng === 'lon' ? 0 : 1];
  }
  return minBy(coords, (value) => value[latOrLng === 'lon' ? 0 : 1])[latOrLng === 'lon' ? 0 : 1];
};

export const getBoundsFromCoords = (coords) => {
  const maxLat = getMinOrMax(coords, 'max', 'lat');
  const minLat = getMinOrMax(coords, 'min', 'lat');
  const maxLng = getMinOrMax(coords, 'max', 'lon');
  const minLng = getMinOrMax(coords, 'min', 'lon');
  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];
  return [southWest, northEast];
};

export const randomColor = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16);
  return `#${n.slice(0, 6)}`;
};
