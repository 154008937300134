import React, { useState, useEffect } from 'react';
import { Typography } from '@material-tailwind/react';
import decorationtop from '../assets/svg/homepage-decor-top.svg';

const Loading = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === '.......' ? '' : `${prevDots}.`));
    }, 250);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="w-full h-full flex justify-center items-center relative">
      <img
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-150 md:scale-100 opacity-25 z-[0]"
        src={decorationtop}
        alt="decoration"
      />

      <div className="flex items-center">
        <Typography variant="h3" color="white">
          LOADING
        </Typography>
        <Typography variant="h3" color="white">
          {dots}
        </Typography>
      </div>
    </div>
  );
};

export default Loading;
