import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import {
  getUserOwnedExperiences,
  getUserOwnedExperiencesWithoutDefault,
} from '../utils/helpers/supabase.helpers';

export const useUserExperiences = (options = {}) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [experienceList, setExperienceList] = useState(null);
  const { enabled, excludeDefaultExperience } = options;

  const fetchExpList = useCallback(async () => {
    if (enabled === false) {
      return;
    }

    const { data, error } = excludeDefaultExperience
      ? await getUserOwnedExperiencesWithoutDefault({ userId: user.id })
      : await getUserOwnedExperiences({ userId: user.id });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setExperienceList(data);
    setIsLoading(false);
  }, [user, enabled, getUserOwnedExperiences]);

  useEffect(() => {
    if (!user || enabled === false) {
      return;
    }

    fetchExpList();
  }, [user, enabled]);

  return { experienceList, isLoading, refetch: fetchExpList };
};
