import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Sheet from 'react-modal-sheet';
import { IconButton, Spinner, Typography } from '@material-tailwind/react';
import { AiOutlinePlus } from 'react-icons/ai';
import Center from '../components/Center';
import AddAgamiToExperienceTable from './AddAgamiToExperienceTable';
import { AgamiSpotDetailPropType } from '../PropTypes';

const AddAgamiToExperienceSheet = ({
  isOpen,
  setIsOpen,
  currentExpId,
  isLoading,
  agamiList,
  refetchAfterChange,
}) => {
  const navigate = useNavigate();
  return (
    <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Sheet.Container>
        <Sheet.Header className="p-4 flex justify-between items-center">
          <Typography variant="h4">Add agami to this experience</Typography>
        </Sheet.Header>
        <Sheet.Content>
          {isLoading && (
            <Center>
              <Spinner className="h-6 w-6 my-4" />
            </Center>
          )}

          {!isLoading && agamiList.length === 0 && (
            <span>
              You didn&apos;t create any <em>agami</em> yet.
            </span>
          )}

          <div className="mx-2 my-3 pb-3 flex items-center justify-between border-b">
            <Typography variant="h5" color="gray">
              Create a new agami
            </Typography>
            <IconButton
              size="md"
              color="primary"
              className="rounded-full w-8 h-8"
              onClick={() => navigate(`/upload/spot?addToExperience=${currentExpId}`)}
            >
              <AiOutlinePlus className="w-4 h-4" />
            </IconButton>
          </div>

          {!isLoading && agamiList.length > 0 && (
            <div className="px-2">
              <Typography variant="h5" color="gray">
                Add from your library
              </Typography>
              <AddAgamiToExperienceTable
                currentExpId={currentExpId}
                refetchAfterChange={refetchAfterChange}
                agamiList={agamiList}
              />
            </div>
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

AddAgamiToExperienceSheet.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentExpId: PropTypes.string,
  isLoading: PropTypes.bool,
  agamiList: PropTypes.arrayOf(AgamiSpotDetailPropType),
  refetchAfterChange: PropTypes.func,
};

export default AddAgamiToExperienceSheet;
