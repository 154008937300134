import React, { useState } from 'react';
import { Button, Input, Option, Select, Spinner, Textarea, Typography } from '@material-tailwind/react';
import { useFormData } from '../hooks/form';
import { useGeneralToast } from '../hooks/toast';
import { createOrEditPattern } from '../utils/helpers/supabase.helpers';
import { AGAMI_GLOBAL_EXPERIENCE_ID } from '../utils/constants/db.constants';
import { useSessionContext } from '../contexts/SessionContext';

const GLOBAL_EXP = {
  id: AGAMI_GLOBAL_EXPERIENCE_ID,
  name: 'Agami Global',
};

const EditPatternForm = ({ pattern, experienceList, refetch, closeDialog }) => {
  const toast = useGeneralToast();
  const [loading, setLoading] = useState(false);

  const { user } = useSessionContext();

  const { formData, handleDataChange } = useFormData({
    pattern_id: pattern?.pattern_id,
    experience_id: pattern?.experience_id ?? AGAMI_GLOBAL_EXPERIENCE_ID,
    creator_id: user.id,
    name: pattern?.name,
    description: pattern?.description,
    color: pattern?.color,
  });

  const experiencesOptions = [GLOBAL_EXP].concat(experienceList);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    if (!formData.experience_id) {
      toast.error('Choose an experience to generate a pattern');
    }

    if (!formData.name) {
      toast.error('Choose a name for the pattern');
    }

    const { error } = await createOrEditPattern({
      upsertData: formData,
    });

    if (error) {
      console.error(error);
      toast.error(error.message ?? JSON.stringify(error));
      setLoading(false);
      return;
    }

    refetch();
    setLoading(false);
    if (typeof closeDialog === 'function') closeDialog();
    toast.success(`Pattern ${pattern ? 'updated' : 'created'} succesfully`);
  };

  return (
    <form className="flex flex-col gap-4 px-2" onSubmit={onSubmit}>
      <div className="flex flex-col">
        <Select
          label="Choose experience"
          value={formData.experience_id ?? ''}
          onChange={(val) => handleDataChange('experience_id', val)}
          required
        >
          {experiencesOptions.map((exp) => (
            <Option key={exp.id} value={exp.id}>
              {exp.name}
            </Option>
          ))}
        </Select>
        <Typography variant="small">The target experience</Typography>
      </div>

      <div className="flex flex-col">
        <Input
          value={formData.name ?? ''}
          onChange={(e) => handleDataChange('name', e.currentTarget.value)}
          type="text"
          label="Pattern name"
          required
        />
        <Typography variant="small">
          This name will be shown in the map together with <em>agami</em> information
        </Typography>
      </div>

      <div className="flex flex-col">
        <Textarea
          className="w-full -mb-2"
          rows={1}
          value={formData.description}
          onChange={(e) => handleDataChange('description', e.currentTarget.value)}
          type="text"
          label="Description (optional)"
        />
        <Typography variant="small">Use this to provide info about the pattern content</Typography>
      </div>

      <div className="flex flex-col">
        <Input
          value={formData.color ?? ''}
          onChange={(e) => handleDataChange('color', e.currentTarget.value)}
          type="color"
          label="Color"
          required
        />
        <Typography variant="small">
          <em>Agami</em> of this pattern will use this color on the map
        </Typography>
      </div>

      <Button type="submit" disabled={loading || !formData.experience_id || !formData.name}>
        {loading ? <Spinner className="mx-auto" /> : `${pattern ? 'UPDATE' : 'CREATE'} PATTERN`}
      </Button>
    </form>
  );
};

export default EditPatternForm;
