import React, { useEffect, useState } from 'react';
import { IconButton, Progress, Tooltip, Typography } from '@material-tailwind/react';
import { IoMdPlay, IoMdPause, IoMdFastforward, IoMdRewind, IoMdClose } from 'react-icons/io';
import { PiInfoDuotone } from 'react-icons/pi';

const formatTimer = (t) => {
  if (t > 0) {
    const o = new Date(0);
    const p = new Date(t * 1000);
    const array = new Date(p.getTime() - o.getTime()).toISOString().split('T')[1].split('Z')[0].split(':');
    return {
      mm: array[1],
      ss: array[2].split('.')[0],
    };
  }
  return { mm: '00', ss: '00' };
};

export default function Player({ spot }) {
  const [progress, setProgress] = useState(0);

  const audio = spot?.audio;
  // eslint-disable-next-line
  const duration = audio?._duration;
  const total = formatTimer(duration);
  const elapsed = formatTimer(progress);

  useEffect(() => {
    const seek = setInterval(() => {
      try {
        const p = audio?.seek();
        setProgress(p);
      } catch {
        // do nothing
      }
    }, 100);
    return () => clearInterval(seek);
  }, [audio]);

  const stop = () => {
    if (audio) audio.stop();
  };

  const skip = (s) => {
    const p = progress + s;
    if (p > 0 && p < duration) audio?.seek(p);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          {spot?.spot?.description ? (
            <div className="flex items-center justify-start gap-3">
              <Typography className="font-bold">{spot.spot.label}</Typography>
              <Tooltip content={<div className="w-[300px]">{spot?.spot?.description}</div>}>
                <span>
                  <PiInfoDuotone />
                </span>
              </Tooltip>
            </div>
          ) : (
            <Typography className="font-bold">{spot.spot.label}</Typography>
          )}

          {spot?.spot?.creator && (
            <Typography variant="small">
              Created by {spot.spot.creator?.name} {spot.spot.creator?.surname}
            </Typography>
          )}
        </div>

        <IconButton onClick={stop} size="sm" variant="text">
          <IoMdClose className="text-xl text-primary" />
        </IconButton>
      </div>
      <div className="flex items-center w-full gap-x-3">
        <IconButton variant="text" size="sm" onClick={() => skip(-10)}>
          <IoMdRewind className="text-xl text-primary mx-2" />
        </IconButton>
        <IconButton
          variant="text"
          size="sm"
          onClick={() => (audio?.playing() ? audio.pause() : audio.play())}
        >
          {audio.playing() ? (
            <IoMdPause className="text-xl text-primary" />
          ) : (
            <IoMdPlay className="text-xl text-primary" />
          )}
        </IconButton>
        <IconButton variant="text" size="sm" onClick={() => skip(10)}>
          <IoMdFastforward className="text-xl text-primary" />
        </IconButton>
        <Typography variant="small">
          {elapsed.mm}:{elapsed.ss}
        </Typography>
        <Progress value={Math.floor((progress / duration) * 100)} color="primary" />
        <Typography variant="small">
          {total.mm}:{total.ss}
        </Typography>
      </div>
    </>
  );
}
