import { useUserPreferences } from '../contexts/UserPreferencesContext';
import {
  RADIUS_CIRCLE_STYLE_OPTS,
  LIGHT_RADIUS_CIRCLE_STYLE_OPTS,
  DEADBAND_CIRCLE_STYLE_OPTS,
  LIGHT_DEADBAND_CIRCLE_STYLE_OPTS,
  LIGHT_CLUSTER_CIRCLE_STYLE_OPTS,
  CLUSTER_CIRCLE_STYLE_OPTS,
  LIGHT_PATTERN_CIRCLE_STYLE_OPTS,
  PATTERN_CIRCLE_STYLE_OPTS,
} from '../utils/constants/map.constants';
import { MAPBOX_SATELLITE_MAP_STYLE } from '../utils/constants/mapbox.constants';

export const useAgamiMarkerStyle = () => {
  const { mapStyle } = useUserPreferences();
  return {
    radiusStyle:
      mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? LIGHT_RADIUS_CIRCLE_STYLE_OPTS : RADIUS_CIRCLE_STYLE_OPTS,
    deadbandStyle:
      mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? LIGHT_DEADBAND_CIRCLE_STYLE_OPTS : DEADBAND_CIRCLE_STYLE_OPTS,
    clusterStyle:
      mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? LIGHT_CLUSTER_CIRCLE_STYLE_OPTS : CLUSTER_CIRCLE_STYLE_OPTS,
    patternStyle:
      mapStyle === MAPBOX_SATELLITE_MAP_STYLE ? LIGHT_PATTERN_CIRCLE_STYLE_OPTS : PATTERN_CIRCLE_STYLE_OPTS,
  };
};
