import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Typography } from '@material-tailwind/react';
import { PiCheckCircle } from 'react-icons/pi';

const PlanCard = ({ planName, price, features }) => (
  <Card color="white" variant="gradient" className="w-full flex flex-col max-w-[20rem] p-8">
    <CardHeader
      floated={false}
      shadow={false}
      color="transparent"
      className="m-0 mb-4 rounded-none border-b border-white/10 pb-8 text-center"
    >
      <Typography variant="h3" className="font-normal uppercase text-primary">
        {planName}
      </Typography>
      <Typography variant="h1" className="mt-6 flex justify-center gap-1 font-normal text-primary">
        <span className="mt-2 text-4xl">€</span>
        {price} <span className="self-end text-4xl">/year</span>
      </Typography>
    </CardHeader>
    <CardBody className="p-0">
      <ul className="flex flex-col gap-4">
        {features?.map((feature) => (
          <li key={`${planName}_${feature?.replace(/ /g, '_')}`} className="flex items-start gap-4">
            <span className="rounded-full border border-white/20 bg-white/20 p-1">
              <PiCheckCircle />
            </span>
            <Typography className="font-normal">{feature}</Typography>
          </li>
        ))}
      </ul>
    </CardBody>
  </Card>
);

PlanCard.propTypes = {
  planName: PropTypes.string,
  price: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.string),
};

export default PlanCard;
