import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowBack } from 'react-icons/md';
import { IconButton } from '@material-tailwind/react';
import { useGoBack } from '../hooks/router';

const GoBack = ({ variant, color }) => {
  const goBack = useGoBack();
  return (
    <IconButton variant={variant ?? 'text'} color={color ?? 'primary'} onClick={goBack}>
      <MdArrowBack className="w-6 h-6" />
    </IconButton>
  );
};

GoBack.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default GoBack;
