import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

// contexts
import { useSessionContext } from './contexts/SessionContext';

// common
import Loading from './layout/Loading';
import Layout from './layout/Layout';
import Page404 from './views/404';
import FaqPage from './views/FaqPage';
import PrivacyPolicy from './views/PrivacyPolicy';
import About from './views/About';
import Support from './views/Support';
import ExpiredLink from './views/ExpiredLink';
import HowItWorks from './views/HowItWorks';

// anonymous users routes
import Home from './views/Home';
import UserSignin from './views/UserSignin';
import ResetPassword from './views/ResetPassword';
import CreateAccount from './views/CreateAccount';

// unfinalized users routes
import UserFinalize from './views/UserFinalize';

// logged users routes
// profile area
import ProfileLayout from './layout/ProfileLayout';
import Profile from './views/Profile';
import Membership from './views/Membership';
import UserSetNewPassword from './views/UserSetNewPassword';

// dashboard area
import DashboardLayout from './layout/DashboardLayout';
import DashboardAgami from './views/DashboardAgami';
import DashboardExperiences from './views/DashboardExperiences';
import DashboardExperienceDetail from './views/DashboardExperienceDetail';
import DashboardAgamiDetail from './views/DashboardAgamiDetail';

// upload area
import Upload from './views/Upload';
import UploadSingleSpot from './ViewUpload/UploadSingleSpot';
import UploadExperience from './ViewUpload/UploadExperience';

// admin area
import RequireAdmin from './layout/RequireAdmin';
import AdminPanel from './views/AdminPanel';
import InviteNewUser from './ViewAdmin/InviteNewUser';
import GenerateCode from './ViewAdmin/GenerateCode';
import CodesList from './ViewAdmin/CodesList';
import UserList from './ViewAdmin/UserList';
import ExperienceList from './ViewAdmin/ExperienceList';
import SpotsList from './ViewAdmin/SpotsList';

// experience area
import Experience from './views/Experience';
import { MapControlsContextProvider } from './contexts/MapControlsContext';
import { XpContextProvider } from './contexts/XpContext';
import { LogsContextProvider } from './contexts/LogsContext';
import DashboardAccessCodes from './views/DashboardAccessCodes';
import DashboardPatterns from './views/DashboardPatterns';
import DashboardPatternDetail from './views/DashboardPatternDetail';

const AppRouting = () => {
  const { user, loading } = useSessionContext();

  if (loading) {
    return <Loading />;
  }

  if (process.env.REACT_APP_COMING_SOON_PREVIEW) {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  if (!user) {
    // unauth routes
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signin" element={<UserSignin />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<CreateAccount />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="about" element={<About />} />
          <Route path="support" element={<Support />} />
          <Route path="expired-invitation" element={<ExpiredLink />} />
          <Route path="how-it-works" element={<HowItWorks />} />
          <Route path="profile/*" element={<Navigate to="/signin" replace />} />
          <Route path="experience/*" element={<Navigate to="/signin" replace />} />
          <Route path="upload/*" element={<Navigate to="/signin" replace />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    );
  }

  if (!user.finalized) {
    // user has to complete profile info
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<UserFinalize />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="about" element={<About />} />
          <Route path="support" element={<Support />} />
          <Route path="expired-invitation" element={<ExpiredLink />} />
          <Route path="how-it-works" element={<HowItWorks />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // normal auth routes
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* LOGGED USERS ROUTES */}
        <Route path="/" element={<DashboardLayout />}>
          <Route index element={<DashboardAgami />} />
          <Route path="experiences" element={<DashboardExperiences />} />
          <Route path="experiences/:experienceId" element={<DashboardExperienceDetail />} />
          <Route path="spot/:id" element={<DashboardAgamiDetail />} />
          <Route path="access-codes" element={<DashboardAccessCodes />} />
          <Route path="patterns" element={<DashboardPatterns />} />
          <Route path="patterns/:patternId" element={<DashboardPatternDetail />} />
        </Route>
        <Route path="profile" element={<ProfileLayout />}>
          <Route index element={<Profile />} />
          <Route path="new-password" element={<UserSetNewPassword />} />
          <Route path="membership" element={<Membership />} />
        </Route>
        <Route path="finalize" element={<UserFinalize />} />
        <Route
          path="experience/:experienceId?"
          element={
            <LogsContextProvider>
              <XpContextProvider>
                <MapControlsContextProvider>
                  <Experience />
                </MapControlsContextProvider>
              </XpContextProvider>
            </LogsContextProvider>
          }
        />
        <Route path="upload" element={<Outlet />}>
          <Route index element={<Upload />} />
          <Route path="spot" element={<UploadSingleSpot />} />
          <Route path="experience" element={<UploadExperience />} />
        </Route>

        {/* ADMIN ROUTES */}
        <Route
          path="admin"
          element={
            <RequireAdmin>
              <Outlet />
            </RequireAdmin>
          }
        >
          <Route index element={<AdminPanel />} />
          <Route path="new-user" element={<InviteNewUser />} />
          <Route path="new-code" element={<GenerateCode />} />
          <Route path="list-codes" element={<CodesList />} />
          <Route path="list-users" element={<UserList />} />
          <Route path="list-experiences" element={<ExperienceList />} />
          <Route path="list-spots" element={<SpotsList />} />
        </Route>

        {/* COMMON ROUTES */}
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="about" element={<About />} />
        <Route path="support" element={<Support />} />
        <Route path="expired-invitation" element={<ExpiredLink />} />
        <Route path="how-it-works" element={<HowItWorks />} />
      </Route>

      <Route path="/dashboard" element={<Navigate to="/" replace />} />

      {/* REDIRECTS */}
      <Route path="signin" element={<Navigate to="/" replace />} />
      <Route path="register" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRouting;
