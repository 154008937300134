import { useEffect, useState, useCallback } from 'react';
import { useSessionContext } from '../contexts/SessionContext';
import { getUserOwnedExperience } from '../utils/helpers/supabase.helpers';

export const useUserExperienceDetails = ({ experienceId }) => {
  const { user } = useSessionContext();
  const [isLoading, setIsLoading] = useState(true);
  const [experienceDetails, setExperienceDetails] = useState(null);

  const fetchExperienceDetails = useCallback(async () => {
    const { data, error } = await getUserOwnedExperience({
      userId: user.id,
      experienceId,
    });

    if (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }

    setExperienceDetails(data);
    setIsLoading(false);
  }, [user, experienceId, getUserOwnedExperience]);

  useEffect(() => {
    if (!user || !experienceId) {
      return;
    }
    fetchExperienceDetails();
  }, [user, experienceId]);

  return { experienceDetails, isLoading, refetch: fetchExperienceDetails };
};
