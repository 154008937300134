import React from 'react';

const isBrowser = typeof window !== 'undefined';

const getFromStorage = (key, defaultValue) => {
  try {
    const item = isBrowser && window.localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

export const useStoredState = ({ key, defaultValue }) => {
  const [storedValue, setStoredValue] = React.useState(() => getFromStorage(key, defaultValue));

  const setValue = React.useCallback(
    (value) => {
      try {
        const currentValue = getFromStorage(key, defaultValue);
        const valueToStore = value instanceof Function ? value(currentValue) : value;
        if (isBrowser) window.localStorage.setItem(key, JSON.stringify(valueToStore));
        setStoredValue(valueToStore);
      } catch (error) {
        console.error(error);
        // do nothing
      }
    },
    // eslint-disable-next-line
    [key]
  );

  return [storedValue, setValue];
};
