import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, IconButton, Spinner, Button } from '@material-tailwind/react';
import { PiTrash } from 'react-icons/pi';
import truncate from 'lodash/truncate';
import NoUploads from '../components/NoUploads';
import PlayerMini from '../components/PlayerMini';
import AgamiTypeChip from '../components/AgamiTypeChip';
import TableHeaderWithSorting from '../components/TableHeaderWithSorting';
import { removeManualCodeLibrarySuffix } from '../utils/helpers/agami.helpers';

const ExperienceSpotsList = ({
  agamiListLoading,
  agamiList,
  agamiCount,
  removeFromExperience,
  showOwner,
  pageSize,
  page,
  setPage,
  sort,
  setSort,
}) => {
  const navigate = useNavigate();

  const remove = async (e, spot) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromExperience(spot);
  };

  const TABLE_HEAD = [
    { title: 'Name', sortKey: 'name' },
    { title: 'Description' },
    { title: 'Created', sortKey: 'inserted_at' },
    { title: 'Audio' },
    ...(showOwner ? [{ title: 'Owner', sortKey: 'profiles.name' }] : []),
    { title: 'Type', sortKey: 'type' },
    ...(removeFromExperience ? [{ title: '' }] : []),
  ];

  const lastPage = useMemo(
    () => (agamiCount && pageSize ? Math.trunc(agamiCount / pageSize) + 1 : undefined),
    [agamiCount, pageSize]
  );

  const pageSelector = useMemo(
    () =>
      agamiCount && pageSize && page && lastPage
        ? Array.from(
            { length: Math.max(Math.min(3, lastPage - 2), 0) },
            (_, i) => i + Math.max(Math.min(page - 1, lastPage - 3), 2)
          )
        : undefined,
    [agamiCount, pageSize, page, lastPage]
  );

  return (
    <div className="py-2">
      {agamiListLoading && <Spinner className="h-12 w-12 m-auto" />}

      {!agamiListLoading && (!agamiList || agamiList.length === 0) && (
        <div className="col-span-2">
          <NoUploads>
            <Typography>
              No <em>agamis</em> here yet.
            </Typography>
          </NoUploads>
        </div>
      )}

      {!agamiListLoading && agamiList?.length > 0 && (
        <table className="w-full">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <TableHeaderWithSorting
                  key={head.title}
                  title={head.title}
                  sortKey={head.sortKey}
                  sort={sort}
                  setSort={setSort}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {agamiList?.map((agami) => (
              <tr
                key={agami.id}
                className="even:bg-blue-gray-50/50 hover:bg-white-yellow cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/spot/${agami.id}`);
                }}
              >
                <td className="px-4">
                  <Typography title={agami.name} variant="small" className="font-black">
                    {truncate(agami.name, {
                      separator: ' ',
                    })}
                  </Typography>
                </td>
                <td className="px-4">
                  <Typography title={agami.description} variant="small">
                    {truncate(agami.description, {
                      separator: ' ',
                    })}
                  </Typography>
                </td>
                <td className="px-4">
                  <Typography variant="small">
                    {new Date(agami.inserted_at).toLocaleDateString('en-GB')}
                  </Typography>
                </td>
                <td className="px-4">
                  <PlayerMini audioUrl={agami.audioUrl} />
                </td>
                {showOwner && (
                  <td className="px-4">
                    <Typography
                      title={`${agami.profiles?.name} ${agami.profiles?.surname}`}
                      variant="small"
                      className="font-black"
                    >
                      {truncate(`${agami.profiles?.name} ${agami.profiles?.surname}`, {
                        separator: ' ',
                      })}
                    </Typography>
                  </td>
                )}
                <td className="px-4 py-2 flex gap-x-2 items-center ">
                  <AgamiTypeChip agamiType={agami.type} />
                  {agami.type === 2 && agami?.experience_content_spots?.[0].manual_code && (
                    <Typography variant="small" className="font-bold">
                      {removeManualCodeLibrarySuffix(agami.experience_content_spots[0].manual_code)}
                    </Typography>
                  )}
                </td>
                {removeFromExperience && (
                  <td className="px-4 text-right">
                    <IconButton variant="text" size="sm" color="yellow" onClick={(e) => remove(e, agami)}>
                      <PiTrash className="text-primary text-[20px]" />
                    </IconButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {!agamiListLoading && page && setPage && pageSize && pageSelector && lastPage && (
        <div className="flex items-center justify-between gap-2 mt-4">
          <div className="flex gap-4">
            <Typography variant="small">
              {`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, agamiCount)}`}
              {' of '}
              <span className="font-bold">{agamiCount}</span>
            </Typography>
          </div>
          <div className="flex gap-2">
            <Button
              color="primary"
              variant="outlined"
              size="sm"
              disabled={page <= 1}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            >
              &lt;&lt;
            </Button>

            <Button
              color="primary"
              variant={page === 1 ? 'solid' : 'outlined'}
              size="sm"
              onClick={() => setPage(1)}
            >
              {1}
            </Button>

            {pageSelector.at(0) > 2 && (
              <Typography variant="h4" className="font-normal">
                ...
              </Typography>
            )}

            {pageSelector.map((idx) => (
              <Button
                key={idx}
                color="primary"
                variant={page === idx ? 'solid' : 'outlined'}
                size="sm"
                onClick={() => setPage(idx)}
              >
                {idx}
              </Button>
            ))}

            {lastPage > 1 && pageSelector.at(-1) < lastPage - 1 && (
              <Typography variant="h4" className="font-normal">
                ...
              </Typography>
            )}

            {lastPage > 1 && (
              <Button
                color="primary"
                variant={page === lastPage ? 'solid' : 'outlined'}
                size="sm"
                onClick={() => setPage(lastPage)}
              >
                {lastPage}
              </Button>
            )}

            <Button
              color="primary"
              variant="outlined"
              size="sm"
              disabled={page >= lastPage}
              onClick={() => {
                if (page < lastPage) {
                  setPage(page + 1);
                }
              }}
            >
              &gt;&gt;
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExperienceSpotsList;
