import React, { useContext, createContext, useMemo, useEffect } from 'react';
import { useStoredState } from '../hooks/useStoredState';
import { MAP_3D_KEY, MAP_STYLE_KEY } from '../utils/constants/localStorage.constants';
import { MAPBOX_LIGHT_MAP_STYLE } from '../utils/constants/mapbox.constants';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import { useSessionContext } from './SessionContext';

const UserPreferencesContext = createContext({
  mapStyle: '',
  setMapStyle: () => {},
  apply3DMap: false,
  setApply3DMap: () => {},
});

export const useUserPreferences = () => useContext(UserPreferencesContext);

const UserPreferencesContextProvider = ({ children }) => {
  const { user } = useSessionContext();

  const advMapsAuth = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);

  const [mapStyle, setMapStyle] = useStoredState({
    key: MAP_STYLE_KEY,
    defaultValue: MAPBOX_LIGHT_MAP_STYLE,
  });

  const [apply3DMap, setApply3DMap] = useStoredState({
    key: MAP_3D_KEY,
    defaultValue: false,
  });

  useEffect(() => {
    if (user?.id && !advMapsAuth) {
      setMapStyle(MAPBOX_LIGHT_MAP_STYLE);
      setApply3DMap(false);
    }
  }, [user, advMapsAuth]);

  const contextValue = useMemo(
    () => ({
      mapStyle,
      setMapStyle,
      apply3DMap,
      setApply3DMap,
    }),
    [mapStyle, apply3DMap]
  );

  return <UserPreferencesContext.Provider value={contextValue}>{children}</UserPreferencesContext.Provider>;
};

export default UserPreferencesContextProvider;
