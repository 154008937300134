import { validate as uuidValidate, v4 as uuidv4 } from 'uuid';
import {
  dbUpsert,
  getExperiencesForSpot,
  removeFile,
  singleDbInsert,
  singleDbUpsert,
  supabase,
  updateFile,
  uploadFile,
} from './supabase.helpers';
import {
  EXPERIENCE_CONTENT_TABLE,
  GEOXP_AUDIO_BUCKET,
  GEOXP_SPOTS_TABLE,
  EXPERIENCES_TABLE,
  USER_EXPERIENCES_TABLE,
} from '../constants/db.constants';
import { mimeTypeExtension } from './mime.heplers';
import { createManualCodeLibrarySuffix } from './agami.helpers';
import { isLegacyFilePath } from './validate.helpers';

export const uuidToPath = (uuid) => {
  if (!uuidValidate(uuid)) {
    throw new Error('Invalid UUID format');
  }

  // Remove dashes from UUID and split into chunks
  const uuidWithoutDashes = uuid.replace(/-/g, '');
  const chunks = uuidWithoutDashes.match(/.{1,2}/g);
  // Join chunks with '/' and append original UUID
  return `${chunks.slice(0, 3).join('/')}/${uuid}`;
};

const uploadNewSpotAudio = async ({ audioFile, spotId }) => {
  // upload as new file
  const fileExtension = mimeTypeExtension(audioFile?.type);
  const uniqueFilePath = uuidToPath(spotId);
  const filename = `${uniqueFilePath}.${fileExtension}`;

  const { data, error } = await uploadFile({
    filename,
    bucketId: GEOXP_AUDIO_BUCKET,
    file: audioFile,
  });
  return {
    uploadData: data,
    audioPath: data?.path,
    uploadError: error,
  };
};

const updateSpotAudio = async ({ audioContentPath, audioFile, spotId }) => {
  const isLegacy = isLegacyFilePath(audioContentPath);

  // if legacy, upload new file and remove old one
  if (isLegacy) {
    const uploadRes = await uploadNewSpotAudio({
      audioFile,
      spotId,
    });

    if (uploadRes.uploadError) {
      return uploadRes;
    }

    // delete old file
    const { data: removeData, error: removeError } = await removeFile({
      filename: audioContentPath,
      bucketId: GEOXP_AUDIO_BUCKET,
    });

    if (removeError) {
      console.error(removeError);
    }

    return {
      ...uploadRes,
      removeData,
      removeError,
    };
  }

  // if not legacy, upsert previous file
  const { data, error } = await updateFile({
    filename: audioContentPath,
    bucketId: GEOXP_AUDIO_BUCKET,
    file: audioFile,
  });

  return {
    uploadData: data,
    audioPath: data?.path,
    uploadError: error,
  };
};

const uploadSpotAudio = async ({ isUpdate, audioContentPath, audioFile, spotId }) => {
  if (!audioFile) return {};

  if (isUpdate) {
    const updateRes = await updateSpotAudio({
      audioContentPath,
      audioFile,
      spotId,
    });
    return updateRes;
  }

  // upload as new file
  const res = await uploadNewSpotAudio({
    audioFile,
    spotId,
  });
  return res;
};

const getManualCodeEntry = ({ type, manual_code }) => {
  if (type === undefined || manual_code === undefined) {
    console.warn('Need to pass both type and manual_code to upsert agami-experience relation');
    return;
  }

  if (type === 1) {
    return { manual_code: null };
  }

  if (type === 2) {
    return { manual_code };
  }
};

export const upsertAgamiExperiences = async ({
  newExperiences,
  spot_id,
  type,
  manual_code,
  userDefaultExpId,
}) => {
  // get all previous relations, also the canceled ones
  const { data: prevExpRelations, error: prevExpRelationsError } = await getExperiencesForSpot({ spot_id });

  if (prevExpRelationsError) {
    return { error: prevExpRelationsError };
  }

  const manualCodeEntry = getManualCodeEntry({ type, manual_code });

  // experiences previously active but not in the new array are those that now we want to cancel
  const toCancel =
    prevExpRelations
      ?.filter((expRel) => !expRel.is_canceled && !newExperiences.includes(expRel.experience_id))
      ?.map((expRel) => ({
        ...(manualCodeEntry && { manual_code: manualCodeEntry.manual_code }),
        id: expRel.id,
        experience_id: expRel.experience_id,
        spot_id,
        is_canceled: true,
      })) ?? [];

  // experiences relations in the new new array should be added to upsert
  const toAddOrUpdate = newExperiences?.map((expId) => {
    // check if already present a row
    const relationId = prevExpRelations?.find(
      (expRel) => expRel.experience_id === expId && expRel.spot_id === spot_id
    );

    // manage manual spots in user library:
    // append §lib_<uuid> to insert without duplicates conflicts
    if (expId === userDefaultExpId && manualCodeEntry) {
      const manualCodeWithSuffix = createManualCodeLibrarySuffix(manualCodeEntry.manual_code);
      return {
        ...(relationId && { id: relationId.id }),
        manual_code: manualCodeWithSuffix,
        spot_id,
        experience_id: expId,
        is_canceled: false,
      };
    }

    return {
      ...(relationId && { id: relationId.id }),
      ...(manualCodeEntry && { manual_code: manualCodeEntry.manual_code }),
      spot_id,
      experience_id: expId,
      is_canceled: false,
    };
  });

  const upsertData = toCancel.concat(toAddOrUpdate);

  // upsert to experience_content_spots table
  const { data, error } = await dbUpsert({
    upsertData,
    table: EXPERIENCE_CONTENT_TABLE,
    upsertOptions: {
      onConflict: 'id',
      defaultToNull: false,
    },
  });

  return { data, error };
};

export const upsertAgamiSpot = async ({
  userId,
  experiences,
  spotName,
  description,
  latitude,
  longitude,
  radius,
  deadband,
  audioFile,
  audioFilename,
  type,
  manual_code,
  onSuccess,
  onError,
  spotId,
  audioContentPath,
  userDefaultExpId,
  is_draft,
}) => {
  if (typeof onSuccess !== 'function' || typeof onError !== 'function') {
    console.warn('upsertAgamiSpot needs both onSucces and onError handlers');
    return;
  }

  // check if it's a new spot or already existing
  const isUpdate = !!spotId;

  const newId = isUpdate ? spotId : uuidv4();

  // upload audio file to object storage bucket
  const { audioPath, uploadData, uploadError } = await uploadSpotAudio({
    isUpdate,
    audioContentPath,
    audioFile,
    spotId: newId,
  });

  if (uploadError) {
    console.error('uploadError', uploadError);
    onError(uploadError);
    return;
  }

  // upsert DB record
  const { data: insertSpotData, error: insertSpotError } = await singleDbUpsert({
    table: GEOXP_SPOTS_TABLE,
    upsertData: {
      description,
      type,
      user_id: userId,
      name: spotName,
      audio_filename: audioFilename,
      id: newId,
      audio_content_path: audioPath,
      latitude: latitude || null,
      longitude: longitude || null,
      radius: radius || null,
      deadband: deadband || null,
      is_draft,
    },
  });

  if (insertSpotError) {
    console.error('insertSpotError', insertSpotError);
    onError(insertSpotError);
    return;
  }

  const id = insertSpotData?.id;

  // upsert to experience_content_spots table
  const { data: upsertExpContentData, error: upsertExpContentError } = await upsertAgamiExperiences({
    manual_code,
    type,
    spot_id: id,
    newExperiences: experiences,
    userDefaultExpId,
  });

  if (upsertExpContentError) {
    console.error('upsertExpContentError', upsertExpContentError);
    onError(upsertExpContentError);
    return;
  }

  if (isUpdate) onSuccess({ insertSpotData, upsertExpContentData });
  else onSuccess({ uploadData, insertSpotData, upsertExpContentData });
};

export const createExperience = async ({ insertData }) => {
  if (!insertData) {
    console.error('createExperience - no insertData provided');
    return { error: 'No insertData provided' };
  }

  // insert in exp tables
  const { data: newExpData, error: newExpError } = await singleDbInsert({
    table: EXPERIENCES_TABLE,
    insertData,
  });

  if (newExpError) {
    return { error: newExpError };
  }

  const { id, owner_id } = newExpData;

  // assign to current user as owner
  const { data, error } = await singleDbInsert({
    table: USER_EXPERIENCES_TABLE,
    insertData: {
      experience_id: id,
      user_id: owner_id,
      experience_role: 1, // owner
    },
  });

  return {
    data: {
      ...data,
      ...newExpData,
    },
    error,
  };
};

export const cleanFileName = ({ audioFile }) => {
  if (!audioFile) return;
  return audioFile?.name?.replace(/[,:;'!?"`]/g, '')?.replace(/ /g, '_');
};

export const updateExperienceDetails = async ({ expNewData }) => {
  const { id, ...dataToUpdate } = expNewData;
  const { error } = await supabase.from(EXPERIENCES_TABLE).update(dataToUpdate).eq('id', id);
  return { error };
};
