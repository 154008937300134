import React from 'react';
import { Input, Typography, Radio, Switch } from '@material-tailwind/react';
import { useSessionContext } from '../contexts/SessionContext';
import { useFeatureAuth } from '../hooks/authorization';
import { FEATURES } from '../utils/constants/features.constants';
import { useUserPreferences } from '../contexts/UserPreferencesContext';
import { MAPBOX_LIGHT_MAP_STYLE, MAPBOX_SATELLITE_MAP_STYLE } from '../utils/constants/mapbox.constants';
import { usePageTitle } from '../hooks/toolkit';

const Profile = () => {
  usePageTitle('Profile | Agami');
  const { user } = useSessionContext();
  const advMapStylesAuth = useFeatureAuth(FEATURES.ADVANCED_MAP_STYLES);
  const { mapStyle, setMapStyle, apply3DMap, setApply3DMap } = useUserPreferences();
  return (
    <div className="w-11/12 mx-auto mt-12">
      <Typography variant="h1" className="text-primary">
        PROFILE
      </Typography>

      <div className="grid grid-cols-2 gap-6 py-10 w-full md:w-1/2">
        <div className="col-span-2">
          <Input value={user?.email} size="lg" readOnly type="email" label="Email" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input value={user?.name} size="lg" readOnly type="text" label="Name" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input value={user?.surname} size="lg" readOnly type="text" label="Surname" />
        </div>
        <div className="col-span-2">
          <Input value={user?.phone} size="lg" readOnly type="phone" label="Phone" />
        </div>
        <div className="col-span-2">
          <Input
            value={user?.company}
            size="lg"
            readOnly
            type="text"
            label="Company/organization/institute"
          />
        </div>
        <div className="col-span-2">
          <Input value={user?.bch_model} size="lg" readOnly type="text" label="Your BCH" />
        </div>
      </div>

      {advMapStylesAuth && (
        <div className="mt-4">
          <Typography variant="h3" className="text-primary">
            MAPS SETTINGS
          </Typography>
          <div className="flex gap-1 justify-start items-center mt-2">
            <Typography className="font-bold text-primary">Map style</Typography>
            <div className="flex gap-1 justify-center items-center">
              <Radio
                type="radio"
                name="map-style"
                label="Light"
                value={MAPBOX_LIGHT_MAP_STYLE}
                checked={mapStyle === MAPBOX_LIGHT_MAP_STYLE}
                onChange={() => setMapStyle(MAPBOX_LIGHT_MAP_STYLE)}
              />
              <Radio
                type="radio"
                name="map-style"
                label="Satellite"
                value={MAPBOX_SATELLITE_MAP_STYLE}
                checked={mapStyle === MAPBOX_SATELLITE_MAP_STYLE}
                onChange={() => setMapStyle(MAPBOX_SATELLITE_MAP_STYLE)}
              />
            </div>
          </div>
          <div className="flex gap-4 justify-start items-center mt-2">
            <Typography className="font-bold text-primary">3D maps (buildings and terrain)</Typography>
            <Switch checked={apply3DMap} onChange={(e) => setApply3DMap(e.currentTarget.checked)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
