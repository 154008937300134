import React from 'react';
import PropTypes from 'prop-types';
import Sheet from 'react-modal-sheet';
import { IconButton, Typography } from '@material-tailwind/react';
import { PiX } from 'react-icons/pi';
import { useScreenBreakpoint } from '../hooks/toolkit';
import { ExperienceDataPropType } from '../PropTypes';
import ExperienceSidebar from './ExperienceSidebar';

const ExperienceSettings = ({
  experienceDetails,
  refetchExperienceDetails,
  closeSidebar,
  sidebarIsClosed,
}) => {
  const screenBreakpoint = useScreenBreakpoint();
  return (
    <>
      {/* eslint-disable-line */}
      {screenBreakpoint === 'sm' ? (
        // BOTTOM SHEET
        <Sheet isOpen={!sidebarIsClosed} onClose={closeSidebar}>
          <Sheet.Container>
            <Sheet.Header className="p-4 flex justify-between items-center">
              <Typography variant="h4">Experience Settings</Typography>
              <IconButton size="sm" variant="text" color="gray" onClick={closeSidebar}>
                <PiX className="h-5 w-5 text-primary" />
              </IconButton>
            </Sheet.Header>
            <Sheet.Content>
              <ExperienceSidebar
                experienceDetails={experienceDetails}
                refetchExperienceDetails={refetchExperienceDetails}
                closeSidebar={closeSidebar}
                sidebarIsClosed={sidebarIsClosed}
                isInBottomSheet
              />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      ) : (
        // SIDEBAR
        <div
          className={`transition-all duration-[400ms] overflow-hidden ${!sidebarIsClosed ? 'w-[500px]' : 'w-0'}`}
        >
          <div
            className="w-[500px] max-w-full transition-opacity duration-[500ms] px-2"
            style={{
              opacity: !sidebarIsClosed ? 1 : 0,
            }}
          >
            <ExperienceSidebar
              experienceDetails={experienceDetails}
              refetchExperienceDetails={refetchExperienceDetails}
              closeSidebar={closeSidebar}
              sidebarIsClosed={sidebarIsClosed}
            />
          </div>
        </div>
      )}
    </>
  );
};

ExperienceSettings.propTypes = {
  experienceDetails: ExperienceDataPropType,
  refetchExperienceDetails: PropTypes.func,
  closeSidebar: PropTypes.func,
  sidebarIsClosed: PropTypes.bool,
};

export default ExperienceSettings;
