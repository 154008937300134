export const DEFAULT_SPOT_RADIUS = 50; // m

export const MAX_SPOT_RADIUS = 150; // m

export const MIN_SPOT_RADIUS = 10; // m

export const DEFAULT_SPOT_DEADBAND = 15; // m

export const MAX_SPOT_DEADBAND = 200; // m

export const MIN_SPOT_DEADBAND = 1; // m

export const DEFAULT_GEOXP_SPOTS = [
  // paris
  {
    label: 'Louvre Museum, Paris',
    latitude: 48.86108836226617,
    longitude: 2.335572632552631,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // torino
  {
    label: 'Piazza San Carlo, Torino',
    latitude: 45.06775413078083,
    longitude: 7.68259348584491,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // barcelona
  {
    label: 'Plaça de Catalunya, Barcelona',
    latitude: 41.38698366533214,
    longitude: 2.1700712933767194,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // roma
  {
    label: 'Piazza Navona, Roma',
    latitude: 41.89909874575307,
    longitude: 12.473082884784798,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // livorno
  {
    label: 'Fortezza Vecchia, Livorno',
    latitude: 43.551866460154926,
    longitude: 10.302589828644285,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  //  abbey road
  {
    label: 'Abbey Road, London',
    latitude: 51.53206191370222,
    longitude: -0.1773245110788979,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // berlin
  {
    label: 'Brandenburger Tor, Berlin',
    latitude: 52.51629457328829,
    longitude: 13.377983123774138,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // amsterdam
  {
    label: 'Rembrandtplein, Amsterdam',
    latitude: 52.36599963439462,
    longitude: 4.896588887111932,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
  // venezia
  {
    label: 'Piazza San Marco, Venezia',
    latitude: 45.43421452873373,
    longitude: 12.338683669381304,
    radius: DEFAULT_SPOT_RADIUS,
    deadband: DEFAULT_SPOT_DEADBAND,
  },
];

export const EMPTY_GEOXP_CONFIG = {
  geo: {
    positions: [],
    options: {},
  },
  audio: {
    sounds: [],
  },
  experience: {
    patterns: [],
    options: {},
  },
};
